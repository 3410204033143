import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class User {

    uid?:string;
    token?:string;
    firstname?:string;
    lastname?:string;
    dispo?:boolean;
    email?:string;
    phone?:string;
    profilpic?:string = "";
    skill1?:string;
    skill2?:string;
    skill3?:string;
    skill4?:string;
    skill5?:string;
    skill6?:string;
    skill7?:string;
    skill8?:string;
    skill9?:string;
    lastSeenInEpoch?:number;
    last_login?:number;
    presence?:boolean;
  
  
  
  
    constructor(user: User = {}) {
      this.uid = user.uid;
      this.token = user.token;
      this.firstname = user.firstname;
      this.lastname = user.lastname;
      this.dispo = user.dispo;
      this.phone = user.phone;
      this.profilpic = user.profilpic;
      this.skill1 = user.skill1;
      this.skill2 = user.skill2;
      this.skill3 = user.skill3;
      this.skill4 = user.skill4;
      this.skill5 = user.skill5;
      this.skill6 = user.skill6;
      this.skill7 = user.skill7;
      this.skill8 = user.skill8;
      this.skill9 = user.skill9;
      this.email = user.email;
      this.last_login = user.last_login;
       this.presence = user.presence;
       this.lastSeenInEpoch = user.lastSeenInEpoch;
    }
  
  }
  
