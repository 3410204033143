import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../../shared/ui/ui.module';
import { NgbPaginationModule, NgbTypeaheadModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { LogistiqueRoutingModule } from './logistique-routing.module';
import { AddmoyenComponent } from './addmoyen/addmoyen.component';
import { AddpersoComponent } from './addperso/addperso.component';

@NgModule({
  declarations: [ AddmoyenComponent, AddpersoComponent,],
  imports: [
    CommonModule,
    LogistiqueRoutingModule,
    UiModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    FormsModule,

    ReactiveFormsModule
  ]
})
export class LogistiqueModule { }
