<div class="container-fluid">
    <app-pagetitle title="Patrouille/Roulage" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  
      <div class="modal-body">
  
        <div class="col-lg-12">
          <div class="card-body">
            <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="alertForm">
        <div class="card">
          <div class="card-body">
            <div class="form-alerte-container">
              <fieldset id="group-type-alerte" >
              <legend>Choix du type</legend>
              <div class="row">
              <div class="form-check mb-5 col-md-4">
                <input type="radio" name="type" onchange="CheckManuelle()" (click)="reset()" value="user" class="radio-type-intervention" id="type-intervention-2" formControlName="type">
                <label for="type-intervention-2">Par personnel</label>
              </div>
              <div class="form-check mb-5 col-md-4"> 
                <input type="radio" name="type" onchange="CheckMoyen()" (click)="reset()" value="moyen" class="radio-type-intervention" id="type-intervention-3" formControlName="type" >
                <label for="type-intervention-3">Par moyen</label>
              </div> 
            </div>
              </fieldset>
              
              <fieldset id="group-moyens" class="off">
              <legend>Choix du moyens</legend>
  
                <div class="row">
                <div *ngFor="let moyen of moyens | async" class="col-md-2">
                <input   type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getUserMoyen($event)">
                <label  for="{{moyen.name}}">{{moyen.name}}</label>
                </div>
              </div>
  
              </fieldset>
              
              <fieldset id="group-equipiers" class="off">
              <legend>Choix des équipiers</legend>
                <div class="row">
              <div *ngFor="let user of users | async">
                <input   type="checkbox" name="moyen-intervention" value="{{user.token}}" class="radio-equipier-intervention" id="{{user.uid}}" (change)="onCheckChange($event)">
                <label  class="label-equipier" for="{{user.uid}}"><img src="{{user.profilpic}}" onerror="this.src='../../../assets/images/picto/users.png';" alt=""><span>{{user.firstname}}</span></label>
              </div>
            </div>
              </fieldset>
              
            </div>
          </div>
  
          
        </div>
  
        
  
  
        
        
        <div class="card">
          <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                  <div class="form-group position-relative">
                    <label for="validationTooltip01">Date & Heure</label>
                    <input type="datetime-local" class="form-control" formControlName="date" id="validationTooltip01"
                      placeholder="Date" value=""
                      style="
                        background-color: gainsboro;"
                     >
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label for="validationTooltip01">Motif Général</label>
                      <input type="text" class="form-control" formControlName="motif" id="validationTooltip01"
                        placeholder="Motif Général" value="" style="
                        background-color: gainsboro;"
                    
                        [ngClass]="{'is-valid': formsubmit && formDataAlert.motif.errors}">
                      <div *ngIf="formsubmit && formDataAlert.motif.errors" class="valid-tooltip">
                        <span *ngIf="formDataAlert.motif.errors.required">OK!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label for="validationTooltip02">Précisions</label>
                      <input type="text" class="form-control" id="validationTooltip02" formControlName="motifsup"
                        placeholder="Précisions" value="" style="
                        background-color: gainsboro;"
                        [ngClass]="{'is-valid': formsubmit && formDataAlert.motifsup.errors}">
                      <div *ngIf="formsubmit && formDataAlert.motifsup.errors" class="valid-tooltip">
                        <span *ngIf="formDataAlert.motifsup.errors.required">OK!</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
  
                    
                      <div class="form-group">
                        <label>Adresse / Lieu</label>
                        <input type="text" class="form-control"  placeholder="Adresse la plus proche" 
                        autocorrect="off" autocapitalize="off" spellcheck="off"  style="
                        background-color: gainsboro;"
                        type="text" formControlName="adresse" aria-describedby="validationTooltipUsernamePrepend"
                      >
                      </div>
                    
                      <!-- <agm-map *ngIf="latitude && longitude" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                          (dragEnd)="markerDragEnd($event)"></agm-marker>
                      </agm-map>
                    
                      <h2 *ngIf="latitude && longitude">Adresse: {{address}}</h2> -->
                    </div>
                  </div>
                </div> 
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label for="validationTooltip03">Moyens</label>
                      <div class="form-group row">
                          <div class="row">
                        
                            
                          <div *ngFor="let moyen of moyens | async" class="col-md-4">
                            <input  class="form-control" type="checkbox" name="select{{moyen.name}}" value="{{moyen.name}}" class="radio-type-intervention" id="select{{moyen.name}}" (change)="onCheckChangeEngage($event)" >
                            <label  for="select{{moyen.name}}">{{moyen.name}}</label>
                            </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label for="validationTooltip04">Observation</label>
                      <textarea type="text" class="form-control" id="validationTooltip04" placeholder="" style="
                      background-color: gainsboro;"
                        formControlName="precision" [ngClass]="{'is-invalid': formsubmit && formDataAlert.precision.errors}">
                      </textarea>
                      <div *ngIf="formsubmit && formDataAlert.precision.errors" class="invalid-tooltip">
                        <span *ngIf="formDataAlert.precision.errors.required">ERREUR</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
  
                  
                
                   <label for="validationTooltip04">Niveau d'Alerte :</label>
                   <div class="form-group row">
                    <div class="row">
                    <div class="col-md-4" >
  
  
                      <input  class="form-control"  type="radio" name="radio-level-intervention" id="URGENCE RELATIVE" class="radio-level-intervention" formControlName="level" value="URGENCE RELATIVE">
                      <label class="urgence-relative" for="URGENCE RELATIVE">
                        URGENCE RELATIVE
                      </label>
                    </div>
                    <div class="col-md-4">
                      <input class="form-control" type="radio" name="radio-level-intervention" id="URGENT"  class="radio-level-intervention" formControlName="level" value="URGENT"
                        >
                      <label class="urgent" for="URGENT">
                        URGENT
                      </label>
                    </div>
                    <div class="col-md-4">
                      <input  class="form-control" type="radio" name="radio-level-intervention" id="URGENCE ABSOLUE" class="radio-level-intervention" formControlName="level" value="URGENCE ABSOLUE"
                        >
                      <label class="urgence-absolue" for="URGENCE ABSOLUE" >
                        URGENCE ABSOLUE
                      </label>
                    
                    </div>
  
                    </div>
                    
  
                  </div>
                    
                  </div> -->
                <button (click)="envoieNotif()" class="btn btn-primary" type="submit">Déclancher Patrouille/Roulage</button>
                </div>
        </div>
              </form>
            </div>
  
        
          <!-- end card -->
        </div>
      
      
  
        <div class="card" *ngIf="isSent == true" >
          <div class="card-body">
            
            <div class="reponses-container">
              <h3>Composition de l'équipe</h3>
              
              <ul>
                
                <li *ngFor="let user of usersResponse" class="{{user.response}}">
                  <input type="checkbox" name="equipier-intervention" value="EQUIPIER_ID" class="radio-equipier-validation" id="equipier-validation-1">
                  <label class="label-equipier-validation" for="equipier-validation-1"><img src="{{user.user.profilpic}}" onerror="this.src='../../../assets/images/picto/users.png';" alt=""><span>{{user.user.firstname}}</span></label>
                </li>
  
              </ul>  
                
          
              
            </div>
  
            <button (click)="update()">Raffraichir</button>
  
          </div>
        </div>
  
      </div>
  
    
  
   
  
  
   
  
  
  
  
  
  
  
  
  
  