import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { latLng, tileLayer } from 'leaflet';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { map } from 'rxjs/operators';



export class User {

  uid?:string;
  token?:string;
  firstname?:string;
  lastname?:string;
  dispo?:boolean;
  email?:string;
  phone?:string;
  profilpic?:string = "";
  skill1?:string;
  skill2?:string;
  skill3?:string;
  skill4?:string;
  skill5?:string;
  skill6?:string;
  skill7?:string;
  skill8?:string;
  skill9?:string;
  lastSeenInEpoch?:number;
  last_login?:number;
  presence?:boolean;




  constructor(user: User = {}) {
    this.uid = user.uid;
    this.token = user.token;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.dispo = user.dispo;
    this.phone = user.phone;
    this.profilpic = user.profilpic;
    this.skill1 = user.skill1;
    this.skill2 = user.skill2;
    this.skill3 = user.skill3;
    this.skill4 = user.skill4;
    this.skill5 = user.skill5;
    this.skill6 = user.skill6;
    this.skill7 = user.skill7;
    this.skill8 = user.skill8;
    this.skill9 = user.skill9;
    this.email = user.email;
    this.last_login = user.last_login;
     this.presence = user.presence;
     this.lastSeenInEpoch = user.lastSeenInEpoch;
  }


}


@Component({
  selector: 'app-roulage',
  templateUrl: './roulage.component.html',
  styleUrls: ['./roulage.component.scss']
})
export class RoulageComponent implements OnInit, AfterViewInit{

  @ViewChild('searchInput') public searchElementRef: ElementRef;

  

  alertForm: FormGroup;

  formsubmit: boolean;

  term: any;
  moyens: Observable<any[]>;

  users: Observable<any[]>;

  isSent: boolean = false;

  usersMoyen = [];

  notifResponse: Object;

  usersSelected = [];

  usersResponse: any[];

  responseFailure;
  responseSuccess;
  responseResults;

  
  

  //MAP


  latitude: number;
  longitude: number;
  zoom:number;
  address: string;
  private geoCoder;
  

  constructor(public formBuilder: FormBuilder,
              private modalService: NgbModal,
              private http: HttpClient,
              private db: AngularFirestore,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              ) {
  }

  

  ngAfterViewInit(): void {

  

     //load Places Autocomplete
     this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;


      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"],
        componentRestrictions: { 
          country: 'fr',
    }

      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    console.log(this.searchElementRef.nativeElement);


    

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;


  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  
  ngOnInit(): void {

   
    this.breadCrumbItems = [{ label: 'Admin' }, { label: 'Patrouille/Roulage', active: true }];
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    
  
    this.moyens = this.db.collection('moyens').valueChanges();

    this.users = this.db.collection('users').valueChanges();

    


    this.alertForm = this.formBuilder.group({
      type: ['', [Validators.required]],
      motif: ['', [Validators.required]],
      motifsup: ['', [Validators.required]],
      adresse: ['', [Validators.required, Validators.minLength(2)]],
      moyens: new FormArray([]),
      precision: ['', [Validators.required]],
      // level:['', [Validators.required]],
      lat: [[''], [Validators.required]],
      long: [[''], [Validators.required]],
      equipe: new FormArray([]),
      numero: Math.floor((Math.random()*9999)+1),
      date: [[''], [Validators.required]],
    });

   
  }

  
  onCheckChangeEngage(event) {
  const formArray: FormArray = this.alertForm.get('moyens') as FormArray;

  /* Selected */
  if(event.target.checked){
    // Add a new control in the arrayForm
    formArray.push(new FormControl(event.target.value));
  }
  /* unselected */
  else{
    // find the unselected element
    let i: number = 0;

    formArray.controls.forEach((ctrl: FormControl) => {
      if(ctrl.value == event.target.value) {
        // Remove the unselected element from the arrayForm
        formArray.removeAt(i);
        return;
      }

      i++;
    });
  }

  console.log(formArray);
}

  update(){

    this.alertForm.controls['equipe'].value.forEach((token,i) => {
      this.db.collection("users", ref => ref.where("token", "==", token)).doc(this.usersResponse[i].user.uid).collection("interventions").doc(this.alertForm.controls["numero"].value.toString()).get()
      .subscribe(intervention => {
        this.usersResponse[i].response = intervention.data().accepted;
        console.log(intervention.data().accepted)
      })
    });
  
    console.log("done")

  //   this.alertForm.controls['equipe'].value.forEach((token,i) => {
  //   this.db.collection("users", ref => ref.where("token", "==", token)).get().subscribe(querySnapshot => {
  //   querySnapshot.forEach(user => {
  //     user.collection("interventions").doc(this.alertForm.controls["numero"].value).valueChanges(
  //       intervention => {
  //         this.usersResponse[i].response = intervention.data().accepted;
  //         console.log(intervention.data().accepted)
  //       }
  //     )
  //   });
  // });

  // })
  // console.log("done")
  };

  async getUserMoyen(event){


    if(this.usersMoyen.length == 0){
  
          if(event.target.checked){
            this.db.collection('moyens').doc(event.target.value).collection('equipe').get()
              .subscribe(snapshot => {
                snapshot.docs.forEach(user => {
                  let token =  user.data().token;
                  this.usersMoyen.push(token);
                
                });
              });
            console.log(this.usersMoyen);
            
            console.log(event.target.value);
          }
    }else{
      this.usersMoyen = [];

      if(event.target.checked){
        this.db.collection('moyens').doc(event.target.value).collection('equipe').get()
          .subscribe(snapshot => {
            snapshot.docs.forEach(user => {
              let token =  user.data().token;
              this.usersMoyen.push(token);
            
            });
          });
        console.log(this.usersMoyen);
        
        console.log(event.target.value);
      }


    }
  }

   // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  onCheckChange(event) {
    const formArray: FormArray = this.alertForm.get('equipe') as FormArray;
  
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;
  
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
  
        i++;
      });
    }

    this.usersSelected = this.alertForm.controls['equipe'].value;

    console.log(this.alertForm.controls['equipe'].value);
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    console.log($event);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  /**
   * Open center modal
   * @param centerDataModal center modal data
   */
   centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, { centered: true });
  }


  /**
   * returns tooltip validation form
   */
   get formDataAlert() {
    return this.alertForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    this.formsubmit = true;
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }



  reset(){

    const formArray: FormArray = this.alertForm.get('equipe') as FormArray;
  
    formArray.removeAt(0);

    this.usersSelected = [];
    
    this.usersMoyen = [];
  }

  async envoieNotif(){
    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms))
    console.log(this.alertForm.controls['type'].value);
    console.log(this.address);
    if(this.alertForm.controls['type'].value == "moyen"){
    let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    let notification = {
      "notification": {
        "title": "🚨 PATROUILLE/ROULAGE 🚨",
        "body": `${this.alertForm.controls['motif'].value} - ${this.alertForm.controls['motifsup'].value}
Moyen: ${this.alertForm.controls['moyens'].value} 
Info: ${this.alertForm.controls['precision'].value} 
Cliquez ici!`,
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "alertmax.wav"
      }, "data": {
     "lat" : 41.677296098112286,
     "long" : 8.897548455238478,
    "adress" : this.alertForm.controls['adresse'].value,
    "statut": "PATROUILLE/ROULAGE",
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value
      },
      "registration_ids":this.usersMoyen,
      "priority": "high"
    }
    let url = 'https://fcm.googleapis.com/fcm/send';
    await delay(5000);
    this.http.post(url, notification, { headers: myheaders })
    .subscribe(results => {
      this.db.collection("interventions").doc(this.alertForm.controls['numero'].value.toString()).set({
        "lat" : 41.677296098112286,
        "long" : 8.897548455238478,
        "adress" : "STATION SNSM",
        "statut": "PATROUILLE/ROULAGE",
        "motif" : this.alertForm.controls['motif'].value,
        "motifsup" : this.alertForm.controls['motifsup'].value,
        "precision": this.alertForm.controls['precision'].value,
        "numero": this.alertForm.controls['numero'].value,
        "moyens": this.alertForm.controls['moyens'].value,
        "date" : new Date(this.alertForm.controls['date'].value)
      })
      this.notifResponse = results;
      console.log(results);
      this.isSent = true;
     }, error => {
      this.isSent = false;
      console.log(error);
    });
  } else {
    let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    let notification = {
      "notification": {
        "title": "🚨 PATROUILLE/ROULAGE 🚨",
        "body": `${this.alertForm.controls['motif'].value} - ${this.alertForm.controls['motifsup'].value}
Moyen: ${this.alertForm.controls['moyens'].value} 
Info: ${this.alertForm.controls['precision'].value} 
Cliquez ici!`,
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "alertmax.wav"
      }, "data": {
     "lat" : 41.677296098112286,
     "long" : 8.897548455238478,
    "adress" : "STATION SNSM",
    "statut": "PATROUILLE/ROULAGE",
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value
      },
      "registration_ids": this.alertForm.controls['equipe'].value,
      "priority": "high"
    }
    let url = 'https://fcm.googleapis.com/fcm/send';
    await delay(5000);
    this.http.post(url, notification, { headers: myheaders })
    .subscribe(results => {

      this.db.collection("interventions").doc(this.alertForm.controls['numero'].value.toString()).set({
        "lat" : 41.677296098112286,
        "long" : 8.897548455238478,
        "adress" : "STATION SNSM",
        "statut": "PATROUILLE/ROULAGE",
        "motif" : this.alertForm.controls['motif'].value,
        "motifsup" : this.alertForm.controls['motifsup'].value,
        "precision": this.alertForm.controls['precision'].value,
        "numero": this.alertForm.controls['numero'].value,
        "moyens": this.alertForm.controls['moyens'].value,
        "date" : new Date(this.alertForm.controls['date'].value)
      })

      console.log(results);
      this.notifResponse = results;

      var outputList = [];
      const promises = [];
      
      this.alertForm.controls['equipe'].value.forEach((token,i) => {
      let promise = this.db.collection("users", ref => ref.where("token", "==", token)).get().subscribe(snapshot => {
        if (snapshot.empty) {
            console.log('No matching documents.');
        }
        snapshot.forEach(doc => {
            if("error" in results["results"][i]){
            outputList.push({user : doc.data(), response : "error"});
            } else if ("message_id" in results["results"][i]){
              outputList.push({user : doc.data(), response : "success"});
            } else {
              console.log("caca");
            }
        });
        return;
      });
      promises.push(promise);
      },
      
    
      Promise.all(promises).then(() => {
        this.usersResponse = outputList;
      })
      )
      setTimeout(() => {

      
      }, 3000);
      this.isSent = true;
      
     }, error => {
      this.isSent = false;
      console.log("Erreur: "+error);
    });
  }
  }
}

