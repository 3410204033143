const timelineData = [
    {
        date: 'Interventions',
        title: 'Timeline event One',
        // tslint:disable-next-line: max-line-length
        text: 'It will be as simple as occidental in fact. To an english person, it will seem like simplified English, as a skeptical friend'
    },
    {
        align: 'left',
        date: 'Mains Courante',
        title: 'Timeline event Two',
        text: 'To achieve this, it would be necessary to have more common words.'
    },
    {
        date: 'Soins',
        title: 'Timeline event Three',
        text: 'The new common language will be more simple and regular than the existing European languages be as simple as occidental',
        images: ['assets/images/small/img-2.jpg', 'assets/images/small/img-3.jpg', 'assets/images/small/img-4.jpg']
    },
    {
        align: 'left',
        date: 'Divers',
        title: 'Formation/Exercice/Entrainement',
        // tslint:disable-next-line: max-line-length
        text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, ab illo inventore veritatis et'
    },
    {
        date: '02 April',
        title: 'Timeline event Five',
        text: 'Itaque earum rerum hic tenetur a sapiente delectus, ut aut doloribus asperiores.'
    },
];

export { timelineData };
