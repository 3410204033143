import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { InterventionsComponent } from './activite/interventions/interventions.component';
import { MaincouranteComponent } from './activite/maincourante/maincourante.component';
import { RoulageComponent } from './activite/roulage/roulage.component';
import { AlertComponent } from './alert/alert.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ListeComponent } from './personnel/liste/liste.component';
import { AffectationComponent } from './planning/affectation/affectation.component';

const routes: Routes = [
    { path: '', component: DashboardComponent , canActivate: [AuthGuard]},
    { path: 'alert', component: AlertComponent, canActivate: [AuthGuard] },
    { path: 'affectation', component: AffectationComponent, canActivate: [AuthGuard]},
    { path: 'maincourante', component: MaincouranteComponent, canActivate: [AuthGuard]},
    { path: 'interventions', component: InterventionsComponent, canActivate: [AuthGuard]},
    { path: 'roulage', component: RoulageComponent, canActivate: [AuthGuard]},
    { path: 'liste', component: ListeComponent, canActivate: [AuthGuard]},
    { path: 'activites', loadChildren: () => import('./activite/activite.module').then(m => m.ActiviteModule), canActivate: [AuthGuard] },
    { path: 'personnel', loadChildren: () => import('./personnel/personnel.module').then(m => m.PersonnelModule), canActivate: [AuthGuard] },
    { path: 'planning', loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule), canActivate: [AuthGuard] },
    { path: 'statistique', loadChildren: () => import('./statistique/statistique.module').then(m => m.StatistiqueModule), canActivate: [AuthGuard] },
    { path: 'configuration', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule), canActivate: [AuthGuard] },
    { path: 'logistique', loadChildren: () => import('./logistique/logistique.module').then(m => m.LogistiqueModule), canActivate: [AuthGuard] },
    { path: 'bibliotheque', loadChildren: () => import('./bibliotheque/bibliotheque.module').then(m => m.BibliothequeModule), canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
