<ngx-spinner></ngx-spinner>
<div class="container-fluid">
  <app-pagetitle title="Nouvelle Alerte" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <ng-template #centerDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Autres moyens</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
  <div class="modal-body">
    
    <div class="row" *ngFor="let rattachement of rattachements | async">
      <div class="col-md-12">
        <div class="form-group position-relative">
          <label for="validationTooltip03">{{rattachement.name}}</label>
          <div class="form-group row">
              <div class="row">
            
                
              <div *ngFor="let moyen of otherMoyens" class="moyenbutton">
              
                <input *ngIf="moyen.rattachement == rattachement.name" class="form-control" type="checkbox" name="select{{moyen.name}}" value="{{moyen.name}}" class="radio-type-intervention" id="select{{moyen.name}}" (change)="onCheckChangeEngage($event)" >
                <label  *ngIf="moyen.rattachement == rattachement.name" for="select{{moyen.name}}">{{moyen.name}}</label>
              
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

  </div>
  </ng-template>
  

    <div class="modal-body">

      <div class="col-lg-12">
        <div class="card-body">
          <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="alertForm">
      <div class="card">
        <div class="card-body">
          <div class="form-alerte-container">
            <fieldset id="group-type-alerte" >
            <legend>Type d'alerte</legend>
            <div class="row">
            <div class="form-check mb-5 col-md-4">
              <input type="radio" name="type" onchange="CheckGenerale()" (click)="reset()" value="alert" class="radio-type-intervention" id="type-intervention-1" formControlName="type" checked>
              <label for="type-intervention-1">Alerte générale</label>
            </div>  
            <div class="form-check mb-5 col-md-4">
              <input type="radio" name="type" onchange="CheckManuelle()" (click)="reset()" value="user" class="radio-type-intervention" id="type-intervention-2" formControlName="type">
              <label for="type-intervention-2">Sélection manuelle</label>
            </div>
            <div class="form-check mb-5 col-md-4"> 
              <input type="radio" name="type" onchange="CheckMoyen()" (click)="reset()" value="moyen" class="radio-type-intervention" id="type-intervention-3" formControlName="type" >
              <label for="type-intervention-3">Par moyen</label>
            </div> 
          </div>
            </fieldset>
            
            <fieldset id="group-moyens" class="off">
            <legend>Choix du moyens</legend>

              <div class="row">
              <div *ngFor="let moyen of moyens | async" class="col-md-2">
              <input   type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getUserMoyen($event)">
              <label  for="{{moyen.name}}">{{moyen.name}}</label>
              </div>
            </div>

            </fieldset>
            
            <fieldset id="group-equipiers" class="off">
            <legend>Choix des équipiers</legend>
              <div class="row">
            <div *ngFor="let user of users | async">
              <input   type="checkbox" name="moyen-intervention" value="{{user.token}}" class="radio-equipier-intervention" id="{{user.uid}}" (change)="onCheckChange($event)">
              <label  class="label-equipier" for="{{user.uid}}"><img src="{{user.profilpic}}" onerror="this.src='../../../assets/images/picto/users.png';" alt=""><span>{{user.firstname}}</span></label>
            </div>
          </div>
            </fieldset>
            
          </div>
        </div>

        
      </div>

      


      
      
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip01">Nom de l'appelant</label>
                <input type="text" class="form-control" formControlName="appelant" id="validationTooltip01"
                  placeholder="Nom de l'appelant" value="" style="
                  background-color: gainsboro;"
              
                  [ngClass]="{'is-valid': formsubmit && formDataAlert.appelant.errors}">
                <div *ngIf="formsubmit && formDataAlert.appelant.errors" class="valid-tooltip">
                  <span *ngIf="formDataAlert.appelant.errors.required">OK!</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip01">Numéro</label>
                <input type="text" class="form-control" formControlName="communication" id="validationTooltip01"
                  placeholder="Numéro" value="" style="
                  background-color: gainsboro;"
              
                  [ngClass]="{'is-valid': formsubmit && formDataAlert.communication.errors}">
                <div *ngIf="formsubmit && formDataAlert.communication.errors" class="valid-tooltip">
                  <span *ngIf="formDataAlert.communication.errors.required">OK!</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip01">Moyen de communication</label>
                <select class="form-control" (change)="changeMoyenCom($event)" formControlName="moyenCommunication">
                  <option value="" disabled>Choisir</option>
                  <option *ngFor="let moyen of moyensCom" [ngValue]="moyen">{{moyen}}</option>
               </select>
                <div *ngIf="formsubmit && formDataAlert.moyenCommunication.errors" class="valid-tooltip">
                  <span *ngIf="formDataAlert.moyenCommunication.errors.required">OK!</span>
                </div>
              </div>
            </div>
          </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label for="validationTooltip01">Motif Général</label>
                    <input type="text" class="form-control" formControlName="motif" id="validationTooltip01"
                      placeholder="Motif Général" value="" style="
                      background-color: gainsboro;"
                  
                      [ngClass]="{'is-valid': formsubmit && formDataAlert.motif.errors}">
                    <div *ngIf="formsubmit && formDataAlert.motif.errors" class="valid-tooltip">
                      <span *ngIf="formDataAlert.motif.errors.required">OK!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label for="validationTooltip02">Précisions</label>
                    <input type="text" class="form-control" id="validationTooltip02" formControlName="motifsup"
                      placeholder="Précisions" value="" style="
                      background-color: gainsboro;"
                      [ngClass]="{'is-valid': formsubmit && formDataAlert.motifsup.errors}">
                    <div *ngIf="formsubmit && formDataAlert.motifsup.errors" class="valid-tooltip">
                      <span *ngIf="formDataAlert.motifsup.errors.required">OK!</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="">

                  
                    <div class="form-group">
                      <label>Adresse / Lieu</label>
                      <input type="text" class="form-control"  placeholder="Adresse / Lieu" 
                      autocorrect="off" autocapitalize="off" spellcheck="off"  style="
                      background-color: gainsboro;"
                      type="text" formControlName="adresse" aria-describedby="validationTooltipUsernamePrepend"
                    >
                    </div>
                  
              
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="">

                  
                    <div class="form-group">
                      <label>Complément d'adresse</label>
                      <input type="text" class="form-control"  placeholder="Complément d'adresse" 
                      autocorrect="off" autocapitalize="off" spellcheck="off"  style="
                      background-color: gainsboro;"
                      type="text" formControlName="adressesup" aria-describedby="validationTooltipUsernamePrepend"
                    >
                    </div>
                  
              
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  
                  
                    <agm-map [latitude]="41.676941" [longitude]="8.897598005456967" [zoom]="12">
                      <agm-marker [latitude]="41.676941" [longitude]="8.897598005456967" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)"></agm-marker>
                    </agm-map>
                  
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group position-relative">
                    <label for="validationTooltip03">Moyens</label>
                    <div class="form-group row">
                        <div class="row">
                      
                          
                        <div *ngFor="let moyen of moyens | async" class="moyenbutton">
                          <input  class="form-control" type="checkbox" name="select{{moyen.name}}" value="{{moyen.name}}" class="radio-type-intervention" id="select{{moyen.name}}" (change)="onCheckChangeEngage($event)" >
                          <label  for="select{{moyen.name}}">{{moyen.name}}</label>
                          </div>
                          <div class="moyenbutton" (click)="otherCenter(centerDataModal)">
                            <button type="button" class="btn btn-danger dropdown-toggle">
                              Autres Centres
                    </button>
                          </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group position-relative">
                    <label for="validationTooltip04">Observation</label>
                    <textarea type="text" class="form-control" id="validationTooltip04" placeholder="" style="
                    background-color: gainsboro;"
                      formControlName="precision" [ngClass]="{'is-invalid': formsubmit && formDataAlert.precision.errors}">
                    </textarea>
                    <div *ngIf="formsubmit && formDataAlert.precision.errors" class="invalid-tooltip">
                      <span *ngIf="formDataAlert.precision.errors.required">ERREUR</span>
                    </div>
                  </div>
                </div>
                <div>

                
              
                 <label for="validationTooltip04">Niveau d'Alerte :</label>
                 <div class="form-group row">
                  <div class="row">
                  <div class="col-md-4" >


                    <input  class="form-control"  type="radio" name="level" id="URGENCE RELATIVE" class="radio-level-intervention" formControlName="level" value="URGENCE RELATIVE">
                    <label class="urgence-relative" for="URGENCE RELATIVE">
                      URGENCE RELATIVE
                    </label>
                  </div>
                  <div class="col-md-4">
                    <input class="form-control" type="radio" name="level" id="URGENT"  class="radio-level-intervention" formControlName="level" value="URGENT"
                      >
                    <label class="urgent" for="URGENT">
                      URGENT
                    </label>
                  </div>
                  <div class="col-md-4">
                    <input  class="form-control" type="radio" name="level" id="URGENCE ABSOLUE" class="radio-level-intervention" formControlName="level" value="URGENCE ABSOLUE"
                      >
                    <label class="urgence-absolue" for="URGENCE ABSOLUE" >
                      URGENCE ABSOLUE
                    </label>
                  
                  </div>

                  </div>
                  

                </div>
                  
                </div>
              <button (click)="envoieNotif()" class="btn btn-primary" type="submit">Déclancher Alerte</button>
              </div>
      </div>
            </form>
          </div>

      
        <!-- end card -->
      </div>
    
    

      <div class="card" *ngIf="isSent == true" >
        <div class="card-body">
          
          <div class="reponses-container">
            <h3>Composition de l'équipe</h3>
            
            <ul>
              
              <li *ngFor="let user of usersResponse" class="{{user.response}}">
                <input type="checkbox" name="equipier-intervention" value="EQUIPIER_ID" class="radio-equipier-validation" id="equipier-validation-1">
                <label class="label-equipier-validation" for="equipier-validation-1"><img src="{{user.user.profilpic}}" onerror="this.src='../../../assets/images/picto/users.png';" alt=""><span>{{user.user.firstname}}</span></label>
              </li>

            </ul>  
              
        
            
          </div>

          <button (click)="update()">Raffraichir</button>

        </div>
      </div>

    </div>

  

 


 









