import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-addperso',
  templateUrl: './addperso.component.html',
  styleUrls: ['./addperso.component.scss']
})
export class AddpersoComponent implements OnInit {

  addPersoForm: FormGroup;

  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;

  constructor(public formBuilder: FormBuilder,
    private db: AngularFirestore,
    private router: Router) { }

  ngOnInit(): void {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Form Validation', active: true }];



    this.addPersoForm = this.formBuilder.group({
      civilite: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      numbervoie: ['', [Validators.required]],
      lieudit: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postalcode: ['', [Validators.required]],
      departement: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
      aptitude: ['', [Validators.required]],
      validitydate: ['', [Validators.required]],
      validitysaison: [''],
      dispo: false,
      actif:false,
      role:"user"
      
    });

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;

  }

  /**
   * returns tooltip validation form
   */
   get formData() {
    return this.addPersoForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    if(this.addPersoForm.invalid){
      console.log("ERREUR");
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulaire Invalide!',
        footer: 'Vérifiez les champs'
      })
    }
    const id = this.addPersoForm.controls['email'].value;
    this.db.collection('users').doc(id).set(this.addPersoForm.value).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Ajouté(e) avec succés',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/']);
        }
      })
      
    });
    console.log(this.addPersoForm.value)
  }

}
