
  <ngx-spinner></ngx-spinner>
  <div class="d-flex justify-content-between"> 
    <div>
  <button class="btn btn-danger" [routerLink]="['/planning/planning']"><i class="mdi mdi-arrow-left"></i> Planning Général</button>
  </div>
  <div>
  <button class="btn btn-info" (click)="this.onPrintIconClick()" ><i class="mdi mdi-printer"></i> Imprimer</button>
  </div>
  <div>
  <button class="btn btn-success" (click)="this.getSavedEvents()" ><i class="mdi mdi-save"></i> Récuperer</button>
  </div>
  <div>
  <fieldset style="height: 50px;" class="float-right fieldsetInfo">
  <legend class="legendInfo">Infos</legend>
  
  <ul class="legende">
		<li class="info"><a href="#" class="color">Sous-effectif</a></li>
		<li class="info"><a href="#" class="color">Valide</a></li>

	</ul>
  

  
</fieldset>
</div>
</div>
  <button class="btn btn-primary float" (click)="getDispo()">Raffraichir <i class="mdi mdi-refresh"></i></button>
  
  <ejs-schedule #scheduleObj
  [currentView]="setView" 
  [views]="scheduleViews" 

  (eventRendered)="oneventRendered($event)"

  [workHours]="workHours"

  height="auto"
  width="auto"

  (renderCell)="onRenderCell($event)"

  [rowAutoHeight]="true"
  


  [group]="group"
  [timeScale]="timeScale"
  [selectedDate]="selectedDate"
  [firstDayOfWeek]='startWeek'

  [allowVirtualScrolling]="true"

  (popupOpen)='onPopupOpen($event)'
  (eventClick)='onEventClick($event)'
  >

  <e-views>
    <e-view option='TimelineWeek' interval="4" dateFormat='dd-MMM-yyyy'></e-view>
  </e-views>
   
  <e-resources>
  <e-resource 
            field="UserId" title="User" name="Users"
            [dataSource]="userDataSource | async" [allowMultiple]="allowMultipleCategory"
            textField="text" idField="id" colorField="color">
</e-resource>
        
</e-resources>


<ng-template #centerAutreDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Autres moyens</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
  <div class="modal-body">
    
    <div class="row" *ngFor="let rattachement of rattachements | async">
      <div class="col-md-12">
        <div class="form-group position-relative">
          <label for="validationTooltip03">{{rattachement.name}}</label>
          <div class="form-group row">
              <div class="row">
            
                
              <div *ngFor="let moyen of otherMoyens" class="moyenbutton">
              
                <input *ngIf="moyen.rattachement == rattachement.name" class="form-control" type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getMoyen($event)" >
                <label  *ngIf="moyen.rattachement == rattachement.name" for="{{moyen.name}}">{{moyen.name}}</label>
              
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

  </div>
  </ng-template>



<!-- <ng-template #resourceHeaderTemplate let-data>
            <div class='template-wrap'>
                <div class="room-name">{{data.userDataSource.text}}</div>
            </div>
</ng-template> -->

<ng-template #centerDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Affectation de {{start}}h à {{end}}h</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>

    <div class="modal-footer">
    <div class="col-lg-12">

    
                  <button (click)="affectUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary btn-block">Affecter</button>
    </div>
                </div>
    </ng-template>

    <ng-template #notcenterDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Retirer Affectation</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
    <button (click)="desaffectUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary col-md-12">Retirer</button>
               
    </div>
    </ng-template>


    <ng-template #dispocenterDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Ajouter une disponibilité</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Périodes</div>
          
              <div class="horizontal">
                <div *ngFor="let period of periods">
                    <input   type="checkbox" name="skill-intervention" value="{{period}}" class="{{period}}" id="{{period}}" (change)="getPeriod($event)">
                    <label  for="{{period}}">{{period}}</label>
                    </div>
              </div>
          </div>
      </div>
      </div>
    </div>
    <div class="modal-footer">
    <div class="col-lg-12">
    <button (click)="dispoAllUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-success float-left">Dispo 24h</button>
                  <button (click)="dispoUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary float-right">Ajouter</button>
                </div>
                </div>
    </ng-template>

    <ng-template #dateHeaderTemplate let-data>
        <div class="date-text" style="text-align: center; font-size: 13px; font-weight: bold; "
         >{{getDateHeaderText(data.date)}}</div>
    </ng-template>

    <ng-template #popContent let-data style="max-width: 100%;">


<div>
<span class="big border border-success" title="Équipiers"><i class="ri-group-fill"></i>{{getCountDispo(this.dateCount)}}</span>
</div> 
<div *ngFor="let name of this.namesOfDispo" style="font-weight: bolder; font-size: 18px;">{{name}}</div>
</ng-template>

  <ng-template #timeScaleMajorSlotTemplate let-data>
      <div style="color:white;" class="majorTime">{{getMajorTime(data.date)}}</div>
        <div style="vertical-align: middle; align-items: center; color:white;" popoverClass="popoverClass" [ngbPopover]="popContent" placement="bottom" container="body" (click)="getDate(data.date)">
          <i class='ri-user-fill' ></i>
          <i class='ri-information-line' ></i>
        </div>
    </ng-template>
    <ng-template #timeScaleMinorSlotTemplate let-data>
        <div class="minorTime">{{getMinorTime(data.date)}}</div>

    </ng-template>
  </ejs-schedule>

  <div>
  <button class="btn btn-success float-left" (click)="refreshCounter()">Raffraichir Compteurs <i class="mdi mdi-refresh"></i></button>
  
  <button class="btn btn-primary float-right" (click)="refresh()">Raffraichir <i class="mdi mdi-refresh"></i></button>
  </div>

