<ngx-spinner></ngx-spinner>

<div class="container-fluid">

  <app-pagetitle title="Liste Personnels" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <button class="btn btn-primary" (click)="getData()">Raffraichir <i class="mdi mdi-refresh"></i></button>



  <ng-template #centerDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Modifier Moyen</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" (click)="iconSelected=null" aria-hidden="true">×</button>
    </div>
  <div class="modal-body">
    <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="moyenForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip01">Immatriculation</label>
            <input type="text" class="form-control" formControlName="immatriculation" id="validationTooltip01"
              placeholder="{{moyen.immatriculation}}" value="{{moyen.immatriculation}}"
              [ngClass]="{'is-valid': formsubmit && moyenForm.controls['immatriculation'].errors}">
            <div *ngIf="formsubmit && moyenForm.controls['immatriculation'].errors" class="valid-tooltip">
              <span *ngIf="moyenForm.controls['immatriculation'].errors.required">Looks good!</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip02">Nom du moyen</label>
            <input type="text" class="form-control" id="validationTooltip02" formControlName="name"
              placeholder="{{moyen.name}}" value="{{moyen.name}}"
              [ngClass]="{'is-valid': formsubmit && moyenForm.controls['name'].errors}">
            <div *ngIf="formsubmit && moyenForm.controls['name'].errors" class="valid-tooltip">
              <span *ngIf="moyenForm.controls['name'].errors.required">Looks good!</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip03">Sous-type</label>
            <div class="input-group">
              <input type="text" class="form-control" id="validationTooltipUsername" formControlName="sousType"
                placeholder="{{moyen.sousType}}" value="{{moyen.sousType}}" aria-describedby="validationTooltipUsernamePrepend"
                [ngClass]="{'is-invalid': formsubmit && moyenForm.controls['sousType'].errors}">

              <div *ngIf="formsubmit && moyenForm.controls['sousType'].errors" class="invalid-tooltip">
                <span *ngIf="moyenForm.controls['sousType'].errors.required">Please choose a unique and valid username.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Type</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{moyen.type}}"
              formControlName="type" value="{{moyen.type}}" [ngClass]="{'is-invalid': formsubmit && moyenForm.controls['type'].errors}">
            <div *ngIf="formsubmit && moyenForm.controls['type'].errors" class="invalid-tooltip">
              <span *ngIf="moyenForm.controls['type'].errors.required">Type non valide.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Rattachement</label>
            <input type="text" class="form-control" id="validationTooltip05" placeholder="{{moyen.rattachement}}"
              formControlName="rattachement" value="{{moyen.rattachement}}" [ngClass]="{'is-invalid': formsubmit && moyenForm.controls['rattachement'].errors}">
            <div *ngIf="formsubmit && moyenForm.controls['rattachement'].errors" class="invalid-tooltip">
              <span *ngIf="moyenForm.controls['rattachement'].errors.required">Rattachement non valide</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Icon</label>
            <div class="dropdown" ngbDropdown>
              <button  type="button" class="btn dropdown-toggle" id="dropdownMenuButton"
                ngbDropdownToggle>
                <a *ngIf="!moyen.icon"> Choisir </a>
                <img *ngIf="moyen.icon" class="png-icon" src="{{moyen.icon}}" style="width: 24px; height: 24px;">
                <img *ngIf="iconSelected" class="png-icon" src="{{iconSelected}}" style="width: 24px; height: 24px;">
                <i class="mdi mdi-chevron-down"></i>
              </button>
              
              <div class="dropdown-menu" ngbDropdownMenu>
                <div *ngFor="let icon of icons" class="dropdown-item">
                  <div (click)="setIcon(icon)" >
                    <img class="png-icon" src="{{icon | async }}" style="width: 24px; height: 24px;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
        <button class="btn btn-danger" (click)="alertDelete(moyen.name)" type="button">Supprimer</button>
      </div>
      <div class="col-lg-6">
        <button class="btn btn-primary" (click)="formSubmit()" (click)="modal.dismiss('Cross click')" (click)="iconSelected=null" type="submit">Modifer Moyen</button>
      </div>
    </div>
  </form>
  </div>
  </ng-template>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Liste Moyens</h4>
          <p class="card-sub-title">
          </p>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select></label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <th sortable="name" (sort)="onSort($event)">Nom</th>
                  <th sortable="type" (sort)="onSort($event)">Type</th>
                  <th sortable="rattachement" (sort)="onSort($event)">Rattachement</th>
                  <th sortable="statut" (sort)="onSort($event)">Statut</th>
                  <th sortable="dispo" (sort)="onSort($event)">Dispo</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables$ | async">
                <tr>
                  <td (click)="openModal(table.name)">
                    <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td (click)="openModal(table.name)">
                    <ngb-highlight [result]="table.type" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td (click)="openModal(table.name)">
                    <ngb-highlight [result]="table.rattachement" [term]="service.searchTerm"></ngb-highlight>
              
                  </td>
                  <td>
                    <ngb-highlight [result]="table.statut" [term]="service.searchTerm"></ngb-highlight>
              
                  </td>
                  <td>
                    <div *ngIf="table.dispo == 'true'" class="dropdown" ngbDropdown container="body">
                      <button  type="button" class="btn btn-success dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>Disponible<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="changeStatut(true,table.name)">Disponible</a>
                        <a class="dropdown-item" (click)="changeStatut(false,table.name)">Indisponible</a>
                      </div>
                    </div>
                    <div *ngIf="table.dispo == 'false'" class="dropdown" ngbDropdown container="body">
                      <button type="button" class="btn btn-danger dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>Indisponible<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="changeStatut(true,table.name)">Disponible</a>
                        <a class="dropdown-item" (click)="changeStatut(false,table.name)">Indisponible</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <!-- <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                  <td align="center"> </td>
                  <td colspan="15" class="p-0">
                    <table class="table mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>End date</th>
                          <th>Unit</th>
                          <th>Sales Account</th>
                          <th>Buy Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{table.enddate}}</td>
                          <td>{{table.unit}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Affiche de
                {{service.startIndex}} à
                {{service.endIndex}} d'un total de {{service.totalRecords}}
                éléments
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-right pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
