import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { latLng, tileLayer } from 'leaflet';
import { AngularFirestore } from '@angular/fire/firestore';


import { ChartType, Stat, Chat, MainsCourantes } from './alert.model';

import { statData, revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, mainsCourantes } from './data';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { map } from 'rxjs/operators';



export class User {

  uid?:string;
  token?:string;
  firstname?:string;
  lastname?:string;
  dispo?:boolean;
  email?:string;
  phone?:string;
  profilpic?:string = "";
  skill1?:string;
  skill2?:string;
  skill3?:string;
  skill4?:string;
  skill5?:string;
  skill6?:string;
  skill7?:string;
  skill8?:string;
  skill9?:string;
  lastSeenInEpoch?:number;
  last_login?:number;
  presence?:boolean;




  constructor(user: User = {}) {
    this.uid = user.uid;
    this.token = user.token;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.dispo = user.dispo;
    this.phone = user.phone;
    this.profilpic = user.profilpic;
    this.skill1 = user.skill1;
    this.skill2 = user.skill2;
    this.skill3 = user.skill3;
    this.skill4 = user.skill4;
    this.skill5 = user.skill5;
    this.skill6 = user.skill6;
    this.skill7 = user.skill7;
    this.skill8 = user.skill8;
    this.skill9 = user.skill9;
    this.email = user.email;
    this.last_login = user.last_login;
     this.presence = user.presence;
     this.lastSeenInEpoch = user.lastSeenInEpoch;
  }


}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})



/**
 * Dashboard Component
 */
export class AlertComponent implements OnInit, AfterViewInit {

  @ViewChild('searchInput') public searchElementRef: ElementRef;

  

  alertForm: FormGroup;

  formsubmit: boolean;

  term: any;
  chatData: Chat[];
  mainsCourantes: MainsCourantes[];
  statData: Stat[];
  moyens: Observable<any[]>;

  

  users: Observable<any[]>;

  rattachements: Observable<any[]>;

  isSent: boolean = false;

  usersMoyen = [];

  otherMoyens = [];

  moyensCom = ["Téléphone", "Radio", "Autre"];

  notifResponse: Object;

  usersSelected = [];

  usersResponse: any[];

  moyenCom;

  responseFailure;
  responseSuccess;
  responseResults;

   

  
  

  //MAP


  latitude: number;
  longitude: number;
  zoom:number;
  address: string;
  private geoCoder;
  

  constructor(public formBuilder: FormBuilder,
              private modalService: NgbModal,
              private http: HttpClient,
              private db: AngularFirestore,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              ) {
  }

  

  ngAfterViewInit(): void {

  

     //load Places Autocomplete
     this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;


      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"],
        componentRestrictions: { 
          country: 'fr',
    }

      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    console.log(this.searchElementRef.nativeElement);


    

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;

  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  
  ngOnInit(): void {

   
    this.breadCrumbItems = [{ label: 'Admin' }, { label: 'Nouvelle alerte', active: true }];
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
    this._fetchData();

    
  
    this.moyens = this.db.collection('moyens',ref => ref.where("rattachement","==","SNSM Propriano")).valueChanges();

    this.users = this.db.collection('users').valueChanges();

    this.rattachements = this.db.collection('rattachements').valueChanges();

    this.db.collection('moyens').get().subscribe((doc)=>{
      doc.docs.forEach((moyen)=>{
        if(moyen.data()['rattachement']!="SNSM Propriano"){
          this.otherMoyens.push(moyen.data());
        }
      })
    });



    
  
    

    


    this.alertForm = this.formBuilder.group({
      appelant: ['', [Validators.required]],
      communication: ['', [Validators.required]],
      moyenCommunication: [this.moyenCom, [Validators.required]],
      type: ['', [Validators.required]],
      motif: ['', [Validators.required]],
      motifsup: ['', [Validators.required]],
      adresse: [this.address, [Validators.required, Validators.minLength(2)]],
      moyens: new FormArray([]),
      precision: ['', [Validators.required]],
      level:['', [Validators.required]],
      lat: [[''], [Validators.required]],
      long: [[''], [Validators.required]],
      equipe: new FormArray([]),
      numero: Math.floor((Math.random()*9999)+1),
      date: new Date(Date.now())
    });

   
  }

  changeMoyenCom(event){
    this.moyenCom = event.target.value;
  }

  
  onCheckChangeEngage(event) {
  const formArray: FormArray = this.alertForm.get('moyens') as FormArray;

  /* Selected */
  if(event.target.checked){
    // Add a new control in the arrayForm
    formArray.push(new FormControl(event.target.value));
  }
  /* unselected */
  else{
    // find the unselected element
    let i: number = 0;

    formArray.controls.forEach((ctrl: FormControl) => {
      if(ctrl.value == event.target.value) {
        // Remove the unselected element from the arrayForm
        formArray.removeAt(i);
        return;
      }

      i++;
    });
  }

  console.log(formArray);
}

  update(){

    this.alertForm.controls['equipe'].value.forEach((token,i) => {
      this.db.collection("users", ref => ref.where("token", "==", token)).doc(this.usersResponse[i].user.uid).collection("interventions").doc(this.alertForm.controls["numero"].value.toString()).get()
      .subscribe(intervention => {
        this.usersResponse[i].response = intervention.data().accepted;
        console.log(intervention.data().accepted)
      })
    });
  
    console.log("done")

  //   this.alertForm.controls['equipe'].value.forEach((token,i) => {
  //   this.db.collection("users", ref => ref.where("token", "==", token)).get().subscribe(querySnapshot => {
  //   querySnapshot.forEach(user => {
  //     user.collection("interventions").doc(this.alertForm.controls["numero"].value).valueChanges(
  //       intervention => {
  //         this.usersResponse[i].response = intervention.data().accepted;
  //         console.log(intervention.data().accepted)
  //       }
  //     )
  //   });
  // });

  // })
  // console.log("done")
  };

  async getUserMoyen(event){


    if(this.usersMoyen.length == 0){
  
          if(event.target.checked){
            this.db.collection('moyens').doc(event.target.value).collection('equipe').get()
              .subscribe(snapshot => {
                snapshot.docs.forEach(user => {
                  let token =  user.data().token;
                  this.usersMoyen.push(token);
                
                });
              });
            console.log(this.usersMoyen);
            
            console.log(event.target.value);
          }
    }else{
      this.usersMoyen = [];

      if(event.target.checked){
        this.db.collection('moyens').doc(event.target.value).collection('equipe').get()
          .subscribe(snapshot => {
            snapshot.docs.forEach(user => {
              let token =  user.data().token;
              this.usersMoyen.push(token);
            
            });
          });
        console.log(this.usersMoyen);
        
        console.log(event.target.value);
      }


    }
  }

   // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  onCheckChange(event) {
    const formArray: FormArray = this.alertForm.get('equipe') as FormArray;
  
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;
  
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
  
        i++;
      });
    }

    this.usersSelected = this.alertForm.controls['equipe'].value;

    console.log(this.alertForm.controls['equipe'].value);
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    console.log($event);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  private _fetchData() {
    this.revenueChart = revenueChart;
    this.salesAnalytics = salesAnalytics;
    this.sparklineEarning = sparklineEarning;
    this.sparklineMonthly = sparklineMonthly;
    this.chatData = chatData;
    this.mainsCourantes = mainsCourantes;
    this.statData = statData;
  }


  otherCenter(centerDataModal: any) {
    this.modalService.open(centerDataModal, { centered: true });
  }


  /**
   * returns tooltip validation form
   */
   get formDataAlert() {
    return this.alertForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    this.formsubmit = true;
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: 'right',
        name: 'Ricky Clark',
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes()
      });

      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null
      });
    }

    this.chatSubmit = true;
  }

  reset(){

    const formArray: FormArray = this.alertForm.get('equipe') as FormArray;
  
    formArray.removeAt(0);

    this.usersSelected = [];
    
    this.usersMoyen = [];
  }

  async envoieNotif(){
    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms))
    console.log(this.alertForm.controls['type'].value);
    console.log(this.address);
    if(this.alertForm.controls['type'].value == "alert"){
      let myheadersInfo = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
      let notificationInfo = {
        "notification": {
          "title": "🚨 Information Alerte 🚨",
          "body": `${this.alertForm.controls['motif'].value} à ${this.address}`,
          "click_action": "FCM_PLUGIN_ACTIVITY",
          "sound": "minalert.wav"
        },
        "android": {
          "ttl": 3600 * 1000,
          "notification": {
            color: '#ff0000',
            sound: 'minalert.wav',
            channel_id: 'my_id' // important to get custom sound
          }
        },  "data": {
        "type": "info",
       "lat" : this.latitude,
       "long" : this.longitude,
       "adress" : this.address,
       "motif" : this.alertForm.controls['motif'].value,
       "motifsup" : this.alertForm.controls['motifsup'].value,
       "moyens": this.alertForm.controls['moyens'].value,
       "numero": this.alertForm.controls['numero'].value,
       "precision": this.alertForm.controls['precision'].value
        },
        "to":"/topics/generalalert",
        "priority": "high"
      }
      let urlInfo = 'https://fcm.googleapis.com/fcm/send';
      this.http.post(urlInfo, notificationInfo, { headers: myheadersInfo })
      .subscribe(results => {
  
        console.log(results);
  
       }, error => {
        console.log(error);
       });
    let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    let notification = {
      "notification": {
        "title": "🚨 NOUVELLE ALERTE 🚨",
        "body": "Vous êtes sollicité pour une intervention! \n ➡️Cliquez pour découvrir⬅️",
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "alertmax.wav"
      }, 
      "android": {
        "ttl": 3600 * 1000,
        "notification": {
          color: '#ff0000',
          sound: 'minalert.wav',
          channel_id: 'my_id' // important to get custom sound
        }
      }, "data": {
        "type": "alert",
     "lat" : this.latitude,
     "long" : this.longitude,
     "adress" : this.address,
     "level": this.alertForm.controls['level'].value,
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value
      },
      "to":"/topics/generalalert",
      "priority": "high"
    }
    let url = 'https://fcm.googleapis.com/fcm/send';
    await delay(5000);
    this.http.post(url, notification, { headers: myheaders })
    .subscribe(results => {

      this.db.collection("interventions").doc(this.alertForm.controls['numero'].value.toString()).set({
        "lat" : this.latitude,
        "long" : this.longitude,
        "adress" : this.address,
        "motif" : this.alertForm.controls['motif'].value,
        "motifsup" : this.alertForm.controls['motifsup'].value,
        "precision": this.alertForm.controls['precision'].value,
        "numero": this.alertForm.controls['numero'].value,
        "moyens": this.alertForm.controls['moyens'].value,
        "level": this.alertForm.controls['level'].value,
        "statut": "Déclenchée",
        "date" : this.alertForm.controls['date'].value
      })
      console.log(results);
      this.notifResponse = results;
      this.isSent = true;
     }, error => {
      this.isSent = false;
      console.log(error);
    });
    
  } else if(this.alertForm.controls['type'].value == "moyen"){
    let myheadersInfo = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    let notificationInfo = {
      "notification": {
        "title": "🚨 Information Alerte 🚨",
        "body": `${this.alertForm.controls['motif'].value} à ${this.address}`,
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "minalert.wav"
      }, "data": {
        "type": "info",
     "lat" : this.latitude,
     "long" : this.longitude,
     "adress" : this.address,
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value
      },
      "to":"/topics/generalalert",
      "priority": "high"
    }
    let urlInfo = 'https://fcm.googleapis.com/fcm/send';
    await this.http.post(urlInfo, notificationInfo, { headers: myheadersInfo })
    .subscribe(results => {

      console.log(results);

     }, error => {
      console.log(error);
     })
    let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    let notification = {
      "notification": {
        "title": "🚨 NOUVELLE ALERTE 🚨",
        "body": "Vous êtes sollicité pour une intervention! \n ➡️Cliquez pour découvrir⬅️",
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "alertmax.wav"
      }, "data": {
        "type": "alert",
     "lat" : this.latitude,
     "long" : this.longitude,
     "adress" : this.address,
     "level": this.alertForm.controls['level'].value,
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value
      },
      "registration_ids":this.usersMoyen,
      "priority": "high"
    }
    let url = 'https://fcm.googleapis.com/fcm/send';
    await delay(5000);
    this.http.post(url, notification, { headers: myheaders })
    .subscribe(results => {
      this.db.collection("interventions").doc(this.alertForm.controls['numero'].value.toString()).set({
        "lat" : this.latitude,
        "long" : this.longitude,
        "adress" : this.address,
        "motif" : this.alertForm.controls['motif'].value,
        "motifsup" : this.alertForm.controls['motifsup'].value,
        "precision": this.alertForm.controls['precision'].value,
        "numero": this.alertForm.controls['numero'].value,
        "moyens": this.alertForm.controls['moyens'].value,
        "level": this.alertForm.controls['level'].value,
        "statut": "Déclenchée",
        "date" : this.alertForm.controls['date'].value
      })
      this.notifResponse = results;
      console.log(results);
      this.isSent = true;
     }, error => {
      this.isSent = false;
      console.log(error);
    });
  } else {
    let myheadersInfo = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    let notificationInfo = {
      "notification": {
        "title": "🚨 Information Alerte 🚨",
        "body": `${this.alertForm.controls['motif'].value} à ${this.address}`,
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "minalert.wav"
      }, "data": {
        "type": "info",
     "lat" : this.latitude,
     "long" : this.longitude,
     "adress" : this.address,
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value
      },
      "to":"/topics/generalalert",
      "priority": "high"
    }
    let urlInfo = 'https://fcm.googleapis.com/fcm/send';
     this.http.post(urlInfo, notificationInfo, { headers: myheadersInfo })
    .subscribe(results => {

      console.log(results);

     }, error => {
      console.log(error);
     })
    let myheaders: HttpHeaders = new HttpHeaders({ 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'});
    let notification = {
      "notification": {
        "title": "🚨 NOUVELLE ALERTE 🚨",
        "body": "Vous êtes sollicité pour une intervention! \n ➡️Cliquez pour découvrir⬅️",
        "click_action": "FCM_PLUGIN_ACTIVITY",
        "sound": "alertmax.wav"
      }, "data": {
        "type": "alert",
     "lat" : this.latitude,
     "long" : this.longitude,
     "adress" : this.address,
     "level": this.alertForm.controls['level'].value,
     "motif" : this.alertForm.controls['motif'].value,
     "motifsup" : this.alertForm.controls['motifsup'].value,
     "moyens": this.alertForm.controls['moyens'].value,
     "numero": this.alertForm.controls['numero'].value,
     "precision": this.alertForm.controls['precision'].value

      },
      "registration_ids": this.alertForm.controls['equipe'].value,
      "priority": "high"
    }
    let url = 'https://fcm.googleapis.com/fcm/send';
    await delay(5000);
    this.http.post(url, notification, { headers: myheaders })
    .subscribe(results => {

      this.db.collection("interventions").doc(this.alertForm.controls['numero'].value.toString()).set({
        "lat" : this.latitude,
        "long" : this.longitude,
        "adress" : this.address,
        "motif" : this.alertForm.controls['motif'].value,
        "motifsup" : this.alertForm.controls['motifsup'].value,
        "precision": this.alertForm.controls['precision'].value,
        "numero": this.alertForm.controls['numero'].value,
        "moyens": this.alertForm.controls['moyens'].value,
        "level": this.alertForm.controls['level'].value,
        "statut": "Déclenchée",
        "date" : this.alertForm.controls['date'].value
      })

      console.log(results);
      this.notifResponse = results;

      var outputList = [];
      const promises = [];
      
      this.alertForm.controls['equipe'].value.forEach((token,i) => {
      let promise = this.db.collection("users", ref => ref.where("token", "==", token)).get().subscribe(snapshot => {
        if (snapshot.empty) {
            console.log('No matching documents.');
        }
        snapshot.forEach(doc => {
            if("error" in results["results"][i]){
            outputList.push({user : doc.data(), response : "error"});
            } else if ("message_id" in results["results"][i]){
              outputList.push({user : doc.data(), response : "success"});
            }
        });
        return;
      });
      promises.push(promise);
      },
      
    
      Promise.all(promises).then(() => {
        this.usersResponse = outputList;
      })
      )
      setTimeout(() => {

      
      }, 3000);
      this.isSent = true;
      for(var moyenInter in this.moyens){
        this.db.collection("moyens").doc(moyenInter).update({
          "statut" : "Alerté"
        });
      }
      
     }, error => {
      this.isSent = false;
      console.log("Erreur: "+error);
    });
  }
  }
}
