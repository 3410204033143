import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModalModule, NgbDropdownModule, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { UiModule } from '../../shared/ui/ui.module';
import { CalendrierComponent } from './calendrier/calendrier.component';

import { PlanningRoutingModule } from './planning-routing.module';
import { AffectationComponent } from './affectation/affectation.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RecurrenceEditorModule, ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { AgendaComponent } from './agenda/agenda.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CalendrierSNB2Component } from './calendrier_SNB2/calendrierSNB2.component';
import { CalendrierSNB1Component } from './calendrier_SNB1/calendrierSNB1.component';
import { CalendrierINFComponent } from './calendrier_INF/calendrierINF.component';

@NgModule({
  declarations: [ CalendrierComponent, CalendrierSNB2Component,CalendrierSNB1Component,CalendrierINFComponent, AffectationComponent, AgendaComponent],
  imports: [
    CommonModule,  
    AngularSvgIconModule.forRoot(),
    NgbModalModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbPopoverModule,
    PlanningRoutingModule,
    CKEditorModule,
    FormsModule,
    DragDropModule,
    ScheduleModule,
    NgxSpinnerModule,
    RecurrenceEditorModule,
    UiModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
})
export class PlanningModule { }
