import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { UiModule } from '../../shared/ui/ui.module';

import { HabilitationsComponent } from './habilitations/habilitations.component';
import { GeneralComponent } from './general/general.component';
import { ParametrageComponent } from './parametrage/parametrage.component';
import { MonitoringComponent } from './monitoring/monitoring.component';

@NgModule({
  declarations: [HabilitationsComponent, GeneralComponent, ParametrageComponent, MonitoringComponent],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    UiModule
  ]
})
export class ConfigurationModule { }
