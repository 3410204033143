<ngx-spinner></ngx-spinner>

<script src="extensions/export/bootstrap-table-export.js"></script>

<div class="container-fluid">
  <app-pagetitle title="Main Courante" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Ajouter Main Courante</h4>
        <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="addMainCouranteForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip01">Date & Heure</label>
                <input type="datetime-local" class="form-control" formControlName="date" id="validationTooltip01"
                  placeholder="{{dateNow}}" value="{{dateNow}}"
                  [ngClass]="{'is-valid': formsubmit && addMainCouranteForm.controls['date'].errors}">
                <div *ngIf="formsubmit && addMainCouranteForm.controls['date'].errors" class="valid-tooltip">
                  <span *ngIf="addMainCouranteForm.controls['date'].errors.required">Looks good!</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip02">Mot Clés</label>
                <input type="text" class="form-control" id="validationTooltip02" formControlName="motclés"
                  placeholder="Mot Clés" value="Mot Clés"
                  [ngClass]="{'is-valid': formsubmit && addMainCouranteForm.controls['motclés'].errors}">
                <div *ngIf="formsubmit && addMainCouranteForm.controls['motclés'].errors" class="valid-tooltip">
                  <span *ngIf="addMainCouranteForm.controls['motclés'].errors.required">Looks good!</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip03">Origine</label>
                <div class="input-group">
                  <input type="text" class="form-control" id="validationTooltipUsername" formControlName="origine"
                    placeholder="Origine" aria-describedby="validationTooltipUsernamePrepend"
                    [ngClass]="{'is-invalid': formsubmit && addMainCouranteForm.controls['sousType'].errors}">

                  <div *ngIf="formsubmit && addMainCouranteForm.controls['origine'].errors" class="invalid-tooltip">
                    <span *ngIf="addMainCouranteForm.controls['origine'].errors.required">NON
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Destinataire</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="Destinataire"
                  formControlName="destinataire" [ngClass]="{'is-invalid': formsubmit && addMainCouranteForm.controls['destinataire'].errors}">
                <div *ngIf="formsubmit && addMainCouranteForm.controls['destinataire'].errors" class="invalid-tooltip">
                  <span *ngIf="addMainCouranteForm.controls['destinataire'].errors.required">Destinataire non valide.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Corps du message</label>
                <textarea type="text" class="form-control" id="validationTooltip05" placeholder="{{addMainCouranteForm.controls['motclés'].value}}"
                value="{{addMainCouranteForm.controls['motclés'].value}}"
                  formControlName="corps" [ngClass]="{'is-invalid': formsubmit && addMainCouranteForm.controls['corps'].errors}"></textarea>
                <div *ngIf="formsubmit && addMainCouranteForm.controls['corps'].errors" class="invalid-tooltip">
                  <span *ngIf="addMainCouranteForm.controls['corps'].errors.required">Message non valide</span>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">Ajouter Main Courante</button>
        </form>
      </div>
    </div>
    <!-- end card -->
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Main Courante</h4>
          <p class="card-sub-title">
          </p>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select></label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <th sortable="date" (sort)="onSort($event)">Date et Heure</th>
                  <th sortable="origine" (sort)="onSort($event)">Origine</th>
                  <th sortable="resume" (sort)="onSort($event)">Résumé</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables$ | async">
                <tr>
                  <td>
                    <ngb-highlight [result]="table.date" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.origine" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.resume" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                </tr>
                <!-- <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                  <td align="center"> </td>
                  <td colspan="15" class="p-0">
                    <table class="table mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>End date</th>
                          <th>Unit</th>
                          <th>Sales Account</th>
                          <th>Buy Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{table.enddate}}</td>
                          <td>{{table.unit}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Affiche de
                {{service.startIndex}} à
                {{service.endIndex}} d'un total de {{service.totalRecords}}
                éléments
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-right pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
