<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        2022 © :Trema.
      </div>
      <!-- <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          Crafted with <i class="mdi mdi-heart text-danger"></i> by L3M3R233
        </div>
      </div> -->
    </div>
  </div>
</footer>
