import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './advanced.model';

import { tableData } from './data';

import { AdvancedService } from './advanced.service';
import { ListemoyenDirective, SortEvent } from './listemoyen-directive';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-listemoye',
  templateUrl: './listemoyen.component.html',
  styleUrls: ['./listemoyen.component.scss'],
  providers: [AdvancedService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class ListemoyenComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;

  moyenForm: FormGroup;

  moyen: any;

  icons = [];

  iconSelected;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;

  hideme: boolean[] = [];

  // Table data
  tableData: Table[];

  @ViewChild('centerDataModal')
  public centerDataModal; 
  


  tables$: Observable<Table[]>;
  total$: Observable<number>;

  @ViewChildren(ListemoyenDirective) headers: QueryList<ListemoyenDirective>;

  constructor(public service: AdvancedService,
    public storage: AngularFireStorage,
    public db: AngularFirestore,
    public datePipe: DatePipe,
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
    ) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  async ngOnInit() {



    await this.getData();



    await this.storage.storage.ref("moyens").listAll().then((result)=>{
      result.items.forEach((image)=>{
        this.icons.push(image.getDownloadURL())
      })
    })

    


  

    

    


    this.breadCrumbItems = [{ label: 'Personnel' }, { label: 'Liste Personnels', active: true }];

    /**
     * fetch data
     */
    this._fetchData();

    

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;

  }

  /**
   * returns tooltip validation form
   */
   get formData() {
    return this.moyenForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    
    if(this.moyenForm.invalid){
      console.log(this.moyenForm.value)
      console.log("ERREUR");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Formulaire invalide!",
          footer: "Réessayez et verifiez les informations",
        })
    } else {
      if(this.moyenForm.controls['name'].value == this.moyen.name){
        console.log(this.moyenForm.value);
        
        this.db.collection('moyens').doc(this.moyen.name).update(this.moyenForm.value).then(()=>{
          console.log(this.moyenForm.value)
          this.getData();
            Swal.fire({
              icon: 'success',
              title: 'Modification(s) enregistrée(s)',
              showConfirmButton: false,
              timer: 1500
            })
          });
      } else {
        console.log("ERREUR");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Nom du moyen d'origine different!",
          footer: "Veuillez en créer un autre..",
        })
      }
      
      
    }
    
  }

  alertDelete(name){


    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez plus récuperer les données!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        this.db.collection("moyens").doc(name).delete().then(()=>{
          Swal.fire(
            'Supprimer!',
            "Moyen bien supprimer",
            'success'
          )
          this.modalService.dismissAll()
          this.getData()
        })
        
      }
    })


  }

  setIcon(icon: Promise<any>){
    icon.then((value)=>{
      console.log(value);
      this.iconSelected=value;
      this.moyenForm.controls['icon'].setValue(value);
    })
    
    

  }

  async changeStatut(boolean: boolean,name: string){

    if(name == null){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Erreur sur le moyen!",
      })
    } else {

    await this.db.collection("moyens").doc(name).update({
      dispo: boolean
    })

    console.log("done")

    this.getData()

  }
  }


  async getData(){

    this.spinner.show();
    this.iconSelected = null;


    this.tableData = [];
    tableData.length = 0;

    await this.db.collection("moyens").get().forEach((doc) => {
      doc.docs.forEach((moyen)=> { tableData.push(
      {
        name: moyen.data()["name"],
        rattachement: moyen.data()["rattachement"], 
        statut: moyen.data()["statut"],
        type: moyen.data()["type"], 
        dispo: moyen.data()["dispo"].toString()

      })
  
      
    })
  });

  this.spinner.hide()

  }


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.tableData = tableData;
    for (let i = 0; i <= this.tableData.length; i++) {
      this.hideme.push(true);
    }
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  async openModal(name){

    console.log(name);
    await this.db.collection("moyens").doc(name).get().subscribe((value) => {
      this.moyen = value.data();
      
      console.log(this.moyen);
      this.moyenForm = this.formBuilder.group({
        icon: [this.moyen? this.moyen.icon:""],
        name: [this.moyen? this.moyen.name:"", [Validators.required]],
        rattachement: [this.moyen? this.moyen.rattachement:"", [Validators.required]],
        statut: [this.moyen? this.moyen.statut:"Rentré", [Validators.required ]],
        type: [this.moyen? this.moyen.type:"", [Validators.required]],
        sousType: [this.moyen? this.moyen.sousType:"", [Validators.required]],
        immatriculation: [this.moyen? this.moyen.immatriculation:"", [Validators.required]],
        
      });
      this.modalService.open(this.centerDataModal, { centered: true , size: 'lg'});
    })
    

  }



}
