import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { classToPlain } from 'class-transformer';
import { find, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export class User {

  uid?:string;
  token?:string;
  firstname?:string;
  lastname?:string;
  dispo?:boolean;
  email?:string;
  phone?:string;
  profilpic?:string = "";
  skill1?:string;
  skill2?:string;
  skill3?:string;
  skill4?:string;
  skill5?:string;
  skill6?:string;
  skill7?:string;
  skill8?:string;
  skill9?:string;
  lastSeenInEpoch?:number;
  last_login?:number;
  presence?:boolean;




  constructor(user: User = {}) {
    this.uid = user.uid;
    this.token = user.token;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.dispo = user.dispo;
    this.phone = user.phone;
    this.profilpic = user.profilpic;
    this.skill1 = user.skill1;
    this.skill2 = user.skill2;
    this.skill3 = user.skill3;
    this.skill4 = user.skill4;
    this.skill5 = user.skill5;
    this.skill6 = user.skill6;
    this.skill7 = user.skill7;
    this.skill8 = user.skill8;
    this.skill9 = user.skill9;
    this.email = user.email;
    this.last_login = user.last_login;
     this.presence = user.presence;
     this.lastSeenInEpoch = user.lastSeenInEpoch;
  }


}

@Component({
  selector: 'app-affectation',
  templateUrl: './affectation.component.html',
  styleUrls: ['./affectation.component.scss']
})
export class AffectationComponent implements OnInit {

  breadCrumbItems: Array<{}>;

  moyens:Observable<any[]>;

  users:Observable<User[]>;

  usersMoyen:Observable<any[]>;

  moyenSelected:string;

  skillSelected:string;

  userSelectedUID:string;
  userMoyenSelectedUID:string;

  userSelected: User;

  dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi",
  "Samedi"];

  skills = ["PSE1","PSE2","SNB1","SNB2","Patron Vdt","Patron SR","Infirmier","Méca","SSA"]

  periods = ["Matin", "A-Midi", "Soir", "Nuit"];

  usersByPeriod = [];

  userDispo = [];

  daySelected:string;

  periodSelected:string;

  moyenIsSelected:boolean = false;

  nextTeam:boolean = false;

  moyenPatron:boolean = false;

  moyenIsCompleted:boolean = false;


  constructor(private db: AngularFirestore,
    private http: HttpClient) { }

  

  ngOnInit(): void {

    this.moyens = this.db.collection('moyens').valueChanges();


    this.users = this.db.collection<User>('users')
                  .valueChanges()
                  .pipe(map(collection => {
                        return collection.map(b =>  new User(b));
                    }));

    var today = new Date(Date.now());

  

    this.daySelected = this.dayNames[today.getDay()];

    this.breadCrumbItems = [{ label: 'Planning' }, { label: 'Affectation Journalière', active: true }];




    console.log(this.users);



  }

  selectDay(day){
    this.daySelected = day;

    this.usersByPeriod = [];

    this.selectPeriod(this.periodSelected);
  }

  selectSkill(skill){

    this.userDispo = [];


    this.skillSelected = skill;

    this.usersByPeriod.forEach((user)=>{
      if(user.skills.includes(skill)){
        this.userDispo.push(user)
      }
    })

  }



  selectPeriod(period){

    this.usersByPeriod = [];

    this.periodSelected = period;

    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
    ];


    this.db.collection("users").get().forEach(user =>{
      user.docs.forEach(user => {
        this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(monthNames[new Date().getMonth()]).get()
      .subscribe(calendar => {
        let obj = JSON.parse(calendar.data().allDays);

        for (let key in obj) {

          let date = new Date(key);
          let day = this.dayNames[date.getDay()];

          if(day == this.daySelected){
            if(obj[key].includes(this.periodSelected)){
            this.usersByPeriod.push(user.data());
            console.log(this.usersByPeriod)
            return
            }
          }
          
          

        }  


      })
    })

    
  })

  }

  getUserMoyen(event){
    if(event.target.checked){
      this.moyenIsSelected = true;
      this.moyenSelected = event.target.value;
      this.usersMoyen  = this.db.collection('moyens').doc(event.target.value).collection('equipe').valueChanges();
      console.log(event.target.value);
    }
  }

  async getUserMoyenID(event){
    this.userMoyenSelectedUID = event.target.value;
    console.log(this.userSelected);
    console.log(event.target.value)
}

  async getUserID(event){
      this.userSelectedUID = event.target.value;
      await this.users.pipe(map(users => users.find(user => user.uid == event.target.value))).subscribe(result => this.userSelected = result);
      console.log(this.userSelected);
      console.log(event.target.value)
  }

  deleteUserMoyen(){

    this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userMoyenSelectedUID).delete().then(() =>  console.log("DELETE SUCCESS"))

  }


  dropUserMoyen(event: CdkDragDrop<string[]>) {

    console.log(event.previousContainer);


    this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userMoyenSelectedUID).delete().then(() =>  console.log("DELETE SUCCESS"))

   
      console.log(event.container); 

  }

  dropUser(event: CdkDragDrop<string[]>) {

    console.log(event.previousContainer);

      this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userSelectedUID).set({
        uid : this.userSelected.uid,
        token: this.userSelected.token,
        firstname : this.userSelected.firstname,
        lastname : this.userSelected.lastname,
        dispo: this.userSelected.dispo,
        phone : this.userSelected.phone,
        profilpic : this.userSelected.profilpic,
        skill1 : this.userSelected.skill1,
        skill2 : this.userSelected.skill2,
        skill3 : this.userSelected.skill3,
        skill4 : this.userSelected.skill4,
        skill5 : this.userSelected.skill5,
        skill6 : this.userSelected.skill6,
        skill7 : this.userSelected.skill7,
        skill8 : this.userSelected.skill8,
        skill9 : this.userSelected.skill9,
        email : this.userSelected.email,
        presence : this.userSelected.presence,
        last_login : this.userSelected.last_login,
        lastSeenInEpoch : this.userSelected.lastSeenInEpoch,
      }).then(() =>     { console.log("ADD SUCCESS");
    //   let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
    // let notification = {
    //   "notification": {
    //     "title": "🚨 AFFECTATION 🚨",
    //     "body": "Vous êtes affecté sur le moyen "+this.moyenSelected,
    //     "click_action": "FCM_PLUGIN_ACTIVITY",
    //     "sound": "minalert.wav"
    //   }, "data": {
    //  "moyens": this.moyenSelected,
    //  "userid": this.userSelected.uid,
    //   },
    //   "to": this.userSelected.token,
    //   "priority": "high"
    // }
    // let url = 'https://fcm.googleapis.com/fcm/send';
    // this.http.post(url, notification, { headers: myheaders })
    // .subscribe(results => {
    //   console.log(results);
    //  }, error => {
    //   console.log(error);
    // });;
  }
      );
 
      
  
  }

  sendNotif(){
    
  }

  copyTeam(){
    
  }

}
