<ngx-spinner></ngx-spinner>
<button class="btn btn-primary float" (click)="getData()">Raffraichir <i class="mdi mdi-refresh"></i></button>


<ng-template #centerDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Ajouter un moyen</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
<div class="modal-body">
  <form class="needs-validation" [formGroup]="alertForm">
      <div class="card">
        <div class="card-body">
          <div class="form-alerte-container">
            
            <fieldset id="group-moyens" class="on">
            <legend>Choix du moyens</legend>

              <div class="row">
              <div *ngFor="let moyen of moyens | async" class="col-md-2">
              <input   type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getUserMoyen($event)">
              <label  for="{{moyen.name}}">{{moyen.name}}</label>
              </div>
            </div>

            </fieldset>
            
          
            
          </div>
        </div>

        
      </div>

      
      <button class="btn btn-primary"  (click)="formSubmit()" (click)="modal.dismiss('Cross click')" type="submit">Ajouter Moyen</button>
            </form>
            <!-- <div class="card" *ngIf="isSent == true" >
              <div class="card-body">
                
                <div class="reponses-container">
                  <h3>Composition de l'équipe</h3>
                  
                  <ul>
                    
                    <li *ngFor="let user of usersResponse" class="{{user.response}}">
                      <input type="checkbox" name="equipier-intervention" value="EQUIPIER_ID" class="radio-equipier-validation" id="equipier-validation-1">
                      <label class="label-equipier-validation" for="equipier-validation-1"><img src="{{user.user.profilpic}}" onerror="this.src='../../../assets/images/picto/users.png';" alt=""><span>{{user.user.firstname}}</span></label>
                    </li>
      
                  </ul>  
                    
              
                  
                </div>
      
                <button (click)="update()">Raffraichir</button>
      
              </div>
            </div> -->
</div>
</ng-template>



<div class="container-fluid">
  <app-pagetitle title="Interventions en Cours" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body card-body-tab">
          <h4 class="card-title">Interventions</h4>
          <p class="card-sub-title">
          </p>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select></label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <th sortable="date" (sort)="onSort($event)">Date et Heure</th>
                  <th sortable="statut" (sort)="onSort($event)">Statut</th>
                  <th sortable="moyens" (sort)="onSort($event)">Moyens</th>
                  <th sortable="resume" (sort)="onSort($event)">Résumé</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables$ | async;let i">
                <tr >
                  <td>
                    <ngb-highlight [result]="table.date" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <div class="dropdown" ngbDropdown container="body">
                      <button  type="button" class="btn {{table.statut}} dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>
                        {{table.statut}}<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="finishOps('Terminée',table.numero)">Terminée</a>
                      

                      </div>
                    </div>
                  </td>
                  <td >
                    <div *ngFor="let rattachement of rattachements">
                      <div *ngIf="table.moyens.find(ifContainRattachement(rattachement))!=null">

                        <h5>{{rattachement}}</h5>

                        <div style="display: table-cell !important;" *ngFor="let moyen of table.moyens"  class="dropdown" ngbDropdown container="body" >
                          <div *ngIf="moyen.rattachement == rattachement">
                          <button type="button" class="btn {{moyen.statut}} dropdown-toggle moyen" id="dropdownMenuButton"
                            ngbDropdownToggle>
                            <img *ngIf="moyen.icon" class="png-icon" src="{{moyen.icon}}" style="width: 24px; height: 24px;">{{moyen.name}}<i class="mdi mdi-chevron-down"></i>
                          </button>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" (click)="changeStatut('Départ',moyen.name,table.numero)">Départ</a>
                            <a class="dropdown-item" (click)="changeStatut('Sur les lieux',moyen.name,table.numero)">Sur les lieux</a>
                            <a class="dropdown-item" (click)="changeStatut('Disponible radio',moyen.name,table.numero)">Disponible radio</a>
                            <a class="dropdown-item" (click)="changeStatut('Retour Disponible',moyen.name,table.numero)">Retour Disponible</a>
                            <a class="dropdown-item" (click)="changeStatut('Retour Indisponible',moyen.name,table.numero)">Retour Indisponible</a>
                            <a class="dropdown-item" (click)="changeStatut('Retour en opération',moyen.name,table.numero)">Retour en opération</a>
                            <a class="dropdown-item" (click)="changeStatut('Rentré',moyen.name,table.numero)">Rentré</a>
                            <a class="dropdown-item" (click)="changeStatut('Départ annulé',moyen.name,table.numero)">Départ annulé</a>
                            <hr class="solid">
                            <a class="dropdown-item">Main courante</a>
    
                          </div>
                        </div>
                        </div>
                        <div style="border-top: 3px solid #bbb;"></div>
                      </div>
                      

                    </div>
                    
           
            
                    
                  </td>
                  <td>
                    <ngb-highlight [result]="table.resume" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td align="center" >
                    <a class="expand-row" (click)="openModal(table.numero)">
                      <button  type="button" class="btn btn-primary dropdown-toggle" id="dropdownMenuButton">
                        Renfort
                      </button>
                    </a>
                  </td>
                </tr>
                <!-- <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                  <td align="center"> </td>
                  <td colspan="15" class="p-0">
                    <table class="table mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>End date</th>
                          <th>Unit</th>
                          <th>Sales Account</th>
                          <th>Buy Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{table.enddate}}</td>
                          <td>{{table.unit}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Affiche de
                {{service.startIndex}} à
                {{service.endIndex}} d'un total de {{service.totalRecords}}
                éléments
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-right pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

