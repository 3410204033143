<ngx-spinner></ngx-spinner>

<div class="container-fluid">

  <app-pagetitle title="Liste Personnels" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <button class="btn btn-primary" (click)="getData()">Raffraichir <i class="mdi mdi-refresh"></i></button>


 
 <ng-template #centerDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Modifier Personnel</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
  <div class="modal-body">
    <form  class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="addPersoForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip01">Civilité</label>
              <select class="form-control" formControlName="civilite" value="{{user.civilite}}">
            <option value="Mr" selected>Mr</option>
            <option value="Mme">Mme</option>
          </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip02">Prénom</label>
            <input type="text" class="form-control" id="validationTooltip02" formControlName="firstname"
              placeholder="{{user.firstname}}" value="{{user.firstname}}"
              [ngClass]="{'is-valid': formsubmit && addPersoForm.controls['firstname'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['firstname'].errors" class="valid-tooltip">
              <span *ngIf="addPersoForm.controls['firstname'].errors.required">Looks good!</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip03">Nom</label>
            <div class="input-group">
              <input type="text" class="form-control" id="validationTooltipUsername" formControlName="lastname"
                placeholder="{{user.lastname}}" value="{{user.lastname}}" aria-describedby="validationTooltipUsernamePrepend"
                [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['lastname'].errors}">

              <div *ngIf="formsubmit && addPersoForm.controls['lastname'].errors" class="invalid-tooltip">
                <span *ngIf="addPersoForm.controls['lastname'].errors.required">Please choose a unique and valid username.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">N° de voie</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{user.numbervoie}}"
            value="{{user.numbervoie}}"
              formControlName="numbervoie" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['numbervoie'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['numbervoie'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['numbervoie'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Lieu-dit</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{user.lieudit}}"
            value="{{user.lieudit}}"
              formControlName="lieudit" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['lieudit'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['lieudit'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['lieudit'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Code Postal</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{user.postalcode}}"
            value="{{user.postalcode}}"
              formControlName="postalcode" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['postalcode'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['postalcode'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['postalcode'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Ville</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{user.city}}"
            value="{{user.city}}"
              formControlName="city" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['city'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['city'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['city'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Département</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{user.departement}}"
            value="{{user.departement}}"
              formControlName="departement" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['departement'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['departement'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['departement'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Téléphone</label>
            <input type="tel" class="form-control" id="validationTooltip03" placeholder="{{user.phone}}"
            value="{{user.phone}}"
              formControlName="phone" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['phone'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['phone'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['phone'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">E-mail</label>
            <input type="text" class="form-control" id="validationTooltip03" placeholder="{{user.email}}"
            value="{{user.email}}"
              formControlName="email" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['email'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['email'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['email'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Aptitude</label>
            <input type="aptitude" class="form-control" id="validationTooltip03" placeholder="{{user.aptitude}}"
            value="{{user.aptitude}}"
              formControlName="aptitude" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['aptitude'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['aptitude'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['aptitude'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group position-relative">
            <label for="validationTooltip4">Fin de validité médicale</label>
            <input type="date" class="form-control" id="validationTooltip03" placeholder="{{user.validitydate}}"
            value="{{user.validitydate}}"
              formControlName="validitydate" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['validitydate'].errors}">
            <div *ngIf="formsubmit && addPersoForm.controls['validitydate'].errors" class="invalid-tooltip">
              <span *ngIf="addPersoForm.controls['validitydate'].errors.required">Please provide a valid city.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group position-relative">
            <h3>Compétences</h3>
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('SNB1',user.skills)" value="SNB1">SNB1</label>
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('SNB2',user.skills)" value="SNB2">SNB2</label>
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('PSE1',user.skills)" value="PSE1">PSE1</label>
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('PSE2',user.skills)" value="PSE2">PSE2</label>       
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('Patron Vdt',user.skills)" value="Patron Vdt">Patron Vdt</label>  
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('Patron SR',user.skills)" value="Patron SR">Patron SR</label>  
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('Infirmier',user.skills)"value="Infirmier">Infirmier</label>  
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('Méca',user.skills)" value="Méca">Méca</label>  
            <label class="skilloption"><input type="checkbox" (change)="onCheckChange($event)" [checked]="checkCat('SSA',user.skills)" value="SSA">SSA</label>  
          </div>
        </div>
      
      </div>
      <div class="row">
      <div class="col-lg-6">
      <button class="btn btn-danger" (click)="alertDelete(user.uid)" type="button">Supprimer</button>
    </div>
    <div class="col-lg-6">
      <button class="btn btn-primary" (click)="formSubmit()" (click)="modal.dismiss('Cross click')" type="submit">Modifer Personnel</button>
    </div>
  </div>
    </form>
  </div>
  </ng-template>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Liste Personnels</h4>
          <p class="card-sub-title">
          </p>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select></label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <th sortable="name" (sort)="onSort($event)">Nom / Prénom</th>
                  <th sortable="phone" (sort)="onSort($event)">Téléphone</th>
                  <th sortable="mail" (sort)="onSort($event)">Email</th>
                  <th sortable="actif" (sort)="onSort($event)">Actif</th>
                  <th sortable="dispo" (sort)="onSort($event)">Disponibilités</th>
                  <th sortable="role" (sort)="onSort($event)">Rôle</th>
                  <th sortable="versionapp" (sort)="onSort($event)">Versions</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables$ | async">
                <tr>
                  <td (click)="openModal(table.uid,table.mail)" >
                    <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.phone" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.mail" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <div *ngIf="table.actif == 'true'" class="dropdown" ngbDropdown container="body">
                      <button  type="button" class="btn btn-primary dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>Actif<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="changeStatutActif(true,table.uid)">Actif</a>
                        <a class="dropdown-item" (click)="changeStatutActif(false,table.uid)">Inactif</a>
                      </div>
                    </div>
                    <div *ngIf="table.actif == 'false'" class="dropdown" ngbDropdown container="body">
                      <button type="button" class="btn btn-warning dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>Inactif<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="changeStatutActif(true,table.uid)">Actif</a>
                        <a class="dropdown-item" (click)="changeStatutActif(false,table.uid)">Inactif</a>
                      </div>
                    </div>
                    </td>
                  <td>
                  <div *ngIf="table.dispo == 'true'" class="dropdown" ngbDropdown container="body">
                    <button  type="button" class="btn btn-success dropdown-toggle" id="dropdownMenuButton"
                      ngbDropdownToggle>Disponible<i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item" (click)="changeStatutDispo(true,table.uid)">Disponible</a>
                      <a class="dropdown-item" (click)="changeStatutDispo(false,table.uid)">Indisponible</a>
                    </div>
                  </div>
                  <div *ngIf="table.dispo == 'false'" class="dropdown" ngbDropdown container="body">
                    <button type="button" class="btn btn-danger dropdown-toggle" id="dropdownMenuButton"
                      ngbDropdownToggle>Indisponible<i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item" (click)="changeStatutDispo(true,table.uid)">Disponible</a>
                      <a class="dropdown-item" (click)="changeStatutDispo(false,table.uid)">Indisponible</a>
                    </div>
                  </div>
                  </td>
                  <td>
                    <div *ngIf="table.role == 'admin'" class="dropdown" ngbDropdown container="body">
                      <button  type="button" class="btn btn-success dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>Patron<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="changeRole('admin',table.uid)">Patron</a>
                        <a class="dropdown-item" (click)="changeRole('user',table.uid)">Équipier</a>
                      </div>
                    </div>
                    <div *ngIf="table.role == 'user'" class="dropdown" ngbDropdown container="body">
                      <button type="button" class="btn btn-info dropdown-toggle" id="dropdownMenuButton"
                        ngbDropdownToggle>Équipier<i class="mdi mdi-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (click)="changeRole('admin',table.uid)">Patron</a>
                        <a class="dropdown-item" (click)="changeRole('user',table.uid)">Équipier</a>
                      </div>
                    </div>
                    </td>
                    <td>
                     
                  
                        <ngb-highlight *ngIf="table.versionapp != minVersion" style="color: red; font-weight: bold;" [result]="table.versionapp" [term]="service.searchTerm"></ngb-highlight>
                        <ngb-highlight *ngIf="table.versionapp == minVersion" [result]="table.versionapp" [term]="service.searchTerm"></ngb-highlight>
                        <div *ngIf="table.versionapp == null">
                          <p style="color: red; font-weight: bold;" >Non mis à jour</p>
                        </div>
            
        
            
                    
                  
                    </td>
                </tr>
                <!-- <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                  <td align="center"> </td>
                  <td colspan="15" class="p-0">
                    <table class="table mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>End date</th>
                          <th>Unit</th>
                          <th>Sales Account</th>
                          <th>Buy Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{table.enddate}}</td>
                          <td>{{table.unit}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Affiche de
                {{service.startIndex}} à
                {{service.endIndex}} d'un total de {{service.totalRecords}}
                éléments
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-right pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
          <p class="card-title-desc">🕔 : Saisonnier  ❗️⛑ : Validité Médicale bientôt dépassée   ❌⛑ : Validité Médicale expirée</p>
        </div>
      </div>
    </div>
  </div>
</div>
