import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddmoyenComponent } from './addmoyen/addmoyen.component';
import { AddpersoComponent } from './addperso/addperso.component';




const routes: Routes = [


    {
        path: 'addmoyen',
        component: AddmoyenComponent
    },
    {
        path: 'addperso',
    component: AddpersoComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LogistiqueRoutingModule { }
