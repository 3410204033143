<div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Ajout de personnel</h4>
        <p class="card-title-desc">Veuillez renseigner des informations
          <code>valides</code> et les plus <code>précises</code> possible.</p>
        <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="addPersoForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip01">Civilité</label>
                  <select class="form-control" formControlName="civilite">
                <option>Mr</option>
                <option>Mme</option>
              </select>
                <div *ngIf="formsubmit && addPersoForm.controls['civilite'].errors" class="valid-tooltip">
                  <span *ngIf="addPersoForm.controls['civilite'].errors.required">Looks good!</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip02">Prénom</label>
                <input type="text" class="form-control" id="validationTooltip02" formControlName="firstname"
                  placeholder="Prénom" value="Otto"
                  [ngClass]="{'is-valid': formsubmit && addPersoForm.controls['firstname'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['firstname'].errors" class="valid-tooltip">
                  <span *ngIf="addPersoForm.controls['firstname'].errors.required">Looks good!</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip03">Nom</label>
                <div class="input-group">
                  <input type="text" class="form-control" id="validationTooltipUsername" formControlName="lastname"
                    placeholder="Nom" aria-describedby="validationTooltipUsernamePrepend"
                    [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['lastname'].errors}">

                  <div *ngIf="formsubmit && addPersoForm.controls['lastname'].errors" class="invalid-tooltip">
                    <span *ngIf="addPersoForm.controls['lastname'].errors.required">Please choose a unique and valid username.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">N° de voie</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="N° de voie"
                  formControlName="numbervoie" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['numbervoie'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['numbervoie'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['numbervoie'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Lieu-dit</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="Lieu-dit"
                  formControlName="lieudit" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['lieudit'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['lieudit'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['lieudit'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Code Postal</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="Code Postal"
                  formControlName="postalcode" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['postalcode'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['postalcode'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['postalcode'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Ville</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="Ville"
                  formControlName="city" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['city'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['city'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['city'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Département</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="Département"
                  formControlName="departement" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['departement'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['departement'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['departement'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Téléphone</label>
                <input type="tel" class="form-control" id="validationTooltip03" placeholder="Téléphone"
                  formControlName="phone" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['phone'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['phone'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['phone'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">E-mail</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="E-mail"
                  formControlName="email" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['email'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['email'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['email'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Aptitude</label>
                <input type="aptitude" class="form-control" id="validationTooltip03" placeholder="Aptitude"
                  formControlName="aptitude" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['aptitude'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['aptitude'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['aptitude'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Fin de validité médicale</label>
                <input type="date" class="form-control" id="validationTooltip03" placeholder="Fin de validité médicale"
                  formControlName="validitydate" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['validitydate'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['validitydate'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['validitydate'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
           
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Validité saisonnière*</label>
                <input type="date" class="form-control" id="validationTooltip03" placeholder="Validité saisonnière"
                  formControlName="validitysaison" [ngClass]="{'is-invalid': formsubmit && addPersoForm.controls['validitysaison'].errors}">
                <div *ngIf="formsubmit && addPersoForm.controls['validitysaison'].errors" class="invalid-tooltip">
                  <span *ngIf="addPersoForm.controls['validitysaison'].errors.required">Please provide a valid city.</span>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">Ajouter Personnel</button>
        </form>
  
        <p class="card-title-desc">*Réservé pour la <code>supression automatique</code> des saisonniers</p>
    
      </div>
    </div>
    <!-- end card -->
  </div>
