import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { getUrlScheme } from '@angular/compiler';
import { UploadFileService } from 'src/app/core/services/upload-file.service';



@Component({
  selector: 'app-addmoyen',
  templateUrl: './addmoyen.component.html',
  styleUrls: ['./addmoyen.component.scss']
})
export class AddmoyenComponent implements OnInit {

  icons = [];

  iconSelected;

  addMoyenForm: FormGroup;

  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;

  constructor(public formBuilder: FormBuilder,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private service: UploadFileService) { }

  ngOnInit(): void {

    this.service.getImageDetailList();


    this.storage.storage.ref("moyens").listAll().then((result)=>{
      result.items.forEach((image)=>{
        this.icons.push(image.getDownloadURL())
      })
    })

   


    
    

  

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Form Validation', active: true }];



    this.addMoyenForm = this.formBuilder.group({
      immatriculation: ['', [Validators.required]],
      name: ['', [Validators.required, ]],
      sousType: ['', [Validators.required, ]],
      statut: ['Rentré'],
      type: ['', [Validators.required, ]],
      rattachement: ['', [Validators.required,]],
      icon: [''],
      dispo:false
    });

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;



  }

  /**
   * returns tooltip validation form
   */
   get formData() {
    return this.addMoyenForm.controls;
  }

  setIcon(icon: Promise<any>){
    icon.then((value)=>{
      console.log(value);
      this.iconSelected=value;
      this.addMoyenForm.controls['icon'].setValue(value);
    })
    
    

  }

  
  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    if(this.addMoyenForm.invalid){
      console.log("ERREUR");
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulaire Invalide!',
        footer: 'Vérifiez les champs'
      })
    } else {
    const id = this.addMoyenForm.controls['name'].value;
    this.db.collection('moyens').doc(id).set(this.addMoyenForm.value).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Ajouté(e) avec succés',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/']);
        }
      })
      
    });
    console.log(this.addMoyenForm.value)
  }
  }

}
