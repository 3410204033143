import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { latLng, tileLayer } from 'leaflet';
import { AngularFirestore } from '@angular/fire/firestore';


import { ChartType, Stat, Chat, MainsCourantes } from './dashboard.model';

import { statData, revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, mainsCourantes } from './data';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChildren('search') public searchElementRef: ElementRef;

  alertForm: FormGroup;

  formsubmit: boolean;

  term: any;
  chatData: Chat[];
  mainsCourantes: MainsCourantes[];
  statData: Stat[];
  moyens: Observable<any[]>;

  isSent: boolean = false;

  

  //MAP

  latitude: number;
  longitude: number;
  zoom:number;
  address: string;
  private geoCoder;
  

  constructor(public formBuilder: FormBuilder,
              private modalService: NgbModal,
              private http: HttpClient,
              private db: AngularFirestore,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              ) {
  }

  

  ngAfterViewInit(): void {

    


    //  //load Places Autocomplete
    //  this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    

    //   const inputElement = this.searchElementRef.nativeElement.getElementsByTagName('input')[0];

    //   let autocomplete = new google.maps.places.Autocomplete(inputElement, {
    //     types: ["address"],
    //     componentRestrictions: { country: 'fr' }

    //   });
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.zoom = 12;
    //     });
    //   });
    // });

    // console.log(this.searchElementRef.nativeElement);

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;

  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  
  ngOnInit(): void {

   
    this.breadCrumbItems = [{ label: 'Admin' }, { label: 'Tableau de bord', active: true }];
    // this.formData = this.formBuilder.group({
    //   message: ['', [Validators.required]],
    // });
    // this._fetchData();

    
  
    // this.moyens = this.db.collection('moyens').valueChanges();


    // this.alertForm = this.formBuilder.group({
    //   motif: ['', [Validators.required]],
    //   motifsup: ['', [Validators.required]],
    //   adresse: ['', [Validators.required, Validators.minLength(2)]],
    //   moyens: ['', [Validators.required]],
    //   precision: ['', [Validators.required]],
    //   level:['', [Validators.required]],
    //   lat: [[''], [Validators.required]],
    //   long: [[''], [Validators.required]],
    //   numero: Math.floor((Math.random()*9999)+1)
    // });

  }

  //  // Get Current Location Coordinates
  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }

  // markerDragEnd($event: google.maps.MouseEvent) {
  //   console.log($event);
  //   this.latitude = $event.latLng.lat();
  //   this.longitude = $event.latLng.lng();
  //   this.getAddress(this.latitude, this.longitude);
  // }

  // getAddress(latitude, longitude) {
  //   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     console.log(results);
  //     console.log(status);
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         this.zoom = 12;
  //         this.address = results[0].formatted_address;
  //         console.log(this.address);
          
  //       } else {
  //         window.alert('No results found');
  //       }
  //     } else {
  //       window.alert('Geocoder failed due to: ' + status);
  //     }

  //   });
  // }

  private _fetchData() {
    this.revenueChart = revenueChart;
    this.salesAnalytics = salesAnalytics;
    this.sparklineEarning = sparklineEarning;
    this.sparklineMonthly = sparklineMonthly;
    this.chatData = chatData;
    this.mainsCourantes = mainsCourantes;
    this.statData = statData;
  }

  /**
   * Open center modal
   * @param centerDataModal center modal data
   */
   centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, { centered: true });
  }

  openApp() {
    window.open("https://snsm-propv01.web.app/", '_blank');
 }


  /**
   * returns tooltip validation form
   */
   get formDataAlert() {
    return this.alertForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    this.formsubmit = true;
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  // messageSave() {
  //   const message = this.formData.get('message').value;
  //   const currentDate = new Date();
  //   if (this.formData.valid && message) {
  //     // Message Push in Chat
  //     this.chatData.push({
  //       align: 'right',
  //       name: 'Ricky Clark',
  //       message,
  //       time: currentDate.getHours() + ':' + currentDate.getMinutes()
  //     });

  //     // Set Form Data Reset
  //     this.formData = this.formBuilder.group({
  //       message: null
  //     });
  //   }

  //   this.chatSubmit = true;
  // }

  // envoieNotif(){
  //   let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
  //   let notification = {
  //     "notification": {
  //       "title": "NOUVELLE ALERTE",
  //       "body": "Veuillez déclarer votre disponibilté",
  //       "click_action": "FCM_PLUGIN_ACTIVITY",
  //       "sound": "default"
  //     }, "data": {
  //    "lat" : this.latitude,
  //    "long" : this.longitude,
  //    "adress" : this.alertForm.controls['adresse'].value,
  //    "motif" : this.alertForm.controls['motif'].value,
  //    "motifsup" : this.alertForm.controls['motifsup'].value,
  //    "moyens": this.alertForm.controls['moyens'].value,
  //    "numero": this.alertForm.controls['numero'].value,
  //    "precision": this.alertForm.controls['precision'].value

  //     },
  //     "to":"f3-x-jH4Tqat_nWrAnDahI:APA91bHetob1dke1-HuvxTGzTLuqMCQywb9tLND-_lus5pzzsU-SL0_wQzwjVygY8dJsz1C3r73Fg7l7dsFIPNJ6uzNprYi1ymoMfUg9MjTePcVi_Wf--IAke5Ujcmk0u69sT3eKoE23",
  //     "priority": "high"
  //   }
  //   let url = 'https://fcm.googleapis.com/fcm/send';
  //   this.http.post(url, notification, { headers: myheaders })
  //   .subscribe(results => {
  //     console.log(results);
  //     this.isSent = true;
  //    }, error => {
  //     this.isSent = false;
  //     console.log(error);
  //   });
  // }
}
