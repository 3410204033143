<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="40">
        
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="40">
           
          </span>
          
        </a>

        <a href="/" style="text-align: center;" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="50">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="50">
           
          </span>
          
          
        </a>
        
      </div>
      

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="ri-search-line"></span>
        </div>
      </form> -->

      <div class="dropdown d-none d-lg-block ml-2" ngbDropdown>
        <button ngbDropdownToggle type="button" class="btn header-item waves-effect" data-toggle="dropdown"
          aria-haspopup="false" aria-expanded="false">
          Ajout Rapide
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu" style="position: fixed;top: 41px;left: auto;right: auto;float: none;" ngbDropdownMenu>
            <div class="col-sm-12">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="font-size-14">Ajout Rapide</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a class="dropdown-item dropdown-item-profil" [routerLink]="['logistique/addperso']" href="./ins_personnel.php?category=INT&amp;suggestedcompany=-1" data-original-title="" title="">
                        <i class="fas fa-plus-circle" style="color:#28A745" data-original-title="" title=""></i>
                       Personnel </a>
                    </li>
                    <li>
                      <a class="dropdown-item dropdown-item-profil" [routerLink]="['logistique/addmoyen']" data-original-title="" title="">
                        <i class="fas fa-plus-circle" style="color:#28A745" data-original-title="" title=""></i>
                       Véhicule </a>
                    </li>
                  </ul>
                </div>

              
              </div>
            </div>
      

        </div>
      </div>
    </div>

    <div class="d-flex">

      <!-- <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->

      

      <!-- <div class="dropdown d-none d-lg-inline-block ml-1" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="ri-apps-2-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github">
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox">
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

          <i class="ri-notification-3-line"></i>
          <span *ngIf="(notifs| async)?.length > 0" class="noti-dot"></span>
 
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0"> Notifications </h6>
              </div>
              
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <a *ngIf="(notifs| async)?.length == 0" href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                
                </div>
                <div class="media-body">
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Aucune notification.</p>
                  
                  </div>
                  
                </div>
              </div>
            </a>
            <a *ngFor="let notif of notifs | async " href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i *ngIf="notif.type=='calendrier'" class="ri-calendar-check-line"></i>
                    <i *ngIf="notif.type=='user'" class="ri-file-user-line"></i>
                    <i *ngIf="notif.type=='moyen'" class="ri-car-washing-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{notif.text}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{notif.author}}</p>
                  
                  </div>
                </div>
              </div>
            </a>
            
          </perfect-scrollbar>
          <div class="p-2 border-top" (click)="deleteNotifs()">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-delete-forever mr-1"></i> Supprimer 
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button *ngIf="user" type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img  class="rounded-circle header-profile-user" src="{{user.profilpic}}" onerror="this.src='../../../../../assets/images/picto/user.png';" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">{{user.firstname}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Déconnexion</a>
        </div>
      </div>

    </div>
  </div>
</header>
