import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './advanced.model';

import { tableData } from './data';

import { AdvancedService } from './advanced.service';
import { MaincouranteDirective, SortEvent } from './maincourante-directive';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/core/services/excel.service';


import jspdf from 'jspdf';
import 'jspdf-autotable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-maincoiurante',
  templateUrl: './maincourante.component.html',
  styleUrls: ['./maincourante.component.scss'],
  providers: [AdvancedService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class MaincouranteComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  addMainCouranteForm: FormGroup;
  modifMCForm: FormGroup;
  hideme: boolean[] = [];

  // Table data
  tableData: Table[];

  head = [['Date & Heure', 'Origine', 'Résumé']]

  data = [];

  maincourante:any;


  tables$: Observable<Table[]>;
  total$: Observable<number>;


  dateNow: string;

  @ViewChildren(MaincouranteDirective) headers: QueryList<MaincouranteDirective>;
  @ViewChild('maincouranteTable') userTable: ElementRef;
  @ViewChild('centerDataModal')
  public centerDataModal; 




  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;

  constructor(public service: AdvancedService,
    private spinner: NgxSpinnerService,
    public db: AngularFirestore,
    public datePipe: DatePipe,
    public formBuilder: FormBuilder,
    public excelService: ExcelService,
    private modalService: NgbModal) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  async ngOnInit() {

    this.dateNow = this.datePipe.transform(Date.now(),"yyyy/MM/dd HH:mm"),


    this.addMainCouranteForm = this.formBuilder.group({
      motcles: ['', [Validators.required, ]],
      date: [new Date(''), [Validators.required]],
      origine: ['', [Validators.required, ]],
      destinataire: ['', [Validators.required, ]],
      corps: ['', [Validators.required, ]],
      numero: Math.floor((Math.random()*9999)+1),
    });


    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;

    await this.getData();

    


  

    

    


    this.breadCrumbItems = [{ label: 'Activités' }, { label: 'Main Courante', active: true }];

    /**
     * fetch data
     */
    this._fetchData();

    
    

  }

  /**
   * returns tooltip validation form
   */
   get formData() {
    return this.addMainCouranteForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   async formSubmit() {
    if(this.addMainCouranteForm.invalid){
      console.log("ERREUR");
    }
    else {
    this.spinner.show();
    this.db.collection('maincourantes').doc(this.addMainCouranteForm.controls["numero"].value.toString()).set(this.addMainCouranteForm.value);
    console.log(this.addMainCouranteForm.value)
    await this.getData();
    this.spinner.hide();
    }
  }


  exportElmToExcel(): void {
    this.excelService.exportTableElmToExcel(this.userTable, 'maincourante');
  }

  createPdf() {


    console.log(tableData);
    
    var doc = new jspdf();

    doc.setFontSize(18);
    doc.text('Main Courante', 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);


    (doc as any).autoTable({
      head: this.head,
      body: this.data,
      theme: 'plain',
      didDrawCell: data => {
        console.log(data.column.index)
      }
    })

    // Open PDF document in new tab
    doc.output('dataurlnewwindow')

    // Download PDF document  
    doc.save('maincourante.pdf');
  }

  changeSubmit() {
    if(this.modifMCForm.invalid){
      console.log("ERREUR");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Formulaire invalide!",
          footer: "Réessayez et verifiez les informations",
        })
    } else {

    
    this.db.collection('maincourantes').doc(this.maincourante.numero.toString()).update(this.modifMCForm.value).then(()=>{
      console.log(this.modifMCForm.value)
    this.getData();
      Swal.fire({
        icon: 'success',
        title: 'Modification(s) enregistrée(s)',
        showConfirmButton: false,
        timer: 1500
      })
    });
    
    }

    console.log(tableData);
    
  }


  async getData(){

    this.data = [];
    this.tableData = [];
    tableData.length = 0;

    await this.db.collection("soins").get().forEach((doc) => {

      doc.docs.forEach((soin)=> { 
        if(this.datePipe.transform(new Date(Date.now()),"yyyy/MM/dd") == this.datePipe.transform(soin.data()["date"],"yyyy/MM/dd")){
        tableData.push(
      {
        date: this.datePipe.transform(soin.data()["date"],"yyyy/MM/dd HH:mm"), 
        origine: "Soin", 
        resume: soin.data()["pathologie"],
        numero: soin.data()["numero"]
      })
        this.data.push(
          [
            this.datePipe.transform(soin.data()["date"],"yyyy/MM/dd HH:mm"), 
        "Soin", 
          soin.data()["pathologie"],
          ]
        )
    }
  
      
    })
  });

    await this.db.collection("interventions").get().forEach((doc) => {
      
      doc.docs.forEach((inter)=> { 
        if(this.datePipe.transform(new Date(Date.now()),"yyyy/MM/dd") == this.datePipe.transform(new Date(inter.data()["date"]["seconds"]*1000),"yyyy/MM/dd")){
        tableData.push(
      {
        date: this.datePipe.transform(new Date(inter.data()["date"]["seconds"]*1000),"yyyy/MM/dd HH:mm"), 
        origine: "Intervention", 
        resume: inter.data()["motif"]+" "+inter.data()["motifsup"]+" "+inter.data()["adress"],
        numero: inter.data()["numero"]
      })

      this.data.push(
        [
          this.datePipe.transform(new Date(inter.data()["date"]["seconds"]*1000),"yyyy/MM/dd HH:mm"), 
      "Intervention", 
      inter.data()["motif"]+" "+inter.data()["motifsup"]+" "+inter.data()["adress"],
        ]
      )

    }


      
    })
  });

  await this.db.collection("maincourantes").get().forEach((doc) => {
    doc.docs.forEach((inter)=> { 
      if(this.datePipe.transform(new Date(Date.now()),"yyyy/MM/dd") == this.datePipe.transform(inter.data()["date"],"yyyy/MM/dd")){
      tableData.push(
    {
      date: this.datePipe.transform(inter.data()["date"],"yyyy/MM/dd HH:mm"), 
      origine: inter.data()["motcles"], 
      resume: inter.data()["corps"],
      numero: inter.data()["numero"]
    })
    this.data.push(
      [
        this.datePipe.transform(inter.data()["date"],"yyyy/MM/dd HH:mm"), 
        inter.data()["motcles"], 
        inter.data()["corps"],
      ]
    )
  }

    
  })
});

  }


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.tableData = tableData;
    for (let i = 0; i <= this.tableData.length; i++) {
      this.hideme.push(true);
    }

  }  

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  async openModal(numero){

  
      console.log(numero);
      await this.db.collection("maincourantes").doc(numero.toString()).get().subscribe((value) => {
        this.maincourante = value.data();

        this.modifMCForm = this.formBuilder.group({
          motcles: [this.maincourante? this.maincourante.motcles:"", [Validators.required, ]],
          date: [this.maincourante? this.maincourante.date:"", [Validators.required]],
          origine: [this.maincourante? this.maincourante.origine:"", [Validators.required, ]],
          destinataire: [this.maincourante? this.maincourante.destinataire:"", [Validators.required, ]],
          corps: [this.maincourante? this.maincourante.corps:"", [Validators.required, ]],
          
        });

        this.modalService.open(this.centerDataModal, { centered: true , size: 'lg'});
        console.log(this.maincourante);
      })
    
    
    

  }


}
