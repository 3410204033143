import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MonitoringComponent } from './monitoring/monitoring.component';
import { HabilitationsComponent } from './habilitations/habilitations.component';
import { GeneralComponent } from './general/general.component';
import { ParametrageComponent } from './parametrage/parametrage.component';

const routes: Routes = [
    {
        path: 'monitoring',
        component: MonitoringComponent
    },
    {
        path: 'habilitations',
        component: HabilitationsComponent
    },
    {
        path: 'general',
        component: GeneralComponent
    },
    {
        path: 'parametrage',
        component: ParametrageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
