import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../../shared/ui/ui.module';
import { NgbPaginationModule, NgbTypeaheadModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { BibliothequeRoutingModule } from './bibliotheque-routing.module';
import { ArchivesComponent } from './archives/archives.component';
import { BibliothequeComponent } from './bibliotheque/bibliotheque.component';

@NgModule({
  declarations: [ ArchivesComponent, BibliothequeComponent],
  imports: [
    CommonModule,
    BibliothequeRoutingModule,
    UiModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    FormsModule,

    ReactiveFormsModule
  ]
})
export class BibliothequeModule { }
