import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './advanced.model';

import { tableData } from './data';

import { AdvancedService } from './advanced.service';
import { ListeDirective, SortEvent } from './liste-directive';
import { AngularFirestore } from '@angular/fire/firestore';
import { getDateCount } from '@syncfusion/ej2-schedule';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
  providers: [AdvancedService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class ListeComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;

  addPersoForm: FormGroup;

  user: any;

  today: Date;
  validityalert: Date;


  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;


  nuitStart;
  nuitEnd;
  matinStart;
  matinEnd;
  amidiStart;
  amidiEnd;
  soirStart;
  soirEnd;

  minVersion;

  dateNow: Date;

  hideme: boolean[] = [];

  // Table data
  tableData: Table[];

  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
    ];

  @ViewChild('centerDataModal')
  public centerDataModal; 
  


  tables$: Observable<Table[]>;
  total$: Observable<number>;

  @ViewChildren(ListeDirective) headers: QueryList<ListeDirective>;

  constructor(public service: AdvancedService,
    public db: AngularFirestore,
    public datePipe: DatePipe,
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
    ) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  async ngOnInit() {


    this.today = new Date(Date.now());
    

    this.validityalert = new Date(this.today.setMonth(this.today.getMonth()-2));

    console.log(this.validityalert);

    this.dateNow = new Date(Date.now());

    this.db.collection("commons").doc("variables").get().subscribe((value) => {
  
      this.nuitStart = value.data()["nuitStart"],
      this.nuitEnd = value.data()["nuitEnd"],
      this.matinStart = value.data()["matinStart"],
      this.matinEnd = value.data()["matinEnd"],
      this.amidiStart =  value.data()["amidiStart"],
      this.amidiEnd =  value.data()["amidiEnd"],
      this.soirStart =  value.data()["soirStart"],
      this.soirEnd =  value.data()["soirEnd"],
      this.minVersion =  value.data()["lastversion"]
    

    }
    );
   

    await this.getData();


    
    


  

    

    


    this.breadCrumbItems = [{ label: 'Personnel' }, { label: 'Liste Personnels', active: true }];



    

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;

  }

  /**
   * returns tooltip validation form
   */
   get formData() {
    return this.addPersoForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    if(this.addPersoForm.invalid){
      console.log("ERREUR");
      console.log(this.addPersoForm.invalid.valueOf);
      
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Formulaire invalide!",
          footer: "Réessayez et verifiez les informations",
        })
    } else {

    
    this.db.collection('users').doc(this.user.uid).update(this.addPersoForm.value).then(()=>{
      console.log(this.addPersoForm.value)
    this.getData();
      Swal.fire({
        icon: 'success',
        title: 'Modification(s) enregistrée(s)',
        showConfirmButton: false,
        timer: 1500
      })
    });
    
    }
  }

  alertDelete(uid){


    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez plus récuperer les données!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        this.db.collection("users").doc(uid).delete().then(()=>{
          Swal.fire(
            'Supprimer!',
            "L'utilisateur bien supprimer",
            'success'
          )
          this.modalService.dismissAll()
          this.getData()
        })
        
      }
    })


  }

 


  async getData(){

    

    this.spinner.show();


    this.tableData = [];
    tableData.splice(0, tableData.length);
    tableData.length = 0;

    

  await this.db.collection("users").get().forEach(user =>{
    

    user.docs.forEach(user => {
      

      

      if(user.data()["uid"] != null){


        var uid = user.data()["uid"];


      this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).get()
    .subscribe(async calendars => {

  

      
      if(calendars.data()){

        let calendar = JSON.parse(calendars.data()["allDays"]);
  
        
  
          for(var key in calendar){
            
            if(key.includes(this.datePipe.transform((this.dateNow),"yyyy-MM-dd 00:00:00.000"))){
              if(calendar[key].includes("Matin") && this.dateNow.getHours() >= this.matinStart &&
                  this.dateNow.getHours() < this.matinEnd){
                await this.db.collection("users").doc(uid).update({
                  "dispo": true
                });
  
                  } else
                if(calendar[key].includes("A-Midi") &&
                    this.dateNow.getHours() >= this.amidiStart && this.dateNow.getHours() < this.amidiEnd){
                      this.db.collection("users").doc(uid).update({
                        "dispo": true
                      });
                }
                else
                  if(calendar[key].includes("Soir") &&
                      this.dateNow.getHours() >= this.soirStart && this.dateNow.getHours() < this.soirEnd ){
                        await this.db.collection("users").doc(uid).update({
                          "dispo": true
                        });
                  }
                  else
                    if(calendar[key].includes("Nuit") &&
                    this.dateNow.getHours() == this.nuitStart && this.dateNow.getHours() < this.nuitEnd){
                        await this.db.collection("users").doc(uid).update({
                            "dispo": true
                          });
                    }
                    else
                      if(calendar[key].find((n) =>
                      n.includes("Perso") &&
                          parseInt(n.substring(n.indexOf("=") + 1).split(
                              ":")[0]) <= this.dateNow.getHours() &&
                          parseInt(n.substring(n.indexOf("=") + 1).split(
                              ":")[1]) > this.dateNow.getHours()
  
                      )){
  
                        await this.db.collection("users").doc(uid).update({
                          "dispo": true
                        });
                      } else
                        {
                          console.log("indispo:"+uid);
                          
                          await this.db.collection("users").doc(uid).update({
                            "dispo": false
                          });
                        
  
                        };
            };
          };
  
  
        


    };


  })

  

}

    })

  }).then((value => {
    setTimeout(()=>{
      this.db.collection("users",ref => ref.orderBy('lastname')).get().forEach((doc) => {
        doc.docs.forEach((user)=> { 
  
  
          
          
          
          
          if(user.data()["validitysaison"] != null){
            if(new Date(Number(Date.parse(user.data()["validitysaison"])))<this.today){
              console.log("Saisonnier date expired");
              user.ref.delete()
            } else if(new Date(Number(Date.parse(user.data()["validitydate"])))<this.validityalert){
  
              tableData.push(
                {
                  name: user.data()["lastname"]+" "+user.data()["firstname"]+" 🕔 /❗️⛑", 
                  phone: user.data()["phone"],
                  mail: user.data()["email"],
                  actif: user.data()["actif"].toString(), 
                  dispo: user.data()["dispo"].toString(),
                  uid: user.data()["uid"], 
                  role: user.data()["role"], 
                  versionapp: user.data()["version_biper"]
            
                })
    
              } else if(new Date(Number(Date.parse(user.data()["validitydate"])))<this.today){
                tableData.push(
                  {
                    name: user.data()["lastname"]+" "+user.data()["firstname"]+" 🕔 / ❌⛑", 
                    phone: user.data()["phone"], 
                    mail: user.data()["email"],
                    actif: user.data()["actif"].toString(), 
                    dispo: user.data()["dispo"].toString(),
                    uid: user.data()["uid"], 
                    role: user.data()["role"], 
                    versionapp: user.data()["version_biper"]
            
                  })
              }
            
            else {
              tableData.push(
                {
                  name: user.data()["lastname"]+" "+user.data()["firstname"]+" 🕔", 
                  phone: user.data()["phone"], 
                  mail: user.data()["email"],
                  actif: user.data()["actif"].toString(), 
                  dispo: user.data()["dispo"].toString(),
                  uid: user.data()["uid"], 
                  role: user.data()["role"], 
                  versionapp: user.data()["version_biper"]
          
                })
            }
            
          } else {
  
            if(new Date(Number(Date.parse(user.data()["validitydate"])))<this.validityalert){
  
            tableData.push(
              {
                name: user.data()["lastname"]+" "+user.data()["firstname"]+" ❗️⛑", 
                phone: user.data()["phone"], 
                mail: user.data()["email"],
                actif: user.data()["actif"].toString(), 
                dispo: user.data()["dispo"].toString(),
                uid: user.data()["uid"], 
                role: user.data()["role"], 
                versionapp: user.data()["version_biper"]
        
              })
  
            } else if(new Date(Number(Date.parse(user.data()["validitydate"])))<this.today){
              tableData.push(
                {
                  name: user.data()["lastname"]+" "+user.data()["firstname"]+" ❌⛑", 
                  phone: user.data()["phone"], 
                  mail: user.data()["email"],
                  actif: user.data()["actif"].toString(), 
                  dispo: user.data()["dispo"].toString(),
                  uid: user.data()["uid"], 
                  role: user.data()["role"], 
                  versionapp: user.data()["version_biper"]
          
                })
            } else {
  
              tableData.push(
                {
                  name: user.data()["lastname"]+" "+user.data()["firstname"], 
                  phone: user.data()["phone"], 
                  mail: user.data()["email"],
                  actif: user.data()["actif"].toString(), 
                  dispo: user.data()["dispo"].toString(),
                  uid: user.data()["uid"], 
                  role: user.data()["role"], 
                  versionapp: user.data()["version_biper"]
          
                })
  
            }
  
            
  
          }
  
          
    
        
      })
    }).then(value => {
      this.spinner.hide()
      console.log(tableData);
      
    });
    },5000)
    
    
  }))
  



  }


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.tableData = tableData;
    for (let i = 0; i <= this.tableData.length; i++) {
      this.hideme.push(true);
    }
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  async changeStatutDispo(boolean: boolean,uid: string){


    console.log(this.datePipe.transform(new Date(Date.now()),"yyyy-MM-dd 00:00:00.000"))

    if(uid == null){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous ne pouvez pas modifier le statut!",
        footer: "L'utilisateur ne s'est pas encore enregistré sur mySNSM",
      })
    } else {

    if(boolean==false){
      await this.db.collection("users").doc(uid).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).get()
      .subscribe(calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.datePipe.transform(new Date(Date.now()),"yyyy-MM-dd 00:00:00.000")){
            objDispo[key].splice(0,objDispo[key].length);
          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      this.db.collection("users").doc(uid).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).update({
        allDays : newObj
      })

    })
    }
    else if(boolean==true){
      await this.db.collection("users").doc(uid).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).get()
      .subscribe(calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.datePipe.transform(new Date(Date.now()),"yyyy-MM-dd 00:00:00.000")){
            objDispo[key] = ["Nuit","Matin","A-Midi","Soir"];
          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      this.db.collection("users").doc(uid).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).update({
        allDays : newObj
      }).then(()=> console.log("OUI")
      )

    })
    }

    await this.db.collection("users").doc(uid).update({
      dispo: boolean
    })

    console.log("done")

    this.getData()

  }
  }

  async changeRole(role: string,uid: string){

    if(uid == null){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous ne pouvez pas modifier le statut!",
        footer: "L'utilisateur ne s'est pas encore enregistré sur mySNSM",
      })
    } else {

    await this.db.collection("users").doc(uid).update({
      role: role
    })

    console.log("done")

    this.getData()

  }
  }

  async changeStatutActif(boolean: boolean,uid: string){

    if(uid == null){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Vous ne pouvez pas modifier le statut!",
        footer: "L'utilisateur ne s'est pas encore enregistré sur mySNSM",
      })
    } else {

    await this.db.collection("users").doc(uid).update({
      actif: boolean
    })

    console.log("done")

    this.getData()

  }
  }

  async openModal(uid,mail){

    if(uid == null){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Vous ne pouvez pas modifier l'utilisateur! Voulez vous le suprrimer?",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler',
          footer: "L'utilisateur ne s'est pas encore enregistré sur mySNSM",
        }).then((result)=>{
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Êtes-vous sûr?',
              text: "Vous ne pourrez plus récuperer les données!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui, supprimer!',
              cancelButtonText: 'Annuler'
            }).then((result) => {
              if (result.isConfirmed) {
                this.db.collection("users").doc(mail).delete().then(()=>{
                  Swal.fire(
                    'Supprimer!',
                    "L'utilisateur bien supprimer",
                    'success'
                  )
                  this.modalService.dismissAll()
                  this.getData()
                })
                
              }
            })
            
          }
        })

        
    } else{
      console.log(uid);
      await this.db.collection("users").doc(uid).get().subscribe((value) => {
        this.user = value.data();

        let skillsArray = new FormArray([]);

        if (this.user) {
          if(this.user.skills){
            for (let skill of this.user.skills) {
            
            skillsArray.push(
              new FormControl(skill)
            );
          }
        }
        }

        console.log(skillsArray);
        

        this.addPersoForm = this.formBuilder.group({
          civilite: [this.user? this.user.civilite:""],
          firstname: [this.user? this.user.firstname:"", [Validators.required]],
          lastname: [this.user? this.user.lastname:"", [Validators.required]],
          numbervoie: [this.user? this.user.numbervoie:"", [Validators.required]],
          lieudit: [this.user? this.user.lieudit:"", [Validators.required]],
          city: [this.user? this.user.city:"", [Validators.required]],
          postalcode: [this.user? this.user.postalcode:"", [Validators.required]],
          departement: [this.user? this.user.departement:"", [Validators.required]],
          phone: [this.user? this.user.phone:"", [Validators.required]],
          email: [this.user? this.user.email:"", [Validators.required]],
          aptitude: [this.user? this.user.aptitude:"", [Validators.required]],
          validitydate: [this.user? this.user.validitydate:"", [Validators.required]],
          skills: skillsArray
    
          
        });

        this.modalService.open(this.centerDataModal, { centered: true , size: 'lg'});
        console.log(this.user);
      })
    }
    
    

  }

  onCheckChange(event) {
    const formArray: FormArray = this.addPersoForm.get('skills') as FormArray;
    
    console.log(formArray);
    
  
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
      console.log(event.target.value);
      
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;

      console.log(event.target.value);
  
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          
          
          return;
        }
  
        i++;
      });
    }
  }

  checkCat(data, skills){
    let ref = skills.find(x => x == data); // this checks the cat[] array
    if(ref == data){
      return true;
    } else {
      return false;
    }
   }



}
