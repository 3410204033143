import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventRenderedArgs,  GroupModel, PopupOpenEventArgs, RenderCellEventArgs, ScheduleComponent, TimelineViewsService, TimeScaleModel, View, WorkHoursModel, PrintService, ScheduleModel } from '@syncfusion/ej2-angular-schedule';
import {  Internationalization, setCulture, loadCldr, L10n,} from '@syncfusion/ej2-base';
import { firestore } from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { User } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';

setCulture('fr');

declare var require: any

export class Dispo{


  eventIndex?:number;
  Id?:number;
  Subject?:string;
  StartTime?: Date;
  EndTime?: Date;
  PeriodId?: number;
  UserId?: string;
  UserToken?:string;
  Color?:string;
  simpleStart?:string;
  simpleEnd?:string;
  isAffected?:boolean;
  moyenAffected?:string;
  isInter?:boolean;
  number?:number;
  FullName?:string;
  Skills?:Array<string>;


  
}



@Component({
  providers: [TimelineViewsService, PrintService],
  selector: 'app-calendrierSNB1',
  template: `
  <ngx-spinner></ngx-spinner>
  <div class="d-flex justify-content-between"> 
    <div>
  <button class="btn btn-danger" [routerLink]="['/planning/planning']"><i class="mdi mdi-arrow-left"></i> Planning Général</button>
  </div>
  <div>
  <button class="btn btn-info" (click)="this.onPrintIconClick()" ><i class="mdi mdi-printer"></i> Imprimer</button>
  </div>
  <div>
    <button class="btn btn-success" (click)="this.getSavedEvents()" ><i class="mdi mdi-save"></i> Récuperer</button>
  </div>
  <div>
  <fieldset style="height: 50px;" class="float-right fieldsetInfo">
  <legend class="legendInfo">Infos</legend>
  
  <ul class="legende">
		<li class="info"><a href="#" class="color">Sous-effectif</a></li>
		<li class="info"><a href="#" class="color">Valide</a></li>
		<li class="info"><a href="#" class="color">Valide+Astreinte</a></li>
	</ul>
  

  
</fieldset>
</div>
</div>
  <button class="btn btn-primary float" (click)="getDispo()">Raffraichir <i class="mdi mdi-refresh"></i></button>
  
  <ejs-schedule #scheduleObj
  [currentView]="setView" 
  [views]="scheduleViews" 

  (eventRendered)="oneventRendered($event)"

  [workHours]="workHours"

  height="auto"
  width="auto"

  (renderCell)="onRenderCell($event)"

  [rowAutoHeight]="true"
  


  [group]="group"
  [timeScale]="timeScale"
  [selectedDate]="selectedDate"
  [firstDayOfWeek]='startWeek'

  [allowVirtualScrolling]="true"

  (popupOpen)='onPopupOpen($event)'
  (eventClick)='onEventClick($event)'

  >
   
  <e-views>
    <e-view option='TimelineWeek' interval="4" dateFormat='dd-MMM-yyyy'></e-view>
  </e-views>

  <e-resources>
  <e-resource 
            field="UserId" title="User" name="Users"
            [dataSource]="userDataSource | async" [allowMultiple]="allowMultipleCategory"
            textField="text" idField="id" colorField="color">
</e-resource>
        
</e-resources>


<ng-template #centerAutreDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Autres moyens</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
  <div class="modal-body">
    
    <div class="row" *ngFor="let rattachement of rattachements | async">
      <div class="col-md-12">
        <div class="form-group position-relative">
          <label for="validationTooltip03">{{rattachement.name}}</label>
          <div class="form-group row">
              <div class="row">
            
                
              <div *ngFor="let moyen of otherMoyens" class="moyenbutton">
              
                <input *ngIf="moyen.rattachement == rattachement.name" class="form-control" type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getMoyen($event)" >
                <label  *ngIf="moyen.rattachement == rattachement.name" for="{{moyen.name}}">{{moyen.name}}</label>
              
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

  </div>
  </ng-template>



<!-- <ng-template #resourceHeaderTemplate let-data>
            <div class='template-wrap'>
                <div class="room-name">{{data.userDataSource.text}}</div>
            </div>
</ng-template> -->

<ng-template #centerDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Affectation de {{start}}h à {{end}}h</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>

    <div class="modal-footer">
    <div class="col-lg-12">

    
                  <button (click)="affectUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary btn-block">Affecter</button>
    </div>
                </div>
    </ng-template>

    <ng-template #notcenterDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Retirer Affectation</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
    <button (click)="desaffectUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary col-md-12">Retirer</button>
               
    </div>
    </ng-template>


    <ng-template #dispocenterDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Ajouter une disponibilité</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Périodes</div>
          
              <div class="horizontal">
                <div *ngFor="let period of periods">
                    <input   type="checkbox" name="skill-intervention" value="{{period}}" class="{{period}}" id="{{period}}" (change)="getPeriod($event)">
                    <label  for="{{period}}">{{period}}</label>
                    </div>
              </div>
          </div>
      </div>
      </div>
    </div>
    <div class="modal-footer">
    <div class="col-lg-12">
    <button (click)="dispoAllUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-success float-left">Dispo 24h</button>
                  <button (click)="dispoUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary float-right">Ajouter</button>
                </div>
                </div>
    </ng-template>

    <ng-template #dateHeaderTemplate let-data>
        <div class="date-text" style="text-align: center; font-size: 13px; font-weight: bold; "
         >{{getDateHeaderText(data.date)}}</div>
    </ng-template>

    <ng-template #popContent let-data style="max-width: 100%;">


<div>
<span class="big border border-success" title="Équipiers"><i class="ri-group-fill"></i>{{getCountDispo(this.dateCount)}}</span>
</div> 
<div *ngFor="let name of this.namesOfDispo" style="font-weight: bolder; font-size: 18px;">{{name}}</div>
</ng-template>

  <ng-template #timeScaleMajorSlotTemplate let-data>
      <div style="color:white;" class="majorTime">{{getMajorTime(data.date)}}</div>
        <div style="vertical-align: middle; align-items: center; color:white;" popoverClass="popoverClass" [ngbPopover]="popContent" placement="bottom" container="body" (click)="getDate(data.date)">
          <i class='ri-user-fill' ></i>
          <i class='ri-information-line' ></i>
        </div>
    </ng-template>
    <ng-template #timeScaleMinorSlotTemplate let-data>
        <div class="minorTime">{{getMinorTime(data.date)}}</div>

    </ng-template>
  </ejs-schedule>

  <div>
  <button class="btn btn-success float-left" (click)="refreshCounter()">Raffraichir Compteurs <i class="mdi mdi-refresh"></i></button>
  
  <button class="btn btn-primary float-right" (click)="refresh()">Raffraichir <i class="mdi mdi-refresh"></i></button>
  </div>
`,
  styleUrls: ['./calendrierSNB1.component.scss'],
   styles: [`
     
    .e-toolbar-item .e-today .e-overflow-show{
  display: none !important; 
}

    .e-today{ 
        display: none !important;    
    } 

    .e-schedule .e-timeline-week-view .e-date-header-wrap table col, 
.e-schedule .e-timeline-week-view .e-content-wrap table col { 
  width: 10px !important; 
} 

   

 
   
   `],
})

export class CalendrierSNB1Component implements OnInit {

  public workHours: WorkHoursModel = { start: '0:00', end: '23:00' };

  nuitStart;
  nuitEnd;
  matinStart;
  matinEnd;
  amidiStart;
  amidiEnd;
  soirStart;
  soirEnd;

  endSelected;
  startSelected;
  moyenSelected:string;
  skillSelected:string;
  userSelected:string;
  dateSelected:string;
  eventIdSelected:number;
  periodSelected=[];
  rattachements:Observable<any[]>
  userSelectedToken:string;
  number;

  namesOfDispo:Array<string>;

  persoStart;
  persoEnd;

  dateCount:Date;



  selectedStartTime:Date;
  selectedEndTime:Date;
  selectedIndex:number;


  userSelect: User;

  start;
  end;
  subject;

  dataPrint = [];
  resourcePrint = [];
  tmpData = [];
  tmpResource = [];
  
  @ViewChild('scheduleObj')
    public scheduleObj: ScheduleComponent;

    @ViewChild('centerDataModal')
    public centerDataModal; 

    @ViewChild('notcenterDataModal')
    public notcenterDataModal; 

    @ViewChild('dispocenterDataModal')
    public dispocenterDataModal; 

    @ViewChild('centerAutreDataModal')
    public centerAutreDataModal; 



  moyens:Observable<any[]>;

  otherMoyens=[];

  skills = [];

  public userDataSource: Promise<any[]> = this.getUsers();

  getDateHeaderText: Function = (value: Date) => {
    return this.instance.formatDate(value, { skeleton: 'Ed' });
  };

  


  oneventRendered(args: EventRenderedArgs): void {
    let color: string = args.data.Color as string;
    let title: string = args.data.Subject as string;
    if (!args.element || !color) {
        return;
    }
    if (this.scheduleObj.currentView === 'TimelineMonth') {
        // args.element.style.borderRadius = "50%"
        // args.element.style.display = "table-cell !important"
        // args.element.style.height = "25px"
        // args.element.style.width = "25px"
        // this.scheduleObj.rowAutoHeight = true
        // args.element.style.backgroundColor = color
        // args.element.style.paddingTop = "0px"

        // switch (title) {
        //   case "Nuit":
        //     args.element.innerHTML = `<img class="png-icon" src="../../../../assets/images/icons/nuit.png" style="width: 15px; height: 15px;">`
            
        //     break;
        
        //   case "A-Midi":
        //     args.element.innerHTML = `<img class="png-icon" src="../../../../assets/images/icons/amidi.png" style="width: 15px; height: 15px;">`
        //     break;

        //   case "Soir":
        //     args.element.innerHTML = `<img class="png-icon" src="../../../../assets/images/icons/soir.png" style="width: 15px; height: 15px;">`
        //     break;

        //   case "Matin":
        //     args.element.innerHTML = `<img class="png-icon" src="../../../../assets/images/icons/matin.png" style="width: 15px; height: 15px;">`
        //     break;  

        //   case "Perso 1":
        //     args.element.innerHTML = `<img class="png-icon" src="../../../../assets/images/icons/perso.png" style="width: 15px; height: 15px;">`
        //     break;
            
        //   case "Perso 2":
        //     args.element.innerHTML = `<img class="png-icon" src="../../../../assets/images/icons/perso.png" style="width: 15px; height: 15px;">`
        //     break;
        
        //   default:
        //     console.log("default");
        // }
        

        // (args.element.firstChild as HTMLElement).style.borderLeftColor = color;

  } if(title == "Astreinte SNB2"){
    args.element.style.display = "none";
  } if( title == "Astreinte SNB1"){
    args.element.style.backgroundColor = color;
  }
  if( title == "A" || title =="G"){
    args.element.style.backgroundColor = color;
  }
  
  else {
      args.element.style.backgroundColor = color;
      args.element.style.opacity = "0.3";
  }
  }

  @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
    if(event.key=="g" || event.key=="G"){
      if(this.userSelected){
        let dispo = new Dispo();

        if(this.startSelected.getHours() == 0){

          dispo.StartTime = this.startSelected
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 7);
          
        }
        if(this.startSelected.getHours() == 6){
          
          dispo.StartTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 7);
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 13);
          
        }
        if(this.startSelected.getHours() == 12){

          dispo.StartTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 13);
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 19);
          
          
        }
        if(this.startSelected.getHours() == 18){

          dispo.StartTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 19);
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 23,59);
         
          
        }

            
            

          
            
            dispo.Id = Math.floor((Math.random()*9999)+1);
    
            dispo.Subject = "G";
            dispo.PeriodId = 2;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = this.userSelected;
            dispo.Color = "#1992A9";
            dispo.simpleStart = this.amidiStart.toString();
            dispo.simpleEnd = this.amidiEnd.toString();
            this.scheduleObj.addEvent(dispo);
          
      }
    }

    if(event.key=="a" || event.key=="A"){
      if(this.userSelected){
        let dispo = new Dispo();

        if(this.startSelected.getHours() == 0){

          dispo.StartTime = this.startSelected
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 7);
          
        }
        if(this.startSelected.getHours() == 6){
          
          dispo.StartTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 7);
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 13);
          
        }
        if(this.startSelected.getHours() == 12){

          dispo.StartTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 13);
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 19);
          
          
        }
        if(this.startSelected.getHours() == 18){

          dispo.StartTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 19);
  
          dispo.EndTime = new Date(this.startSelected.getFullYear(), this.startSelected.getMonth(), this.startSelected.getDate(), 23,59);
         
          
        }

   

          
            
            dispo.Id = Math.floor((Math.random()*9999)+1);
    
            dispo.Subject = "A";
            dispo.PeriodId = 2;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = this.userSelected;
            dispo.Color = "#EC2440";
            dispo.simpleStart = this.amidiStart.toString();
            dispo.simpleEnd = this.amidiEnd.toString();
            this.scheduleObj.addEvent(dispo);
      }
      
  }
  if(event.key == 'Backspace'){
    this.scheduleObj.deleteEvent(this.eventIdSelected);
    console.log("delete");
    
  }
  if(event.key == 'Enter'){
    Swal.fire({
      title: 'Voulez-vous sauvegarder le planning?',
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText:'Retour',
      confirmButtonColor:'green',
      cancelButtonColor:'red',
      denyButtonColor:'blue',
      confirmButtonText: 'Sauvegarde Définitive',
      denyButtonText: `Sauvegarde Rapide`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.saveEvents()
       
      } else if (result.isDenied) {
        this.quickSaveEvents().then((res)=> {Swal.fire('Sauvegarde Rapide Éffectuée', '', 'success')})
        
      }
    })
    
    
  }
    console.log(event.key);
    
}


onRenderCell(args: RenderCellEventArgs): void {



  setTimeout(() => {
    if (args.element.classList.contains("e-time-slots")) {

      if(this.getCountDispo(args.date)==2){
        (args.element as HTMLElement).style.backgroundColor = "green";
      } else if(this.getCountDispo(args.date)==3){
        (args.element as HTMLElement).style.backgroundColor = "blue";
      } else {
        (args.element as HTMLElement).style.backgroundColor = "red";
      }
      
      
  
      

}  
  }, 1500);
  


  


}

refreshCounter(){
  this.scheduleObj.refreshTemplates('TimelineMonth');
}

otherCenter(centerDataModal: any) {
  this.modalService.open(centerDataModal, { centered: true });
}

getMoyen(event){
  if(event.target.checked){
    this.moyenSelected = event.target.value;
  }
}

getDate(date){
  this.dateCount = date;
  console.log(this.dateCount);
}


getSkill(event){
  if(event.target.checked){
    this.skillSelected = event.target.value;
  }
}

getPeriod(event){
  if(event.target.checked){
    this.periodSelected.push(event.target.value);
  }
  if(!event.target.checked){
    this.periodSelected.forEach((period,i)=>{
      if(period==event.target.value){
        this.periodSelected.splice(i,1)
      }
    })
  }
  console.log(this.periodSelected);
  
}

getStart(event){
    this.persoStart = event.target.value;
    console.log(this.persoStart);
    
}

getEnd(event){
    this.persoEnd = event.target.value;
    console.log(this.persoEnd)
}


getMonthNames(index: number): string {

  
  
  length = this.monthNames.length
  if(index>length - 1){
    return "January"
  } else {
    return this.monthNames[index]
  }

}

async saveEvents(){

 


  let savedEvents: Dispo[] = this.scheduleObj.getCurrentViewEvents(); 
  let i = 0;
  // savedEvents.forEach((element) => {
  //   let event = element as Dispo
  //   if(!(event.Subject == "A" || event.Subject == "G")){
  //     savedEvents.splice(i,1);
  //     console.log("delete");
  //   }
  //   i++
  // })
  let filteredEvents = savedEvents.filter(element => 
    element.Subject.length < 2
  );

  this.spinner.show();

  let tokensUsers:String[] = [];

  await this.db.collection('users',ref => ref.where("skills","array-contains","SNB1")).get().forEach(async user =>{
    

    await user.docs.forEach(async user => {
      

      

      if(user.data()["uid"] != null){


    


          


        


      await this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
    .subscribe(async (calendar) => {
  
      if(calendar.data().dayAffected != null){
        let objAffect = calendar.data().dayAffected;
        // for (const element of objAffect) {
          
          
          
        //     let start = new Date(element["start"].seconds*1000);
        //     let title = element["moyen"];
  
            
            
        //     if(start.toString() == this.selectedStartTime.toString() && title == "Astreinte SNB1"){
            
  
        //       this.spinner.hide();
  
        //       Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: "Personnel Indisponible (SNB1)",
        //       })
  
        //       return;
        //     }
            
         
        // } 

  
      filteredEvents.forEach(async affect => {
        if(affect.UserId == user.data()["uid"]){
          await this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.getMonthNames(affect.StartTime.getMonth())).update({
            dayAffected : firestore.FieldValue.arrayUnion({moyen: "Astreinte SNB2" ,start: affect.StartTime, end : affect.EndTime, skill: "SNB2", number: affect.number.toString()})
          });
        }
      });

      filteredEvents.forEach(async affect => {
        if(affect.UserId == user.data()["uid"]){
          await this.db.collection("users").doc(user.data()["uid"]).collection("affectations").doc(affect.number.toString()).set({
            end : affect.EndTime,
            start : affect.StartTime,
            moyen : "Astreinte SNB2",
            skill : "SNB2",
            number: affect.number.toString()
          })
        }
      });




   



    
}

    });
  }
});

  }).then(async () =>     { console.log("ADD SUCCESS");



  //   this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).get()
  // .subscribe(calendar => { 


  //   let objDispo = JSON.parse(calendar.data().allDays);

    
  //   for (let key in objDispo) {

    

  //     if(key == this.datePipe.transform(this.selectedStartTime,"yyyy-MM-dd")+" 00:00:00.000"){
  //       objDispo[key].splice(this.selectedIndex,1);
  //     }

    

  //   }

  //   let newObj = JSON.stringify(objDispo);


    
  // this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).update({
  //   allDays : newObj
  // })

// })




await this.db.collection('users',ref => ref.where("skills","array-contains","SNB1")).get().forEach(async user =>{
    

  await user.docs.forEach(async user => {


    filteredEvents.forEach(async affect => {
      if(affect.UserId == user.data()["uid"]){

        
          if(!tokensUsers.includes(user.data()["bipToken"])){
            tokensUsers.push(user.data()["bipToken"]);
          }
          
   
      }
    });    

  })
});



console.log(tokensUsers);




// await this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userSelected).set({
//   uid : this.userSelected,
//   token: this.userSelectedToken,
//   skill : this.skillSelected,
// }).then(() =>     {   // console.log("ADD SUCCESS");
let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
let notification = {
"notification": {
  "title": "🚨 AFFECTATION 🚨",
  "body": "Vous êtes affecté en tant que SNB2",
  "click_action": "FLUTTER_NOTIFICATION_CLICK",
  "sound": "affect.wav",
    "android_channel_id": "info"
},
"android": {
  "ttl": 3600 * 1000,
  "notification": {
    color: '#ff0000',
    sound: 'affect',
    channel_id: 'info' // important to get custom sound
  }
},  "data": {
  "moyen":"Astreinte SNB2",
},
"registration_ids": tokensUsers,
"priority": "high"
}
let url = 'https://fcm.googleapis.com/fcm/send';
this.http.post(url, notification, { headers: myheaders })
.subscribe(results => {
// window.location.reload();
// this.getDispo().then(()=>{this.spinner.hide;
// this.scheduleObj.refresh()});
this.spinner.hide();
Swal.fire('Sauvegarde et Notifications Éffectuées', '', 'success');
}, error => {
console.log(error);
});;
}
);;





  // localStorage.setItem("event_SNB2", JSON.stringify(filteredEvents));
  // console.log(filteredEvents); 
  
}


  async quickSaveEvents(){

 


  let savedEvents: Dispo[] = this.scheduleObj.getCurrentViewEvents(); 
  let i = 0;
  // savedEvents.forEach((element) => {
  //   let event = element as Dispo
  //   if(!(event.Subject == "A" || event.Subject == "G")){
  //     savedEvents.splice(i,1);
  //     console.log("delete");
  //   }
  //   i++
  // })
  let filteredEvents = savedEvents.filter(element => 
    element.Subject.length < 2
  );
  // for(let element of savedEvents){
  //   let event = element as Dispo
  //   if(!(event.Subject == "A" || event.Subject == "G")){
  //     savedEvents.splice(i,1);
  //     console.log("delete");
  //   }
  //   i++
  // }
  localStorage.setItem("event_SNB1", JSON.stringify(filteredEvents));
  console.log(filteredEvents); 
  
}

// filterEvents(index, events){
//   events.splice(index,1);
  
// }

getSavedEvents(){

 
  let eventSaved = JSON.parse(localStorage.getItem("event_SNB1"));
  this.scheduleObj.addEvent(eventSaved)

  console.log(eventSaved); 
  
}

refresh(){
  this.spinner.show();
  this.getDispo().then((res)=>{
    this.scheduleObj.refreshTemplates("TimelineMonth");
    this.spinner.hide();
  })
  
}





  // async onEventClick(events) {
  //   this.otherMoyens = [];
  //   console.log(events);
  //   if(!events["event"]["moyenAffected"]){
  //   this.skills = [];
  //   await this.db.collection("users").doc(events["event"]["UserId"]).get().subscribe((value) => {
  //     this.skills = value.data()["skills"];
  //     // console.log(value.data()["skills"]);
  //   })
  //   this.moyens = this.db.collection('moyens',ref => ref.where("rattachement","==","SNSM Propriano")).valueChanges();
  //   this.rattachements = this.db.collection('rattachements').valueChanges();
  //   this.db.collection('moyens').get().subscribe((doc)=>{
  //     doc.docs.forEach((moyen)=>{
  //       if(moyen.data()['rattachement']!="SNSM Propriano"){
  //         this.otherMoyens.push(moyen.data());
  //       }
  //     })
  //   });
  //   this.start = events["event"]["simpleStart"];
  //   this.end = events["event"]["simpleEnd"];
  //   this.subject =  events["event"]["Subject"];
  //   this.number =events["event"]["number"];
  //   this.userSelected = events["event"]["UserId"];
  //   this.userSelectedToken = events["event"]["UserToken"];
  //   this.selectedStartTime = new Date(events["event"]["StartTime"]);
  //   this.selectedEndTime = new Date(events["event"]["EndTime"]);
  //   this.selectedIndex = events["event"]["eventIndex"];
  //   // console.log(this.skills);
  //   // console.log(events["event"]);
  //   this.modalService.open(this.centerDataModal, { centered: true , size: 'lg'});
  // }else{
  //   this.otherMoyens = [];
  //   this.skills = [];
  //   this.moyenSelected = events["event"]["moyenAffected"];
  //   await this.db.collection("users").doc(events["event"]["UserId"]).get().subscribe((value) => {
  //     this.skills = value.data()["skills"];
  //     // console.log(value.data()["skills"]);
  //   })
  //   this.moyens = this.db.collection('moyens').valueChanges();
  //   this.rattachements = this.db.collection('rattachements').valueChanges();
  //   this.db.collection('moyens').get().subscribe((doc)=>{
  //     doc.docs.forEach((moyen)=>{
  //       if(moyen.data()['rattachement']!="SNSM Propriano"){
  //         this.otherMoyens.push(moyen.data());
  //       }
  //     })
  //   });
  //   this.start = events["event"]["simpleStart"];
  //   this.end = events["event"]["simpleEnd"];
  //   this.subject =  events["event"]["Subject"];
  //   this.number =events["event"]["number"];
  //   this.userSelected = events["event"]["UserId"];
  //   this.userSelectedToken = events["event"]["UserToken"];
  //   this.selectedStartTime = new Date(events["event"]["StartTime"]);
  //   this.selectedEndTime = new Date(events["event"]["EndTime"]);
  //   this.selectedIndex = events["event"]["eventIndex"];
  //   // console.log(this.skills);
  //   // console.log(events["event"]);
  //   this.modalService.open(this.notcenterDataModal, { centered: true , size: 'lg'});
  // }
  // }

  





  async onEventClick(events) {
    this.otherMoyens = [];
    console.log(events);
    if(!events["event"]["moyenAffected"]){
    this.skills = [];
    await this.db.collection("users").doc(events["event"]["UserId"]).get().subscribe((value) => {
      this.skills = value.data()["skills"];
      // console.log(value.data()["skills"]);
    })
    this.moyens = this.db.collection('moyens',ref => ref.where("rattachement","==","SNSM Propriano")).valueChanges();
    this.rattachements = this.db.collection('rattachements').valueChanges();
    this.db.collection('moyens').get().subscribe((doc)=>{
      doc.docs.forEach((moyen)=>{
        if(moyen.data()['rattachement']!="SNSM Propriano"){
          this.otherMoyens.push(moyen.data());
        }
      })
    });
    this.start = events["event"]["simpleStart"];
    this.end = events["event"]["simpleEnd"];
    this.subject =  events["event"]["Subject"];
    this.number =events["event"]["number"];
    this.userSelected = events["event"]["UserId"];
    this.userSelectedToken = events["event"]["UserToken"];
    this.selectedStartTime = new Date(events["event"]["StartTime"]);
    this.selectedEndTime = new Date(events["event"]["EndTime"]);
    this.selectedIndex = events["event"]["eventIndex"];
    // console.log(this.skills);
    // console.log(events["event"]);
    this.modalService.open(this.centerDataModal, { centered: true , size: 'lg'});
  }else{
    this.otherMoyens = [];
    this.skills = [];
    this.moyenSelected = events["event"]["moyenAffected"];
    await this.db.collection("users").doc(events["event"]["UserId"]).get().subscribe((value) => {
      this.skills = value.data()["skills"];
      // console.log(value.data()["skills"]);
    })
    this.moyens = this.db.collection('moyens').valueChanges();
    this.rattachements = this.db.collection('rattachements').valueChanges();
    this.db.collection('moyens').get().subscribe((doc)=>{
      doc.docs.forEach((moyen)=>{
        if(moyen.data()['rattachement']!="SNSM Propriano"){
          this.otherMoyens.push(moyen.data());
        }
      })
    });
    this.start = events["event"]["simpleStart"];
    this.end = events["event"]["simpleEnd"];
    this.subject =  events["event"]["Subject"];
    this.number =events["event"]["number"];
    this.userSelected = events["event"]["UserId"];
    this.userSelectedToken = events["event"]["UserToken"];
    this.selectedStartTime = new Date(events["event"]["StartTime"]);
    this.selectedEndTime = new Date(events["event"]["EndTime"]);
    this.selectedIndex = events["event"]["eventIndex"];
    // console.log(this.skills);
    // console.log(events["event"]);
    this.modalService.open(this.notcenterDataModal, { centered: true , size: 'lg'});
  }
  }

  


  public group: GroupModel = {
    resources: ['Users']
};

  public allowMultipleCategory: Boolean = true;

  public periodDataSource: Object[] = [
    { text: 'Matin', id: 1, color: '#df5286' },
    { text: 'A-Midi', id: 2, color: '#7fa900' },
    { text: 'Soir', id: 3, color: '#7fa900' },
    { text: 'Nuit', id: 4, color: '#7fa900' }
];

  public setView: View = "TimelineWeek";
  public setDate: Date = new Date(Date.now());

async onPopupOpen(args: PopupOpenEventArgs) {

  console.log(args.data);


  console.log(args.data["StartTime"]);

  console.log(this.datePipe.transform(args.data["StartTime"],"yyyy-MM-dd")+" 00:00:00.000");
    
    if ( args.type === 'QuickInfo')  {
        args.cancel = true;
        this.userSelected = args.data["UserId"];
        this.dateSelected = this.datePipe.transform(args.data["StartTime"],"yyyy-MM-dd")+" 00:00:00.000";
        this.startSelected = args.data["StartTime"];
        this.endSelected = args.data["EndTime"];
        this.eventIdSelected = args.data["Id"];
      
    }
    if (args.type === 'Editor') {
      this.eventIdSelected = args.data["Id"];
      // this.userSelected = args.data["UserId"];
      // this.dateSelected = this.datePipe.transform(args.data["StartTime"],"yyyy-MM-dd")+" 00:00:00.000";
      // this.modalService.open(this.dispocenterDataModal, { centered: true , size: 'lg'});
      args.cancel = true;
    
      
    }
  
}


  public users: Observable<any[]>;

  public monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
    ];

  public periods = ["Nuit", "Matin", "A-Midi", "Soir"];

  


  
  public startWeek: number = new Date().getDay();



//  public eventSettings: EventSettingsModel = {
//     dataSource: [
//     {
//         Id: 1,
//         Subject: 'Explosion of Betelgeuse Star',
//         StartTime: new Date(2021, 3, 15, 1, 0),
//         EndTime: new Date(2021, 3, 15, 5, 0)
//     }, {
//         Id: 2,
//         Subject: 'Thule Air Crash Report',
//         StartTime: new Date(2021, 3, 14, 7, 0),
//         EndTime: new Date(2021, 3, 14, 11, 0)
//     }, {
//         Id: 3,
//         Subject: 'Blue Moon Eclipse',
//         StartTime: new Date(2021, 3, 13, 13, 0),
//         EndTime: new Date(2021, 3, 13, 17, 0)
//     }, {
//         Id: 4,
//         Subject: 'Meteor Showers in 2021',       
//         StartTime: new Date(2021, 3, 14, 19, 0),
//         EndTime: new Date(2021, 3, 14,23, 0)
//     }]
// };


  items: any;
  public test: Array<Dispo> = [];
  
  public timeScale: TimeScaleModel = {
    majorSlotTemplate: '#majorSlotTemplate',
    minorSlotTemplate: '#minorSlotTemplate',
    enable: true,
    interval: 360,
    slotCount: 1,
  };
  public scheduleViews: View[] = ["TimelineWeek"];
  public data;
  public selectedDate = new Date();

  public instance: Internationalization = new Internationalization();


  getMajorTime(date: Date): string {
    if(date.getHours() == 0){
      return "Nuit"
    }
    if(date.getHours() == 6){
      return "Matin"
    }
    if(date.getHours() == 12){
      return "A-Midi"
    }
    if(date.getHours() == 18){
      return "Soir"
    }
    
  }
  getMinorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'ms' }).replace(':00', '');
  }

  
  getCountDispo(date: Date){
    
    
    var countUser = 0;
    this.namesOfDispo = [];
    this.scheduleObj.eventsData.forEach((dispo: Dispo) => {



      if(date.getHours() == 0){

        if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
            if(dispo.StartTime.getHours()<7 && dispo.StartTime.getHours()>=0){
              countUser++;
              this.namesOfDispo.push(dispo.FullName);
            }
          }
        } else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd HH',"en")){
          if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          countUser++;
          this.namesOfDispo.push(dispo.FullName);
          }
          
        }
      }
      if(date.getHours() == 6){
        
        if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
            if(dispo.StartTime.getHours()<13 && dispo.EndTime.getHours()>7){
              if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
              countUser++;
              this.namesOfDispo.push(dispo.FullName);
              }
              
            }
          }  
        }else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd 07',"en")){
          if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          countUser++;
          this.namesOfDispo.push(dispo.FullName);
          }
          
        }
      }
      if(date.getHours() == 12){
        
        if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
            if(dispo.StartTime.getHours()<19 && dispo.EndTime.getHours()>13){
              if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
              countUser++;
              this.namesOfDispo.push(dispo.FullName);
              }
            }
          }  
        }else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd 13',"en")){
          if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          countUser++;
          this.namesOfDispo.push(dispo.FullName);
          }
        }
      }
      if(date.getHours() == 18){
       
        if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
            if(dispo.StartTime.getHours()<24 && dispo.EndTime.getHours()==0){
              if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
              countUser++;
              this.namesOfDispo.push(dispo.FullName);
              }
            }
          }  
        } else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd 19',"en")){
          if(dispo.Subject === "Garde SNB1" || dispo.Subject === "Astreinte SNB1" || dispo.Subject === "G" || dispo.Subject === "A"){
          countUser++;
          this.namesOfDispo.push(dispo.FullName);
          }
        }
      }


      
        

        
        
    })
    return countUser
  }

  // getCountDispoSkill(date: Date, skill: string){
    
  //   var countUser = 0;
  //   this.scheduleObj.eventsData.forEach((dispo: Dispo) => {



  //     if(date.getHours() == 0){
        
  //       if(dispo.Subject.includes("Perso")){
  //         if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
  //           if(dispo.StartTime.getHours()<7 && dispo.StartTime.getHours()>=0){
              
  //             this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //               if(user.data()["skills"].includes(skill)){
  //                 countUser++;
  //               }
  //             })
  //           }
  //         }
  //       } else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd HH',"en")){
  //         this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //           if(user.data()["skills"].includes(skill)){
  //             countUser++;
  //           }
  //         })
  //       }
  //     }
  //     if(date.getHours() == 6){
        
  //       if(dispo.Subject.includes("Perso")){
  //         if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
  //           if(dispo.StartTime.getHours()<13 && dispo.EndTime.getHours()>7){
  //             this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //               if(user.data()["skills"].includes(skill)){
  //                 countUser++;
  //               }
  //             })
  //           }
  //         }  
  //       }else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd 07',"en")){
  //         this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //           if(user.data()["skills"].includes(skill)){
  //             countUser++;
  //           }
  //         })
  //       }
  //     }
  //     if(date.getHours() == 12){
        
  //       if(dispo.Subject.includes("Perso")){
  //         if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
  //           if(dispo.StartTime.getHours()<19 && dispo.EndTime.getHours()>13){
  //             this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //               if(user.data()["skills"].includes(skill)){
  //                 countUser++;
  //               }
  //             })
  //           }
  //         }  
  //       }else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd 13',"en")){
  //         this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //           if(user.data()["skills"].includes(skill)){
  //             countUser++;
  //           }
  //         })
  //       }
  //     }
  //     if(date.getHours() == 18){
       
  //       if(dispo.Subject.includes("Perso")){
  //         if(formatDate(dispo.StartTime, 'yyyy-MM-dd',"en")==formatDate(date, 'yyyy-MM-dd',"en")){
  //           if(dispo.StartTime.getHours()<24 && dispo.EndTime.getHours()>19){
  //             this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //               if(user.data()["skills"].includes(skill)){
  //                 countUser++;
  //               }
  //             })
  //           }
  //         }  
  //       } else if(formatDate(dispo.StartTime, 'yyyy-MM-dd HH',"en")==formatDate(date, 'yyyy-MM-dd 19',"en")){
  //         this.db.collection("users").doc(dispo.UserId).get().subscribe((user)=>{
  //           if(user.data()["skills"].includes(skill)){
  //             countUser++;
  //           }
  //         })
  //       }
  //     }

        

        
        
  //   })
  //   return countUser
  // }


  constructor(private db: AngularFirestore,
    public firebaseStorage : AngularFireStorage,
    private modalService: NgbModal,
    private http: HttpClient,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public uploadService : UploadFileService) {


      this.moyens = this.db.collection('moyens').valueChanges();
      this.rattachements = this.db.collection('rattachements').valueChanges();

      this.db.collection('moyens').get().subscribe((doc)=>{
        doc.docs.forEach((moyen)=>{
          if(moyen.data()['rattachement']!="SNSM Propriano"){
            this.otherMoyens.push(moyen.data());
          }
        })
      });
    

    loadCldr(
      require('../../../../../node_modules/cldr-data/supplemental/numberingSystems.json'),
      require('../../../../../node_modules/cldr-data/main/fr/ca-gregorian.json'),
      require('../../../../../node_modules/cldr-data/main/fr/currencies.json'),
      require('../../../../../node_modules/cldr-data/main/fr/numbers.json'),
      require('../../../../../node_modules/cldr-data/main/fr/timeZoneNames.json')
    );
    
    L10n.load({
      'fr': {
          'schedule': {
             "day": "journée",
            "week": "La semaine",
            "workWeek": "Semaine de travail",
            "month": "Mois",
            "agenda": "Ordre du jour",
            "weekAgenda": "Agenda de la semaine",
            "workWeekAgenda": "Agenda de la semaine de travail",
            "monthAgenda": "Agenda du mois",
            "today": "Aujourd'hui",
            "noEvents": "Pas d'événements",
            "emptyContainer": "Aucun événement n'est prévu ce jour-là.",
            "allDay": "Toute la journée",
            "start": "Début",
            "end": "Fin",
            "more": "plus",
            "close": "Fermer",
            "cancel": "Annuler",
            "noTitle": "(Pas de titre)",
            "delete": "Effacer",
            "deleteEvent": "Supprimer un événement",
            "deleteMultipleEvent": "Supprimer plusieurs événements",
            "selectedItems": "Articles sélectionnés",
            "deleteSeries": "Supprimer la série",
            "edit": "modifier",
            "editSeries": "Modifier la série",
            "editEvent": "Modifier l'événement",
            "createEvent": "Créer",
            "subject": "Assujettir",
            "addTitle": "Ajouter un titre",
            "moreDetails": "Plus de détails",
            "save": "sauvegarder",
            "editContent": "Voulez-vous modifier uniquement cet événement ou une série entière?",
            "deleteRecurrenceContent": "Voulez-vous supprimer uniquement cet événement ou une série entière?",
            "deleteContent": "Êtes-vous sûr de vouloir supprimer cet événement?",
            "deleteMultipleContent": "Êtes-vous sûr de vouloir supprimer les événements sélectionnés?",
            "newEvent": "Nouvel évènement",
            "title": "Titre",
            "location": "Emplacement",
            "description": "La description",
            "timezone": "Fuseau horaire",
            "startTimezone": "Début du fuseau horaire",
            "endTimezone": "Fin du fuseau horaire",
            "repeat": "Répéter",
            "saveButton": "sauvegarder",
            "cancelButton": "Annuler",
            "deleteButton": "Effacer",
            "recurrence": "Récurrence",
            "wrongPattern": "Le modèle de récurrence n'est pas valide.",
            "seriesChangeAlert": "Les modifications apportées à des instances spécifiques de cette série seront annulées et ces événements correspondront à nouveau à la série.",
            "createError": "La durée de l'événement doit être plus courte que sa fréquence. Raccourcissez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événement de récurrence.",
            "recurrenceDateValidation": "Certains mois ont moins que la date sélectionnée. Pour ces mois, l'événement se produira à la dernière date du mois.",
            "sameDayAlert": "Deux occurrences du même événement ne peuvent pas se produire le même jour.",
            "editRecurrence": "Modifier la récurrence",
            "repeats": "Répète",
            "alert": "Alerte",
            "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
            "invalidDateError": "La valeur de date saisie est invalide.",
            "ok": "D'accord",
            "occurrence": "Occurrence",
            "series": "Séries",
            "previous": "précédent",
            "next": "Prochain",
            "timelineDay": "Journée chronologique",
            "timelineWeek": "Semaine chronologique",
            "timelineWorkWeek": "Semaine de travail chronologique",
            "timelineMonth": "Mois de la chronologie"
        },
        "recurrenceeditor": {
            "none": "Aucun",
            "daily": "du quotidien",
            "weekly": "Hebdomadaire",
            "monthly": "Mensuel",
            "month": "Mois",
            "yearly": "Annuel",
            "never": "Jamais",
            "until": "Jusqu'à",
            "count": "Compter",
            "first": "Premier",
            "second": "Seconde",
            "third": "Troisième",
            "fourth": "Quatrième",
            "last": "Dernier",
            "repeat": "Répéter",
            "repeatEvery": "Répéter tous les",
            "on": "Répéter sur",
            "end": "Fin",
            "onDay": "journée",
            "days": "Journées)",
            "weeks": "Semaines)",
            "months": "Mois)",
            "years": "Années)",
            "every": "chaque",
            "summaryTimes": "fois)",
            "summaryOn": "sur",
            "summaryUntil": "jusqu'à",
            "summaryRepeat": "Répète",
            "summaryDay": "journées)",
            "summaryWeek": "semaines)",
            "summaryMonth": "mois)",
            "summaryYear": "années)",
            "monthWeek": "Mois Semaine",
            "monthPosition": "Position du mois",
            "monthExpander": "Mois Expander",
            "yearExpander": "Année Expander",
            "repeatInterval": "Intervalle de répétition"
    
        }
      }
    });

  

  }

  async getUsers() {

    var tab = [];

   
    await this.db.collection('users',ref => ref.where("skills","array-contains","SNB1")).get().forEach((doc) =>  {

      doc.docs.forEach((user) => {
     
        
          this.monthNames.forEach((month, index)=>{

            

            user.ref.collection('Calendar').doc(month).get().then((doc) => {
              if(!doc.exists){

                var _events = new Map<Date,[]>();

                for (var i = 1;
                  i <= 31;  
                  i++) {
                 
                      _events[formatDate(new Date(new Date().getFullYear(),index,i), 'yyyy-MM-dd 00:00:00.000',"en")] = [];
  
                  }
                
                  user.ref.collection('Calendar').doc(month).set({
                    Month: month,
                    allDays: JSON.stringify(_events)
                  })
                
              }
            })

            
          })
          
      })


      doc.docs.forEach((user) => {
        if(user.data()["skills"]!=null){
          if(user.data()["skills"].includes("Infirmier")){
            tab.push({text: "💉⛑"+user.data()["lastname"]+" "+user.data()["firstname"], id: user.data()["uid"]});
          }else{
          tab.push({text: user.data()["lastname"]+" "+user.data()["firstname"], id: user.data()["uid"]});
          }
        } else {
        tab.push({text: user.data()["lastname"]+" "+user.data()["firstname"], id: user.data()["uid"]});
        }
      })
      // console.log(tab);
      
    });


    return tab;

  };

  ngOnInit() {

    this.users = this.db.collection<User>('users',ref => ref.where("skills","array-contains","SNB1"))
                  .valueChanges()
                  .pipe(map(collection => {
                        return collection.map(b =>  new User(b));
                    }));

    // console.log(this.firebaseStorage.ref('moyens/QUAD.jpg').getDownloadURL())


    this.db.collection("commons").doc("variables").get().subscribe((value) => {
  
      this.nuitStart = value.data()["nuitStart"],
      this.nuitEnd = value.data()["nuitEnd"],
      this.matinStart = value.data()["matinStart"],
      this.matinEnd = value.data()["matinEnd"],
      this.amidiStart =  value.data()["amidiStart"],
      this.amidiEnd =  value.data()["amidiEnd"],
      this.soirStart =  value.data()["soirStart"],
      this.soirEnd =  value.data()["soirEnd"]
    

    }
    );

//     this.db.collection("commons").doc("adresse").update({

//       coordonnees : {

//         "PLAGE DU LIDO" : [41.675904,8.896433],
// "PLAGE DE PHARE DE SCOGLIU LONGU" : [41.677868,8.897196],
// "PLAGE DE PURAGHJA" : [41.673973,8.892570],
// "PLAGE DE MANCINU" : [41.678589,8.910360],
// "PLAGE DU SAMPIERO CORSO" : [41.679951,8.914052],
// "PLAGE DE LA RESIDENCE DE LA PLAGE" : [41.677106,8.908888],
// "PLAGE DU VALINCO – BASE DE JET SKI" : [41.677106,8.907599],
// "PLAGE DE L’HOTEL ROC E MARE" : [41.680672,8.914987],
// "PLAGES DES CRIQUES DE CAPU LAUROSU" : [41.671144,8.886443],
// "PLAGE DE L’HOTEL BELLAMBRA" : [41.672028,8.890587],
// "PLAGE DE CAPU LAUROSO" : [41.666418,8.881358],
// "PLAGE DE L’EMBOUCHURE DU RIZZANESE" :  [41.662795,8.880327],
// "PLAGE AUX VACHES" : [41.654940,8.878567],
// "PLAGE DU ROBINSON" : [41.647763,8.874083],
// "PLAGE DE PORTIGLIOLLO" : [41.646167,8.871109],
// "PORT DE COMMERCE"  : [41.676433,8.898473],
// "PORT DE PLAISANCE" : [41.676273,8.902473],
// "CAPITAINERIE DU PORT" : [41.676401,8.903654],
// "PROMENADE DU PORT DE PLAISANCE" : [41.675911,8.901253 ],
// "STATION ESSENCE TOTAL DU PORT DE PLAISANCE" : [41.677369,8.906061],
// "MISE A L’EAU DU PORT DE PLAISANCE" : [41.677121,8.906172],
// "DIGUE DU PORT DE PLAISANCE" : [41.677413,8.902496],
// "DIGUE DU PORT DE COMMERCE" : [41.678211,8.898436],
// "ILOT DE L’ANCURELLA" : [41.662195,8.873551],
// "AIRE DE CARRENAGE DU PORT DE PLAISANCE" : [41.677125,8.906552],
// "GOLFE DU VALINCO" : [41.678223,8.839626],
// "PLAGE DE BARACCI" : [41.686419,8.916551],
// "PLAGE DE L’EMBOUCHURE DU BARACCI" : [41.690938,8.914146],
// "PLAGE DE CHEZ ZEDDA" : [41.691098,8.910069],
// "PLAGE DE SCOGLI ROSSI" : [41.691128,8.906486],
// "PLAGE DE VETRICELLA" : [41.689846,8.897522],
// "PLAGE DE L’HOTEL MARINCA" : [41.690407,8.902449],
// "PLAGE DE LA CALANCA" : [41.690071,8.892272],
// "PLAGE DE CAMPITELLO" : [41.691188,8.885613],
// "PLAGE D’AGLIO" : [41.695963,8.877625],
// "PLAGE DE CAPPICCIOLO (OU DU RUESCO)" : [41.695674,8.871087],
// "PLAGE DI U VANGONU" : [41.696139,8.867246],
// "POINTE DE SCOGLI NERI" : [41.695834,8.860740],
// "PLAGE DE CALA PISCONA" : [41.697036,8.858663],
// "PLAGE DU CENTRE DE VACANCE SCODI NERI" : [41.696764,8.849237],
// "PLAGE D’ABBARTELLO" : [41.698494,8.840627],
// "PLAGE DE TANUTELLA (OU DE L’HOTEL RAL BOL)" :[41.703866,8.833143],
// "POINTE DE TARAVO OU ABBARTELLO" : [41.699924,8.836802],
// "EMBOUCHURE DU FLEUVE TARAVO" : [41.712220,8.819471],
// "PLAGE DU TARAVO" : [41.714158,8.813820],
// "PLAGE DU CAMPING CHEZ ALFONSI – PORTO POLLO" : [41.710417,8.805371],
// "PORTICCIU – PORTO POLLO" : [41.710369,8.806982],
// "PLAGE DE PORTO POLLO" : [41.709440,8.795012],
// "PLAGE DU SPAR – PORTO POLLO" : [41.710772,8.797869],
// "PORT DE PLAISANCE – PORTO POLLO" : [41.707368,8.793802],
// "MISE A L’EAU DU PORT DE PLAISANCE– PORTO POLLO" : [41.707092,8.794688],
// "ROCHER DU TARAVO – PORTO POLLO" : [41.696194,8.796040],
// "POINTE DE L’OGA / POINTE DE PORTO POLLO" : [41.699045,8.782985],
// "POINTE DE PRATARELLA (BAIE DE CUPABIA)" : [41.711669,8.771568],
// "POINTE DE CAPANELLA (BAIE DE CUPABIA)" : [41.721343,8.773371],
// "CALA DI FARRU (BAIE DE CUPABIA)" : [41.724867,8.780285],
// "PLAGE DE CUPABIA" : [41.734340,8.784707],
// "BAIE DE CUPABIA" : [41.728756,8.762476],
// "VILLAGE DE PORTO POLLO" : [41.709788,8.794769],
// "LIEU DIT LA PUNTA – PORTO POLLO" : [41.702355,8.790500],
// "BAIE DE PORTO POLLO" : [41.704858,8.801949],
// "PLAGE DE CUPABIA (COTI-CHIAVARI)" : [41.737094,8.783286],
// "SCOGLIU DI VINTURA (BAIE DE CUPABIA)" : [41.740953,8.774053],
// "SCOGLIU BIANCU (BAIE DE CUPABIA)" : [41.739432,8.763639],
// "CALA DI CIGLIU (BAIE DE CUPABIA)" : [41.727749,8.725842],
// "POINTE DE CAPU NERU" : [41.721760,8.706820],
// "PLAGE DE CALA D’ORZU" : [41.738742,8.706131],
// "CALA DI U MURU (BAIE DE CALA D’ORZU)" : [41.741206,8.685724],
// "PUNTA DI PESCIU (BAIE DE CALA D’ORZU)" : [41.740221,8.684993],
// "BAIE DE CALA D’ORZU" : [41.735788,8.702435],
// "CALA DI U COMMISARIU (POINTE DE CAPU DI MURO)" : [41.739995,8.662269],
// "POINTE DE CAPU DI MURO" : [41.743743,8.657058],
// "ANSE D’ANA" : [41.572783,8.791911],
// "ANSE D’ARANA" : [41.585752,8.790021],
// "ANSE DE FERRU (SECTEUR ECCICA)" : [41.588641,8.779800],
// "ANSE DE POZZU (DERRIERE CAMPOMORO)" : [41.636193,8.802045],
// "BAIE DE CAMPOMORO" : [41.634461,8.817248],
// "BOTTE DE CAMPOMORO (SOUS LE VILLAGE DE BELVEDERE)"  : [41.646071,8.837884],
// "CALA D’OLIU (SECTEUR ECCICA)" : [41.594919,8.783284],
// "CALA AGULIA (SECTEUR ECCICA)" : [41.596529,8.788847],
// "CALA MURETTA" : [41.646536,8.855686],
// "COSTA SUMERADIA (SOUS LE VILLAGE DE BELVEDERE)" : [41.639194,8.839122],
// "COTE DE CANUSELLU (LITTORAL CAMPOMORO -> ECCICA)" : [41.623454,8.794757],
// "COTE DE MIGINI (LITTORAL CAMPOMORO -> ECCICA)" : [41.631253,8.796394],
// "COTE DE SALINA (LITTORAL CAMPOMORO -> ECCICA)" : [41.611683,8.791414],
// "CRIQUE DE BELVEDERE (SOUS LE VILLAGE DE BELVEDERE)": [41.639899,8.840800],
// "ECUEILS DE PISCINELLU (DERRIERE CAMPOMORO)" : [41.634360,8.793554],
// "ECUEILS DU MONTE ALBANU": [41.583185,8.779270],
// "I PURCHETTI (SOUS LE VILLAGE DE BELVEDERE)"  : [41.642795,8.841539],
// "ILE D’ECCICA" : [41.589141,8.766889],
// "ISULOTU DI CAMPOMORO (POINTE DE CAMPOMORO)" : [41.643363,8.811620],
// "PALO D’ECCICA (SECTEUR ECCICA)" : [41.592665,8.775624],
// "PLAGE D’ARANA" : [41.584175,8.792813],
// "PLAGE DE CAMPOMORO" : [41.629698,8.814476],
// "PLAGE DE PORTIGLIOLO (CAMPING U LEVANTE)" :[41.646085,8.869642],
// "POINTE ARATESI (LITTORAL CAMPOMORO -> ECCICA)" : [41.602172,8.785877],
// "POINTE DE CAMPOMORO" : [41.641753,8.804509],
// "POINTE DE FALUMBAGGIA (SOUS LE VILLAGE DE BELVEDERE)" : [41.647725,8.851563],
// "POINTE DE L’OMU (ENTREE BAIE DE CAMPOMORO)" : [41.637347,8.828386],
// "POINTE DE SCOGLIU LONGU (DERRIERE CAMPOMORO)" : [41.632619,8.795111],
// "POINTE DI U PUNTONU DI CAMPOMORO (LITTORAL CAMPOMORO -> ECCICA)" : [41.618036,8.786823],
// "PONTON DE CAMPOMORO" : [41.631741,8.811512],
// "PUNTA D’ECCICA" : [41.590682,8.774839],
// "PUNTA DI SCOLONU (LITTORAL CAMPOMORO -> ECCICA)" : [41.628430,8.791024],
// "ANSE DE PORTU (LITTORAL SENETOSA -> TIZZANO)" : [41.547154,8.830008],
// "ANSE DE TIVELLA" : [41.556226,8.809645],
// "BAIE DE L’AVENA" : [41.535526,8.852334],
// "BOTTE DE TIZZANO" : [41.515417,8.843354],
// "CALA DI BRIJIA (GOLFE DE MURTOLI)" : [41.520301,8.860015],
// "CALA DI ROCCAPINA" : [41.493028,8.933189],
// "CALA DI TIZZANO" : [41.541363,8.849733],
// "CALA DI TROMBA" : [41.545763,8.842862],
// "CALA LONGA"  : [41.554328,8.815914],
// "COTE DE TRADICETTU" : [41.521201,8.868733],
// "COTE DE ZIVIA" : [41.527948,8.847050],
// "COTE DU LABRU (LITTORAL DE CONCA)" : [41.577553,8.795783],
// "ETANG DE TIZZANO" :  [41.546566,8.853007],
// "GOLFE DE MURTOLI" : [41.515701,8.875562],
// "GOLFE DE ROCCAPINA" : [41.499284,8.907854],
// "ILES DES MOINES"  : [41.456517,8.908599],
// "ILOTS DE ROCCAPINA" : [41.490629,8.930327],
// "PASSAGE DE LA SARDAIGNE (POINTE DE SENETOSA)" : [41.555854,8.785838],
// "PLAGE D’ARGENT" : [41.515701,8.889776],
// "PLAGE D’ERBAJU DITE DU DOMAINE DE MURTOLI" : [41.501561,8.920525],
// "PLAGE DE BARCAGGIU" : [41.547530,8.832856],
// "PLAGE DE CONCA" : [41.575081,8.803985],
// "PLAGE DE L’AVENA" : [41.535285,8.855993],
// "PLAGE DE ROCCAPINA" : [41.495925,8.934335],
// "PLAGE DE SENETOSA (SOUS LE PHARE DE SENETOSA)" : [41.557491,8.791719],
// "PLAGE DE TIVELLA" : [41.556538,8.810368],
// "PLAGE DE TRADICETTU" : [41.522386,8.880753],
// "POINTE AQUEDDA (GOLFE DE MURTOLI)" : [41.517538,8.884261],
// "POINTE D’ACULA (POINTE DE SENETOSA)" : [41.555696,8.787986],
// "POINTE DE BARCAGGIU" : [41.544992,8.828435],
// "POINTE DE LATTONICCIA OU POINTE DE TIZZANO" : [41.517886,8.842540],
// "POINTE DE MURTOLI" : [41.505377,8.882731],
// "POINTE DE ROCCAPINA" : [41.488888,8.920805],
// "POINTE DE SCOGLIU LONGU DI SENETOSA" : [41.566637,8.783038],
// "POINTE DE SENETOSA" : [41.556215,8.787664],
// "PORT DE TIZZANO" : [41.542156,8.851381],
// "PORTICCIA DU SENETOSA" : [41.563488,8.789038],
// "ROCHER DE LA TORTUE DE CONCA" : [41.576462,8.794967],
// "SCOGLIU BIANCU (LITTORAL SENETOSA -> TIZZANO)" : [41.555334,8.798798],
// "ANSE D’ARBITRU" : [41.478802,9.017043],
// "ANSE DE CAPINERU (SECTEUR FIGARI)" : [41.456548,9.038900],
// "ANSE DE CHEVANU (SECTEUR FIGARI)" : [41.468099,9.036110],
// "ANSE DE FURNELLU" : [41.485827,8.994616],
// "BAIE DE FIGARI" : [41.459893,9.060768],
// "ECUEILS DU PRETRE" : [41.475674,8.970252],
// "GRAND ECUEIL D’OLMETO" : [41.460793,8.951988],
// "ILES BRUZZI" : [41.461217,9.021681],
// "PETIT ECUEIL D’OLMETO" : [41.466968,8.966417],
// "PLAGE DI PASTORI (SUD ROCCAPINA)" : [41.489241,8.967082],
// "POINTE DE MUCCHIU BIANCU (SUD ROCCAPINA)" : [41.482876,8.965106],
// "PORT DE FIGARI" : [41.475246,9.071926],
// "PUNTA DI CANISCIONE (SECTEUR MONACCIA D’AULLENE)" : [41.474815,8.980550],
// "TOUR D’OLMETO (SECTEUR MONACCIA D’AULLENE)" : [41.475249,8.984073],
// "TOUR DE CALDARELLO" : [41.464859,9.058019],









    

    
//     }
  
      
      

//     }
//     );

   
    
    this.getDispo()

    
    

    // this.scheduleObj.refreshEvents()

  }


  async getDispo(){

    this.spinner.show();

    this.test = [];

    this.dataPrint = [];

    this.resourcePrint = [];


  
    

    
    

  


    await this.db.collection('users',ref => ref.where("skills","array-contains","SNB1")).get().forEach(user =>{
    

      user.docs.forEach(async user => {
        

        

        if(user.data()["uid"] != null){


            


          


        await this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
      .subscribe(calendar => {

        let schObjDispo = (document.querySelector(".e-schedule") as any)
        .ej2_instances[0];

        
        if(calendar.data()){

          let objDispo = JSON.parse(calendar.data().allDays);
          if(calendar.data().dayAffected == null){
            this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
              dayAffected : []
            })
          }
        let objAffect = calendar.data().dayAffected;
      

       
        

       
        
        for (let key in objDispo) {




          if(objDispo[key].includes("Matin")){

            let i = objDispo[key].indexOf("Matin");

            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(this.matinStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.matinEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i;

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Matin";
            dispo.PeriodId = 1;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#eeff00";
            dispo.simpleStart = this.matinStart.toString();
            dispo.simpleEnd = this.matinEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
          }
          if(objDispo[key].includes("A-Midi")){

            let i = objDispo[key].indexOf("A-Midi");

            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(this.amidiStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.amidiEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i
            
            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "A-Midi";
            dispo.PeriodId = 2;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#12b800";
            dispo.simpleStart = this.amidiStart.toString();
            dispo.simpleEnd = this.amidiEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];


            this.test.push(dispo);
          }
          if(objDispo[key].includes("Soir")){

            let i = objDispo[key].indexOf("Soir");

            

            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(this.soirStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.soirEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Soir";
            dispo.PeriodId = 3;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#00248f";
            dispo.simpleStart = this.soirStart.toString();
            dispo.simpleEnd = this.soirEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
          }
          if(objDispo[key].includes("Nuit")){

            let i = objDispo[key].indexOf("Nuit");

            let dispo = new Dispo();
            

            let date = new Date(key);
            date.setHours(this.nuitStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.nuitEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Nuit";
            dispo.PeriodId = 4;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#bd45ed";
            dispo.simpleStart = this.nuitStart.toString();
            dispo.simpleEnd = this.nuitEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
          }
          if (objDispo[key].includes("INDISPO"))
                                        {
                                          let i = objDispo[key].indexOf("INDISPO");
                                          let dispo = new Dispo();
                                          let date = new Date(key);
                                          dispo.StartTime = new Date(
                                              date.getFullYear(), date.getMonth(), date.getDate(), 0);
                                          dispo.EndTime = new Date(
                                            date.getFullYear(), date.getMonth(), date.getDate(), 23, 59);
                                          dispo.eventIndex = i,
                                          dispo.Id = user.data()["uid"];
                                          dispo.UserToken = user.data()["token"];
                                          dispo.Subject = "INDISPO";
                                          dispo.PeriodId = 5;
                                          dispo.number =  Math.floor((Math.random()*9999)+1);
                                          dispo.UserId = user.data()["uid"];
                                          dispo.Color = '#9a0000';
                                          dispo.simpleStart = "0";
                                          dispo.simpleEnd = "23";
                                          dispo.FullName = user.data()["lastname"] +
                                              " " +
                                              user.data()["firstname"];
                                         
                                              this.test.push(dispo);
                                        }
          if(objDispo[key].find(a=>a.includes("Perso 1")) != null){

            let i = objDispo[key].indexOf(objDispo[key].find(a=>a.includes("Perso 1")));

            

            // console.log("CUSTOM");

            let el = objDispo[key].find(a=>a.includes("Perso 1"))

            let arraySplit = el.substring(el.indexOf("=")+1).split(/[:]/);

            // console.log(arraySplit);


            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(arraySplit[0],0);
            dispo.StartTime = new Date(date.toString());
            if(arraySplit[1] == 23){
              date.setHours(arraySplit[1]+1,0);
            } else {
              date.setHours(arraySplit[1],0);
            }
            
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Perso 1"
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#F57F17";
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.simpleStart = arraySplit[0];
            dispo.simpleEnd = arraySplit[1];
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];
            
          

            this.test.push(dispo);
          }

          if(objDispo[key].find(a=>a.includes("Perso 2")) != null){

            let i = objDispo[key].indexOf(objDispo[key].find(a=>a.includes("Perso 2")));

            

            // console.log("CUSTOM");

            let el = objDispo[key].find(a=>a.includes("Perso 2"))

            let arraySplit = el.substring(el.indexOf("=")+1).split(/[:]/);

            // console.log(arraySplit);


            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(arraySplit[0],0);
            dispo.StartTime = new Date(date.toString());
            if(arraySplit[1] == 23){
              date.setHours(arraySplit[1]+1,0);
            } else {
              date.setHours(arraySplit[1],0);
            }
  
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Perso 2"
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#F57F17";
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.simpleStart = arraySplit[0];
            dispo.simpleEnd = arraySplit[1];
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];
            
          

            this.test.push(dispo);
          }

  

        
          

        }

  

        
          

        

        if(objAffect){

          let i = 0

        objAffect.forEach(element => {
          let dispo = new Dispo();

         

          
          console.log(new Date(element["start"].seconds*1000));
            
      
            dispo.moyenAffected = element["moyen"];

            dispo.StartTime = new Date(element["start"].seconds*1000);
       
            dispo.EndTime = new Date(element["end"].seconds*1000);

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = element["moyen"];
            dispo.number = element["number"];
            dispo.UserId = user.data()["uid"];
            dispo.Color = "blue";
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];


            this.test.push(dispo);
            this.dataPrint.push(dispo);

            if(i == 0 && dispo.EndTime > new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),0)){
              this.resourcePrint.push({text: user.data()["lastname"]+" "+user.data()["firstname"], id: user.data()["uid"]});
              i++
            }
           
            
            
        });

        
        
        

        

      
      }
            

    // console.log(this.test);
    
      
      }

  
      schObjDispo.eventSettings.dataSource = this.test;

      // console.log(this.scheduleObj.eventsData);
      
      
      
      },(err) => {console.error(err)})

      console.log(this.getMonthNames(new Date().getMonth()+1));
      

      await this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).get()
      .subscribe(async calendar => {

        let schObjDispo = (document.querySelector(".e-schedule") as any)
        .ej2_instances[0];

        
        if(calendar.data()){

          let objDispo = JSON.parse(calendar.data().allDays);
          if(calendar.data().dayAffected == null){
            this.db.collection("users").doc(user.data()["uid"]).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
              dayAffected : []
            })
            
          }
        let objAffect = calendar.data().dayAffected;

       
        

       
        
        for (let key in objDispo) {




          if(objDispo[key].includes("Matin")){

            let i = objDispo[key].indexOf("Matin");

            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(this.matinStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.matinEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i;

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Matin";
            dispo.PeriodId = 1;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#eeff00";
            dispo.simpleStart = this.matinStart.toString();
            dispo.simpleEnd = this.matinEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
          }
          if(objDispo[key].includes("A-Midi")){

            let i = objDispo[key].indexOf("A-Midi");

            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(this.amidiStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.amidiEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i
            
            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "A-Midi";
            dispo.PeriodId = 2;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#12b800";
            dispo.simpleStart = this.amidiStart.toString();
            dispo.simpleEnd = this.amidiEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];


            this.test.push(dispo);
          }
          if(objDispo[key].includes("Soir")){

            let i = objDispo[key].indexOf("Soir");

            

            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(this.soirStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.soirEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Soir";
            dispo.PeriodId = 3;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#00248f";
            dispo.simpleStart = this.soirStart.toString();
            dispo.simpleEnd = this.soirEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
          }
          if(objDispo[key].includes("Nuit")){

            let i = objDispo[key].indexOf("Nuit");

            let dispo = new Dispo();
            

            let date = new Date(key);
            date.setHours(this.nuitStart,0);
            dispo.StartTime = new Date(date.toString());
            date.setHours(this.nuitEnd,0);
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Nuit";
            dispo.PeriodId = 4;
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#bd45ed";
            dispo.simpleStart = this.nuitStart.toString();
            dispo.simpleEnd = this.nuitEnd.toString();
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
          }
          if (objDispo[key].includes("INDISPO"))
                                        {
                                          let i = objDispo[key].indexOf("INDISPO");
                                          let dispo = new Dispo();
                                          let date = new Date(key);
                                          dispo.StartTime = new Date(
                                              date.getFullYear(), date.getMonth(), date.getDate(), 0);
                                          dispo.EndTime = new Date(
                                            date.getFullYear(), date.getMonth(), date.getDate(), 23, 59);
                                          dispo.eventIndex = i,
                                          dispo.Id = user.data()["uid"];
                                          dispo.UserToken = user.data()["token"];
                                          dispo.Subject = "INDISPO";
                                          dispo.PeriodId = 5;
                                          dispo.number =  Math.floor((Math.random()*9999)+1);
                                          dispo.UserId = user.data()["uid"];
                                          dispo.Color = '#9a0000';
                                          dispo.simpleStart = "0";
                                          dispo.simpleEnd = "23";
                                          dispo.FullName = user.data()["lastname"] +
                                              " " +
                                              user.data()["firstname"];
                                         
                                              this.test.push(dispo);
                                        }
          if(objDispo[key].find(a=>a.includes("Perso 1")) != null){

            let i = objDispo[key].indexOf(objDispo[key].find(a=>a.includes("Perso 1")));

            

            // console.log("CUSTOM");

            let el = objDispo[key].find(a=>a.includes("Perso 1"))

            let arraySplit = el.substring(el.indexOf("=")+1).split(/[:]/);

            // console.log(arraySplit);


            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(arraySplit[0],0);
            dispo.StartTime = new Date(date.toString());
            if(arraySplit[1] == 23){
              date.setHours(arraySplit[1]+1,0);
            } else {
              date.setHours(arraySplit[1],0);
            }
            
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Perso 1"
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#F57F17";
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.simpleStart = arraySplit[0];
            dispo.simpleEnd = arraySplit[1];
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];
            
          

            this.test.push(dispo);
          }

          if(objDispo[key].find(a=>a.includes("Perso 2")) != null){

            let i = objDispo[key].indexOf(objDispo[key].find(a=>a.includes("Perso 2")));

            

            // console.log("CUSTOM");

            let el = objDispo[key].find(a=>a.includes("Perso 2"))

            let arraySplit = el.substring(el.indexOf("=")+1).split(/[:]/);

            // console.log(arraySplit);


            let dispo = new Dispo();

            let date = new Date(key);
            date.setHours(arraySplit[0],0);
            dispo.StartTime = new Date(date.toString());
            if(arraySplit[1] == 23){
              date.setHours(arraySplit[1]+1,0);
            } else {
              date.setHours(arraySplit[1],0);
            }
  
            dispo.EndTime = new Date(date.toString());

            dispo.eventIndex = i

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = "Perso 2"
            dispo.UserId = user.data()["uid"];
            dispo.Color = "#F57F17";
            dispo.number = Math.floor((Math.random()*9999)+1);
            dispo.simpleStart = arraySplit[0];
            dispo.simpleEnd = arraySplit[1];
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];
            
          

            this.test.push(dispo);
          }

  

        
          

        }
        

        

        
          

        

        if(objAffect){

        objAffect.forEach(element => {
          let dispo = new Dispo();


          console.log(element["start"]);
          

          
            
      
            dispo.moyenAffected = element["moyen"];

            dispo.StartTime = new Date(element["start"].seconds*1000);
       
            dispo.EndTime = new Date(element["end"].seconds*1000);

            dispo.Id = user.data()["uid"];
            dispo.UserToken = user.data()["token"];
            dispo.Subject = element["moyen"];
            dispo.number = element["number"];
            dispo.UserId = user.data()["uid"];
            dispo.Color = "blue";
            dispo.FullName = user.data()["lastname"]+" "+user.data()["firstname"];
            dispo.Skills = user.data()["skills"];

            this.test.push(dispo);
        });

       

      }


            

        // console.log(this.test);
        
      
      }

      
      
      schObjDispo.eventSettings.dataSource = this.test;
      // console.log(this.scheduleObj.eventsData);
      this.scheduleObj.refreshEvents();
      console.log(schObjDispo.eventSettings.dataSource);
      
      },(err) => {console.error(err)})

      
    

    }

    


      
      })
      
    }).then((value) =>{



      
      
      this.spinner.hide();

      

      
       
        
      
        
        
      
    })

    

    
    


   

  }

  async dispoUser(){

    this.spinner.show();

    console.log(this.persoStart);
      console.log(this.periodSelected);
      console.log(this.persoEnd);
      

  
      
      
      

    if(this.persoStart !=null && this.persoEnd!=null && parseInt(this.persoStart)<parseInt(this.persoEnd)){

      console.log("Perso");
      


      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
      .subscribe(async calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.dateSelected){
            if(objDispo[key].some(a=>a.includes("Perso 1")) && !objDispo[key].some(a=>a.includes("Perso 2"))){
              objDispo[key].push("Perso 2 ="+this.persoStart.toString()+":"+this.persoEnd.toString());
            } else if(objDispo[key].some(a=>a.includes("Perso 2")) && !objDispo[key].some(a=>a.includes("Perso 1"))){ 
              objDispo[key].push("Perso 1 ="+this.persoStart.toString()+":"+this.persoEnd.toString());
            } else if(objDispo[key].some(a=>a.includes("Perso 1")) && objDispo[key].some(a=>a.includes("Perso 2"))){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Plus de Dispo perso disponible! Veuillez supprimer une dispo perso",
              })
            } else {
              objDispo[key].push("Perso 1 ="+this.persoStart.toString()+":"+this.persoEnd.toString());
            }

          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
        allDays : newObj
      })
      

    })

    await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).get()
      .subscribe(async calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.dateSelected){
            if(objDispo[key].some(a=>a.includes("Perso 1")) && !objDispo[key].some(a=>a.includes("Perso 2"))){
              objDispo[key].push("Perso 2 ="+this.persoStart.toString()+":"+this.persoEnd.toString());
            } else if(objDispo[key].some(a=>a.includes("Perso 2")) && !objDispo[key].some(a=>a.includes("Perso 1"))){ 
              objDispo[key].push("Perso 1 ="+this.persoStart.toString()+":"+this.persoEnd.toString());
            } else if(objDispo[key].some(a=>a.includes("Perso 1")) && objDispo[key].some(a=>a.includes("Perso 2"))){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Plus de Dispo perso disponible! Veuillez supprimer une dispo perso",
              })
            } else {
              objDispo[key].push("Perso 1 ="+this.persoStart.toString()+":"+this.persoEnd.toString());
            }

          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
        allDays : newObj
      }).then(()=> {
        this.persoStart=null
        this.persoEnd=null
        this.periodSelected=[]
        this.getDispo()
      
      }
      )
      

    })

  
      
    } else if(this.periodSelected.length>0) {

      console.log("Periode");

      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
      .subscribe(async calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.dateSelected){
            this.periodSelected.forEach((period)=>{
              objDispo[key].push(period);
            })
            
          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
        allDays : newObj
      })

      

    })

    await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).get()
    .subscribe(async calendar => { 


      let objDispo = JSON.parse(calendar.data().allDays);

      
      for (let key in objDispo) {

      

        if(key == this.dateSelected){
          
          this.periodSelected.forEach((period)=>{
            objDispo[key].push(period);
          })
        }

      

      }

      let newObj = JSON.stringify(objDispo);


      
    await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
      allDays : newObj
    }).then(()=> {
      this.persoStart=null
      this.persoEnd=null
      this.periodSelected=[]
      this.getDispo()
    
    }
    )

    

  })

   
    }else{

      console.log("NON valide");

      this.spinner.hide();
      console.log(this.persoStart);
      console.log(this.periodSelected);
      console.log(this.persoEnd);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Erreur sur l'ajout de Dispo!",
      })
    }

    
    }

    async dispoAllUser(){

      this.spinner.show();
  
        
        
  
        console.log("24");
  
        await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
        .subscribe(async calendar => { 
  
  
          let objDispo = JSON.parse(calendar.data().allDays);
  
          
          for (let key in objDispo) {
  
          
  
            if(key == this.dateSelected){
              
              objDispo[key].push("Nuit");
              objDispo[key].push("Matin");
              objDispo[key].push("A-Midi");
              objDispo[key].push("Soir");
            }
  
          
  
          }
  
          let newObj = JSON.stringify(objDispo);
  
  
          
        await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
          allDays : newObj
        })
  
        
  
      })
  
      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).get()
      .subscribe(async calendar => { 
  
  
        let objDispo = JSON.parse(calendar.data().allDays);
  
        
        for (let key in objDispo) {
  
        
  
          if(key == this.dateSelected){
            
            objDispo[key].push("Nuit");
              objDispo[key].push("Matin");
              objDispo[key].push("A-Midi");
              objDispo[key].push("Soir");
          }
  
        
  
        }
  
        let newObj = JSON.stringify(objDispo);
  
  
        
      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
        allDays : newObj
      }).then(()=> {
        this.persoStart=null
        this.persoEnd=null
        this.periodSelected=[]
        this.getDispo()
      
      }
      )
  
      
  
    })
  
     
      }
  
      



    async deleteDispo(){

      this.spinner.show();

      await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
      .subscribe(calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.datePipe.transform(this.selectedStartTime,"yyyy-MM-dd 00:00:00.000")){
            var index = objDispo[key].indexOf(this.subject);
            objDispo[key].splice(index,1);
          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
        allDays : newObj
      })

    })

    await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).get()
      .subscribe(calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);

        
        for (let key in objDispo) {

        

          if(key == this.datePipe.transform(this.selectedStartTime,"yyyy-MM-dd 00:00:00.000")){
            var index = objDispo[key].indexOf(this.subject);
            objDispo[key].splice(index,1);
          }

        

        }

        let newObj = JSON.stringify(objDispo);


        
      this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
        allDays : newObj
      }).then(()=> this.getDispo()
      )

    })

    }


  async affectUser(){

    this.spinner.show();

    

    await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get().subscribe(async (calendar) => {
      if(calendar.data().dayAffected != null){
        let objAffect = calendar.data().dayAffected;
        for (const element of objAffect) {
          
          
          
            let start = new Date(element["start"].seconds*1000);
            let title = element["moyen"];

          
            
            
  
            
            
            if(start.toString() == this.selectedStartTime.toString() && title == "Astreinte SNB2"){
            
  
      

              this.spinner.hide();
  
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Personnel Indisponible (SNB2)",
              })
  
              return;
            }
            
         
        } 

    

          await this.users.pipe(map(users => users.find(user => user.uid == this.userSelected))).subscribe(result => this.userSelect = result);
    
    
        await this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
          dayAffected : firestore.FieldValue.arrayUnion({moyen: "Astreinte SNB1" ,start: this.selectedStartTime, end : this.selectedEndTime, skill: "SNB1", number: this.number.toString()})
        });
    
        await this.db.collection("users").doc(this.userSelected).collection("affectations").doc(this.number.toString()).set({
          end : this.selectedEndTime,
          start : this.selectedStartTime,
          moyen : "Astreinte SNB1",
          skill : "SNB1",
          number: this.number.toString()
        }).then(() =>     { console.log("ADD SUCCESS");
    
    
    
          //   this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).get()
          // .subscribe(calendar => { 
    
    
          //   let objDispo = JSON.parse(calendar.data().allDays);
    
            
          //   for (let key in objDispo) {
    
            
    
          //     if(key == this.datePipe.transform(this.selectedStartTime,"yyyy-MM-dd")+" 00:00:00.000"){
          //       objDispo[key].splice(this.selectedIndex,1);
          //     }
    
            
    
          //   }
    
          //   let newObj = JSON.stringify(objDispo);
    
    
            
          // this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.monthNames[new Date().getMonth()]).update({
          //   allDays : newObj
          // })
    
        // })
    
      
    
    
        // await this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userSelected).set({
        //   uid : this.userSelected,
        //   token: this.userSelectedToken,
        //   skill : this.skillSelected,
        // }).then(() =>     {   // console.log("ADD SUCCESS");
        let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
      let notification = {
        "notification": {
          "title": "🚨 AFFECTATION 🚨",
          "body": "Vous êtes affecté en tant que SNB1 de "+this.start+"h à "+this.end+"h.",
          "click_action": "FLUTTER_NOTIFICATION_CLICK",
            "sound": "affect.wav",
            "android_channel_id": "info"
        },
        "android": {
          "ttl": 3600 * 1000,
          "notification": {
            color: '#ff0000',
            sound: 'affect',
            channel_id: 'info' // important to get custom sound
          }
        },  "data": {
          "moyen":"Astreinte SNB1",
       "userid": this.userSelected,
        },
        "to": this.userSelectedToken,
        "priority": "high"
      }
      let url = 'https://fcm.googleapis.com/fcm/send';
      this.http.post(url, notification, { headers: myheaders })
      .subscribe(results => {
        // window.location.reload();
        this.getDispo().then(()=>{this.spinner.hide;
        this.scheduleObj.refresh()});
       }, error => {
        console.log(error);
      });;
    }
        );
    
        }
    
    
    
    })


      

    

    
    


  }

  async desaffectUser(){

    this.spinner.show();

    await this.users.pipe(map(users => users.find(user => user.uid == this.userSelected))).subscribe(result => this.userSelect = result);



        this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).get()
      .subscribe(calendar => { 


        let objDispo = JSON.parse(calendar.data().allDays);
        let newArray = calendar.data().dayAffected;

        
        for (let key in objDispo) {

  
       
        

          if(this.datePipe.transform(key,"yyyy-MM-dd") == this.datePipe.transform(this.selectedStartTime,"yyyy-MM-dd")){
            // if(this.selectedStartTime.getHours() == this.nuitStart
            //  && this.selectedEndTime.getHours() == this.nuitEnd){
            //   objDispo[key].push("Nuit")
            // }else
            // if(this.selectedStartTime.getHours() == this.matinStart && this.selectedEndTime.getHours() == this.matinEnd){
            //   objDispo[key].push("Matin")
            // }else
            // if(this.selectedStartTime.getHours() == this.amidiStart && this.selectedEndTime.getHours() == this.amidiEnd){
            //   objDispo[key].push("A-Midi")
            // }else
            // if(this.selectedStartTime.getHours() == this.soirStart && this.selectedEndTime.getHours() == this.soirEnd){
            //   objDispo[key].push("Soir")
            // }else{
            //   if(this.subject == "Perso 1"){
            //   objDispo[key].push("Perso 1 ="+this.selectedStartTime.getHours().toString()+":"+this.selectedEndTime.getHours().toString())
            //   }
            //   if(this.subject == "Perso 2"){
            //     objDispo[key].push("Perso 2 ="+this.selectedStartTime.getHours().toString()+":"+this.selectedEndTime.getHours().toString())
            //     }

            // }
            


            let index = newArray.findIndex(x=> this.datePipe.transform(new Date(x.start["seconds"]*1000),"yyyy/MM/dd hh:mm") === this.datePipe.transform(this.selectedStartTime,"yyyy/MM/dd hh:mm") && x.moyen == "Astreinte SNB1");
            newArray.splice(index,1);
          }

        

        }

        // let newObj = J sON.stringify(objDispo);


        
      this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
        // allDays : newObj,
        dayAffected : newArray
      })

    })

    this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).get()
    .subscribe(calendar => { 


      let objDispo = JSON.parse(calendar.data().allDays);
      let newArray = calendar.data().dayAffected;

      
      for (let key in objDispo) {


     
      

        if(this.datePipe.transform(key,"yyyy-MM-dd") == this.datePipe.transform(this.selectedStartTime,"yyyy-MM-dd")){
          // if(this.selectedStartTime.getHours() == this.nuitStart
          //  && this.selectedEndTime.getHours() == this.nuitEnd){
          //   objDispo[key].push("Nuit")
          // }else
          // if(this.selectedStartTime.getHours() == this.matinStart && this.selectedEndTime.getHours() == this.matinEnd){
          //   objDispo[key].push("Matin")
          // }else
          // if(this.selectedStartTime.getHours() == this.amidiStart && this.selectedEndTime.getHours() == this.amidiEnd){
          //   objDispo[key].push("A-Midi")
          // }else
          // if(this.selectedStartTime.getHours() == this.soirStart && this.selectedEndTime.getHours() == this.soirEnd){
          //   objDispo[key].push("Soir")
          // }else{
          //   if(this.subject == "Perso 1"){
          //   objDispo[key].push("Perso 1 ="+this.selectedStartTime.getHours().toString()+":"+this.selectedEndTime.getHours().toString())
          //   }
          //   if(this.subject == "Perso 2"){
          //     objDispo[key].push("Perso 2 ="+this.selectedStartTime.getHours().toString()+":"+this.selectedEndTime.getHours().toString())
          //     }

          // }
          


          let index = newArray.findIndex(x=> this.datePipe.transform(new Date(x.start["seconds"]*1000),"yyyy/MM/dd hh:mm") === this.datePipe.transform(this.selectedStartTime,"yyyy/MM/dd hh:mm"));
          newArray.splice(index,1);
        }

      

      }

      // let newObj = JSON.stringify(objDispo);


      
    this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
      // allDays : newObj,
      dayAffected : newArray
    })

  })



    

    this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth())).update({
      ['dayAffected.' + this.number.toString()]: firestore.FieldValue.delete()
    }).then(() =>     { console.log("ADD SUCCESS");

    

    this.db.collection('users').doc(this.userSelected).collection('affectations').doc(this.number.toString()).delete()

    // await this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userSelected).set({
    //   uid : this.userSelected,
    //   token: this.userSelectedToken,
    //   skill : this.skillSelected,
    // }).then(() =>     {   // console.log("ADD SUCCESS");
    let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
  let notification = {
    "notification": {
      "title": "🚨 AFFECTATION 🚨",
      "body": "Vous n'êtes plus affecté sur le Créneau Infimier/ère veuillez ouvrir vos affectations",
      "click_action": "FCM_PLUGIN_ACTIVITY",
      "sound": "desaffect.wav",
      "android_channel_id": "info"
    },
    "android": {
      "ttl": 3600 * 1000,
      "notification": {
        "color": '#ff0000',
        "sound": 'desaffect',
        "channelId": 'info' // important to get custom sound
      }
    }, "data": {
   "moyens": this.moyenSelected,
   "userid": this.userSelected,
    },
    "to": this.userSelectedToken,
    "priority": "high"
  }
  let url = 'https://fcm.googleapis.com/fcm/send';
  this.http.post(url, notification, { headers: myheaders })
  .subscribe(results => {
    
    // window.location.reload();
    // this.getDispo().then(()=>{this.spinner.hide;
    //   this.scheduleObj.refresh()});
    
   }, error => {
    console.log(error);
  });;
}


    );

    this.db.collection("users").doc(this.userSelected).collection("Calendar").doc(this.getMonthNames(new Date().getMonth()+1)).update({
      ['dayAffected.' + this.number.toString()]: firestore.FieldValue.delete()
    }).then(() =>     { console.log("ADD SUCCESS");

    

    this.db.collection('users').doc(this.userSelected).collection('affectations').doc(this.number.toString()).delete()

    // await this.db.collection('moyens').doc(this.moyenSelected).collection('equipe').doc(this.userSelected).set({
    //   uid : this.userSelected,
    //   token: this.userSelectedToken,
    //   skill : this.skillSelected,
    // }).then(() =>     {   // console.log("ADD SUCCESS");
    let myheaders = { 'Authorization': 'key=AAAAxHjPluU:APA91bH4El8Z7TXyKvyzTAk4pknuV_nOIelzSU5n7Kkh6Q2u3ZD9Qs3otYsMjw-0G6SJ29Hh_lvSrm6Npc1nrC0zEJ1qB1bPZAsPnoyyfCaUrB2MXiLPZLV9EVcww_Tm6DffBE8cd1FH', 'Content-Type': 'application/json'};
  let notification = {
    "notification": {
      "title": "🚨 AFFECTATION 🚨",
      "body": "Vous n'êtes plus affecté sur le Créneau Infimier/ère veuillez ouvrir vos affectations",
      "click_action": "FCM_PLUGIN_ACTIVITY",
      "sound": "desaffect.wav",
      "android_channel_id": "info"
    },
    "android": {
      "ttl": 3600 * 1000,
      "notification": {
        "color": '#ff0000',
        "sound": 'desaffect',
        "channelId": 'info' // important to get custom sound
      }
    }, "data": {
   "moyens": this.moyenSelected,
   "userid": this.userSelected,
    },
    "to": this.userSelectedToken,
    "priority": "high"
  }
  let url = 'https://fcm.googleapis.com/fcm/send';
  this.http.post(url, notification, { headers: myheaders })
  .subscribe(results => {
    
    // window.location.reload();
    this.getDispo().then(()=>{this.spinner.hide;
      this.scheduleObj.refresh()});
    
   }, error => {
    console.log(error);
  });;
}


    );



  }

  changeResources(){
    let schObjDispo = (document.querySelector(".e-schedule") as any)
        .ej2_instances[0];
    schObjDispo.eventSettings.dataSource = this.dataPrint;
    this.scheduleObj.resources[0].dataSource = this.resourcePrint;
    console.log(this.scheduleObj.resources[0]);
    
  }

  public async onPrintIconClick(): Promise<void> {
    await this.changeResources();
    let printModel: ScheduleModel = {
      resources: this.scheduleObj.resources,
      width: '100%',
      height: '100%',
    
      
      
      
      

    };
  
    // if (args.element.classList.contains("e-time-slots")) {

    //   if(this.getCountDispo(args.date)==2){
    //     (args.element as HTMLElement).style.backgroundColor = "green";
    //   } else if(this.getCountDispo(args.date)==3){
    //     (args.element as HTMLElement).style.backgroundColor = "blue";
    //   } else {
    //     (args.element as HTMLElement).style.backgroundColor = "red";
    //   }
    await new Promise(resolve => setTimeout(resolve, 1000)); 
    this.scheduleObj.print(printModel);
    this.userDataSource = this.getUsers();
    this.getDispo();

    this.scheduleObj.refresh();
      }


  




}




