
<ngx-spinner></ngx-spinner>
<div class="container-fluid">
  <app-pagetitle title="Feuille de garde" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="modal-body">

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
  <!-- <div class="row">
    <div class="col-md-12">
        
            <div class="section-heading">Calendrier</div>
        
            <div class="horizontal">
              <div *ngFor="let day of dayNames " >
                  <input   type="radio" name="day-intervention" value="{{day}}" class="radio-type-intervention" id="{{day}}" (change)="selectDay(day)">
                  <label  for="{{day}}">{{day}}</label>
                  </div>
            </div>
        </div>

  </div> -->
  <div class="row" *ngIf="daySelected">
    <div class="col-md-12">
      <div class="drag-container">
            <div class="section-heading">Periodes</div>
        
            <div class="horizontal">
              <div *ngFor="let period of periods " >
                  <input   type="radio" name="period-intervention" value="{{period}}" class="radio-type-intervention" id="{{period}}" (change)="selectPeriod(period)">
                  <label  for="{{period}}">{{period}}</label>
                  </div>
            </div>
        </div>
      </div>

  </div>
    <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Moyens</div>
          
              <div class="horizontal">
                <div style="margin-left: 12px;" *ngFor="let moyen of moyens | async">
                    <input   type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getUserMoyen($event)">
                    <label  for="{{moyen.name}}">{{moyen.name}}</label>
                    </div>
              </div>
          </div>
      </div>
      <div *ngIf="moyenIsSelected" >
      
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Equipier affectés</div>

              <button *ngIf="nextTeam==true"  class="btn btn-primary" (click)="copyTeam()">Copier équipage précédent <i class="mdi mdi-refresh"></i></button>

              <div *ngIf="moyenPatron==false"><p class="card-title-desc"><code>Patron manquant</code></p></div>
              <div *ngIf="moyenIsCompleted==false"><p class="card-title-desc"><code>Équipiers insuffisants pour départ</code></p></div>
              <div class="horizontal"> 
              <div cdkDropList #usersMoyenList="cdkDropList" [cdkDropListData]="usersMoyen" [cdkDropListConnectedTo]="[]"
                class="" (cdkDropListDropped)="dropUserMoyen($event)">
                <div class="item-box" *ngFor="let user of usersMoyen" cdkDrag>
                <div *ngIf="user.skillSelected">{{user.skillSelected}} : </div>
                    <input   type="radio" name="user-intervention" value="{{user.uid}}" class="radio-type-intervention" id="{{user.email}}" (click)="getUserMoyenID($event)">
                    <label  for="{{user.email}}">{{user.firstname+" "+user.lastname}}</label>
                </div>
              </div>
            </div>
            </div>
      </div>
      </div>
      <div *ngIf="moyenIsSelected" class="col-md-12">
        <div class="drag-container">
                  <div class="card bg-danger text-white-120" (click)="deleteUserMoyen()">
                    <div class="card-body">
                      <h1 class="mt-0 mb-4 text-white" style="text-align: center;"><i class="mdi mdi-trash-can-outline mr-3"></i></h1>
                    </div>
            
                    
                </div>
          </div>
    </div>
    </div>
    <div class="row" *ngIf="periodSelected">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Compétence requise</div>
        
              <div class="horizontal">
                <div *ngFor="let skill of skills " >
                    <input   type="radio" name="skill-intervention" value="{{skill}}" class="radio-type-intervention" id="{{skill}}" (change)="selectSkill(skill)">
                    <label  for="{{skill}}">{{skill}}</label>
                    </div>
              </div>
            </div>
      </div>
  </div>
    <div class="row" *ngIf="periodSelected && skillSelected">
        <div class="col-md-12">
            <div class="drag-container">
                <div class="section-heading">Équipiers disponibles</div>
              <div class="horizontal">
                <div cdkDropList #usersList="cdkDropList" [cdkDropListData]="userDispo" [cdkDropListConnectedTo]="[]"
                  class="item-list horizontal" (cdkDropListDropped)="dropUser($event)" cdkDropListOrientation="horizontal">
                  <div class="horizontal" *ngFor="let user of userDispo" cdkDrag>
                    
                      <input   type="checkbox" name="moyen-intervention" value="{{user.uid}}" class="radio-equipier-intervention" id="{{user.uid}}" (click)="getUserID($event)">
                      <label  class="label-equipier" for="{{user.uid}}"><img src="{{user.profilpic}}" onerror="this.src='../../../../assets/images/picto/users.png';" alt=""><span>{{user.firstname}}</span></label>
  
                  </div>
                </div>
                </div>
              </div>
        </div>
    </div>
</div>
    </div>
  </div>
  <button class="btn btn-primary" (click)="sendNotif()" type="submit">Sauvegarder</button>
</div>
</div>