import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from "@angular/fire/storage";

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  

  url  ='';

  imageDetailList: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase, 
    private storage: AngularFireStorage) { }



// Create a reference under which you want to list
// const listRef = ref(storage, 'files/uid');

// Find all the prefixes and items.
// listAll(listRef)
//   .then((res) => {
//     res.prefixes.forEach((folderRef) => {
//       // All the prefixes under listRef.
//       // You may call listAll() recursively on them.
//     });
//     res.items.forEach((itemRef) => {
//       // All the items under listRef.
//     });
//   }).catch((error) => {
//     // Uh-oh, an error occurred!
//   });

//method to retrieve download url
public async getUrl(snap: firebase.storage.UploadTaskSnapshot) {
  const url = await snap.ref.getDownloadURL();
  this.url = url;  //store the URL
  return url;
  console.log(this.url);
}

  getImageDetailList() {
    this.storage.ref("moyens").listAll()

    this.imageDetailList = this.firebase.list('moyens');
  }

  insertImageDetails(imageDetails) {
    this.imageDetailList.push(imageDetails);
  }
}
