
  <ngx-spinner></ngx-spinner>
  <button class="btn btn-danger" [routerLink]="['/planningSNB1']">SNB1 <i class="mdi mdi-diving-flippers"></i></button>
  <button class="btn btn-danger" [routerLink]="['/planningSNB2']">SNB2 <i class="mdi mdi-diving-scuba-tank-multiple"></i></button>
  <button class="btn btn-danger" [routerLink]="['/planningINF']">Infirmier <i class="fas fa-syringe"></i></button>
  <button class="btn btn-primary float" (click)="getDispo()">Raffraichir <i class="mdi mdi-refresh"></i></button>
  
  <ejs-schedule #scheduleObj
  [currentView]="setView" 
  [views]="scheduleViews" 

  (eventRendered)="oneventRendered($event)"

  [workHours]="workHours"

  [rowAutoHeight]="true"

  height="auto"
  


  [group]="group"
  [timeScale]="timeScale"
  [selectedDate]="selectedDate"
  [firstDayOfWeek]='startWeek'

  [allowVirtualScrolling]="true"

  (popupOpen)='onPopupOpen($event)'
  (eventClick)='onEventClick($event)'
  >
   
  <e-resources>
  <e-resource 
            field="UserId" title="User" name="Users"
            [dataSource]="userDataSource | async" [allowMultiple]="allowMultipleCategory"
            textField="text" idField="id" colorField="color">
</e-resource>
        
</e-resources>


<ng-template #centerAutreDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Autres moyens</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
  <div class="modal-body">
    
    <div class="row" *ngFor="let rattachement of rattachements | async">
      <div class="col-md-12">
        <div class="form-group position-relative">
          <label for="validationTooltip03">{{rattachement.name}}</label>
          <div class="form-group row">
              <div class="row">
            
                
              <div *ngFor="let moyen of otherMoyens" class="moyenbutton">
              
                <input *ngIf="moyen.rattachement == rattachement.name" class="form-control" type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention" id="{{moyen.name}}" (change)="getMoyen($event)" >
                <label  *ngIf="moyen.rattachement == rattachement.name" for="{{moyen.name}}">{{moyen.name}}</label>
              
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

  </div>
  </ng-template>



<!-- <ng-template #resourceHeaderTemplate let-data>
            <div class='template-wrap'>
                <div class="room-name">{{data.userDataSource.text}}</div>
            </div>
</ng-template> -->

<ng-template #centerDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Affectation de {{start}}h à {{end}}h</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Compétences</div>
          
              <div class="horizontal">
                <div *ngFor="let skill of skills">
                    <input  *ngIf="skill != ''" type="radio" name="skill-intervention" value="{{skill}}" class="radio-type-intervention" id="{{skill}}" (change)="getSkill($event)">
                    <label *ngIf="skill != ''" for="{{skill}}">{{skill}} <i *ngIf="skill=='SNB1'" class="mdi mdi-diving-flippers"></i>
<i *ngIf="skill=='SNB2'" class="mdi mdi-diving-scuba-tank-multiple"></i>
<i *ngIf="skill=='PSE1'" class="fas fa-band-aid"></i>
<i *ngIf="skill=='PSE2'" class="mdi mdi-bandage"></i>
<i *ngIf="skill=='Patron Vdt'" class="fas fa-ship"></i>
<i *ngIf="skill=='Patron SR'" class="ri-ship-2-fill"></i>
<i *ngIf="skill=='Infirmier'" class="fas fa-syringe"></i>
<i *ngIf="skill=='Méca'" class="fas fa-tools"></i>
<i *ngIf="skill=='SSA'" class="fas fa-swimmer"></i>
</label>


                    </div>
              </div>
          </div>
      </div>
      </div>
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Moyens</div>
          
              <ng-container  class="horizontal">
                <ng-container style="margin:5px;" *ngFor="let moyen of moyens | async" >
                    <input   type="radio" name="moyen-intervention" value="{{moyen.name}}" class="radio-type-intervention moyenbutton" id="{{moyen.name}}" (change)="getMoyen($event)">
                    <label  for="{{moyen.name}}">{{moyen.name}} <img class="png-icon" src="{{moyen.icon}}" style="width: 15px; height: 15px;"></label>
                    
                    
                    </ng-container>
                    <div class="moyenbutton" (click)="otherCenter(centerAutreDataModal)">
                            <button type="button" class="btn btn-danger dropdown-toggle">
                              Autres Centres
                    </button>
                          </div>
                    </ng-container>
          </div>
      </div>
    </div>
    </div>
    <div class="modal-footer">
    <div class="col-lg-12">
    <button (click)="deleteDispo()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-danger float-left">Supprimer Dispo</button>
    <button (click)="deleteAllDayDispo()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-danger float-left">Supprimer Dispo 24h</button>

    
                  <button (click)="affectUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary float-right">Affecter</button>
    </div>
                </div>
    </ng-template>

    <ng-template #notcenterDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Retirer Affectation</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
    <button (click)="desaffectUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary col-md-12">Retirer</button>
               
    </div>
    </ng-template>

    <ng-template #optionIndispoModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Ajouter une indisponibilité</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Périodes</div>
          
              <div class="horizontal">
                <div *ngFor="let period of optionIndispo">
                    <input   type="radio" name="skill-intervention" value="{{period}}" class="radio-type-intervention" id="{{period}}" (change)="getOptionIndispo($event)">
                    <label  for="{{period}}">{{period}}</label>
                    </div>
              </div>
          </div>
      </div>
      </div>
    </div>
    <div class="modal-footer">
    <div class="col-lg-12">
                  <button (click)="indispoUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary float-right">Ajouter</button>
                </div>
                </div>
    </ng-template>


    <ng-template #dispocenterDataModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Ajouter une disponibilité</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
    <div class="modal-body">
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Périodes</div>
          
              <div class="horizontal">
                <div *ngFor="let period of periods">
                    <input   type="checkbox" name="skill-intervention" value="{{period}}" class="{{period}}" id="{{period}}" (change)="getPeriod($event)">
                    <label  for="{{period}}">{{period}}</label>
                    </div>
              </div>
          </div>
      </div>
      </div>
      <div class="row">
      <div class="col-md-12">
          <div class="drag-container">
              <div class="section-heading">Perso</div>
          
              De
    <input type="number" min="0" max="24" required name="persoDebut" (change)="getStart($event)" (keypress)="getStart($event)">
    H à
    <input type="number" min="0" max="24" required name="persoFin" (change)="getEnd($event)" (keypress)="getEnd($event)">
    H

          </div>
      </div>
      </div>
    </div>
    <div class="modal-footer">
    <div class="col-lg-12">
    <button (click)="dispoAllUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-success float-left">Dispo 24h</button>
    <button (click)="openOptionIndispo()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-danger float-none">INDISPO</button>
                  <button (click)="dispoUser()" (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary float-right">Ajouter</button>
                </div>
                </div>
    </ng-template>

    <ng-template #dateHeaderTemplate let-data>
        <div class="date-text" style="text-align: center; font-size: 13px; font-weight: bold; "
         >{{getDateHeaderText(data.date)}}</div>
    </ng-template>

    <ng-template #popContent let-data style="max-width: 100%;">


<div>
<span class="big border border-success" title="Équipiers"><i class="ri-group-fill"></i>{{getCountDispo(this.dateCount)}}</span>
<span class="big border border-success" title="SNB1"><i class="mdi mdi-diving-flippers"></i>{{countSkill1}}</span>
<span class="big border border-success" title="SNB2"><i class="mdi mdi-diving-scuba-tank-multiple"></i>{{countSkill2}}</span>
<span class="big border border-success" title="PSE1"><i class="fas fa-band-aid"></i>{{countSkill3}}</span>
<span class="big border border-success" title="PSE2"><i class="mdi mdi-bandage"></i>{{countSkill4}}</span>
<span class="big border border-success" title="Patron Vdt"><i class="fas fa-ship"></i>{{countSkill5}}</span>
<span class="big border border-success" title="Patron SR"><i class="ri-ship-2-fill"></i>{{countSkill6}}</span>
<span class="big border border-success" title="Infirmier"><i class="fas fa-syringe"></i>{{countSkill7}}</span>
<span class="big border border-success" title="Mécanicien"><i class="fas fa-tools"></i>{{countSkill8}}</span>
<span class="big border border-success" title="SSA"><i class="fas fa-swimmer"></i>{{countSkill9}}</span>
</div> 
<div *ngFor="let name of this.namesOfDispo" style="font-weight: bolder; font-size: 18px;">{{name}}</div>
</ng-template>

  <ng-template #timeScaleMajorSlotTemplate let-data>
      <div class="majorTime">{{getMajorTime(data.date)}}</div>
        <div style="vertical-align: middle; align-items: center;" popoverClass="popoverClass" [ngbPopover]="popContent" placement="bottom" container="body" (click)="getDate(data.date)">
          <i class='ri-user-fill' ></i>
          <i class='ri-information-line' ></i>
        </div>
    </ng-template>
    <ng-template #timeScaleMinorSlotTemplate let-data>
        <div class="minorTime">{{getMinorTime(data.date)}}</div>

    </ng-template>
  </ejs-schedule>

