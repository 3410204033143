import { Component, OnInit, ViewChildren, QueryList, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './advanced.model';

import { tableData } from './data';

import { AdvancedService } from './advanced.service';
import { InterventionsDirective, SortEvent } from './interventions-directive';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

export class User {

  uid?:string;
  token?:string;
  firstname?:string;
  lastname?:string;
  dispo?:boolean;
  email?:string;
  phone?:string;
  profilpic?:string = "";
  skill1?:string;
  skill2?:string;
  skill3?:string;
  skill4?:string;
  skill5?:string;
  skill6?:string;
  skill7?:string;
  skill8?:string;
  skill9?:string;
  lastSeenInEpoch?:number;
  last_login?:number;
  presence?:boolean;




  constructor(user: User = {}) {
    this.uid = user.uid;
    this.token = user.token;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.dispo = user.dispo;
    this.phone = user.phone;
    this.profilpic = user.profilpic;
    this.skill1 = user.skill1;
    this.skill2 = user.skill2;
    this.skill3 = user.skill3;
    this.skill4 = user.skill4;
    this.skill5 = user.skill5;
    this.skill6 = user.skill6;
    this.skill7 = user.skill7;
    this.skill8 = user.skill8;
    this.skill9 = user.skill9;
    this.email = user.email;
    this.last_login = user.last_login;
     this.presence = user.presence;
     this.lastSeenInEpoch = user.lastSeenInEpoch;
  }

}

@Component({
  selector: 'app-interventions',
  templateUrl: './interventions.component.html',
  styleUrls: ['./interventions.component.scss'],
  providers: [AdvancedService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class InterventionsComponent implements OnInit, OnDestroy {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  // Table data
  tableData: Table[];

  moyens: Observable<any[]>;

  rattachements = [];

  moyenSelected;

  numeroSelected;

  usersMoyen = [];
  tableMoyen = [];


  alertForm: FormGroup;

  @ViewChild('centerDataModal')
  public centerDataModal; 

  intervention:any;


  tables$: Observable<Table[]>;
  total$: Observable<number>;

  @ViewChildren(InterventionsDirective) headers: QueryList<InterventionsDirective>;
  interval: NodeJS.Timer;

  constructor(public service: AdvancedService,
    public db: AngularFirestore,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  async ngOnInit() {
    

    this.moyens = this.db.collection('moyens').valueChanges();

    this.db.collection("rattachements").get().subscribe((doc) => {
      doc.forEach((data) => {
        this.rattachements.push(data.data()["name"])
      })
      
    })


    await this.getData();


    

    


  

    

    


    this.breadCrumbItems = [{ label: 'Activités' }, { label: 'Interventions', active: true }];

    /**
     * fetch data
     */
    this._fetchData();



    this.interval = setInterval(() => {
      this.getData();
   }, 10000);


    
  
      

  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }


  async getData(){


    this.spinner.show();

    this.tableData = [];
    this.tableMoyen = [];
    tableData.length = 0;

    await this.db.collection("interventions").get().forEach((doc) => {
      doc.docs.forEach((inter)=> { 
        var tableMoyen = []
        if(inter.data()["statut"] !== "Terminée"){
        
        
        inter.data()["moyens"].forEach(moyen => {
          
          
          this.db.collection("moyens").doc(moyen).get().subscribe((doc) => {
            console.log(doc.data());
            
            tableMoyen.push(doc.data());
            this.tableMoyen.push(doc.data());
  
          });
        }); 
        console.log(tableMoyen);
        tableData.push(
      {
        date: this.datePipe.transform(new Date(inter.data()["date"]["seconds"]*1000),"yyyy/MM/dd HH:mm"), 
        statut: inter.data()["statut"],
        moyens: tableMoyen,
        numero: inter.data()["numero"].toString(), 
        resume: inter.data()["motif"]+" // "+inter.data()["motifsup"]+" // "+inter.data()["adress"] 
      })

    }

     


      
    })
  }).then(()=> {this.spinner.hide()
    console.log(this.tableMoyen);});

  
  

  

  }

  async finishOps(change: string, inter:string){

    

    Swal.fire({
      title: 'Êtes-vous sûr de vouloir cloturer l\'intervention?',
      confirmButtonText: `Confirmer`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Interventions terminée!', '', 'success')

        this.spinner.show(); 

        this.db.collection("interventions").doc(inter).update({
          statut: change
        })
    
    
    
      
    
    
        this.db.collection("interventions").doc(inter).update({
          statut: "Terminée"
        })
    
      
    
        
    
        
    
        
    
        this.getData()
    
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          console.log("done")
        }, 1000);
     
      }
    });
    

    
    
  }

  public ifContainRattachement(rattachement){
   return n => n['rattachement']==rattachement
  }

  getRattachements(rattachement): void {

    // add filter logic here
    // console.log(tableData.forEach((inter)=> {
    //   console.log(inter.find((n => n['rattachement']==rattachement)));
      
    // }
    
    // ));
    return this.tableMoyen.find(n => n['rattachement']==rattachement);

  }

  async changeStatut(change: string,moyen : string, inter:string){

    this.spinner.show();
    await this.db.collection("moyens").doc(moyen).update({
      statut: change
    })

    await this.db.collection("interventions").doc(inter).update({
      statut: "En cours"
    })



  
    if(change == "Rentré" || change == "Départ annulé" ){

    // await this.db.collection("interventions").doc(inter).update({
    //   statut: "Terminée"
    // })

    }

    

    

    

    this.getData()

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
      console.log("done")
    }, 1000);
 
    
  }

  


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.tableData = tableData;
    for (let i = 0; i <= this.tableData.length; i++) {
      this.hideme.push(true);
    }
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  async openModal(numero){

  
    console.log(numero);

    this.numeroSelected = numero

    await this.db.collection("interventions").doc(numero.toString()).get().subscribe((value) => {
      this.intervention = value.data();

      this.alertForm = this.formBuilder.group({
      
        
      });

      this.modalService.open(this.centerDataModal, { centered: true , size: 'lg'});
      console.log(this.intervention);
    })
  
  
  

}


async getUserMoyen(event){


  if(this.usersMoyen.length == 0){

        if(event.target.checked){
          this.db.collection('moyens').doc(event.target.value).collection('equipe').get()
            .subscribe(snapshot => {
              snapshot.docs.forEach(user => {
                let token =  user.data().token;
                this.usersMoyen.push(token);
              
              });
            });
          console.log(this.usersMoyen);
          
          console.log(event.target.value);
          this.moyenSelected = event.target.value;
        }
  }else{
    this.usersMoyen = [];

    if(event.target.checked){
      this.db.collection('moyens').doc(event.target.value).collection('equipe').get()
        .subscribe(snapshot => {
          snapshot.docs.forEach(user => {
            let token =  user.data().token;
            this.usersMoyen.push(token);
          
          });
        });
      console.log(this.usersMoyen);
      
      console.log(event.target.value);
      this.moyenSelected = event.target.value;
    }


  }
}


async formSubmit() {
  console.log(this.intervention);

  var newMoyens = [];

  await this.db.collection("interventions").doc(this.numeroSelected.toString()).get().subscribe((value) => {
    newMoyens = value.data()["moyens"];
    newMoyens.push(this.moyenSelected);
    console.log(newMoyens);
    this.db.collection("interventions").doc(this.numeroSelected.toString()).update({
      moyens : newMoyens,
    });
  });

  
  

    await this.db.collection("moyens").doc(this.moyenSelected).update({
      statut : "Alerté"
    }).then(()=> this.getData())


  

  

  console.log("done");
  

}



}
