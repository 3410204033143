<div class="my-5 pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center my-5">
          <h1 class="font-weight-bold text-error">4 <span class="error-text">0<img src="assets/images/error-img.png"
                alt="" class="error-img"></span> 4</h1>
          <h3 class="text-uppercase">Désolé cette page n'existe pas</h3>
          <div class="mt-5 text-center">
            <a class="btn btn-primary waves-effect waves-light" routerLink="">Retourner au tableau de bord</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
