import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListeComponent } from './liste/liste.component';
import { ListemoyenComponent } from './listemoyen/listemoyen.component';

const routes: Routes = [
    {
        path: 'liste',
        component: ListeComponent
    },
    {
        path: 'listemoyen',
        component: ListemoyenComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PersonnelRoutingModule { }
