import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// tslint:disable-next-line: max-line-length
import { NgbAccordionModule, NgbNavModule, NgbTypeaheadModule, NgbPaginationModule, NgbCollapseModule, NgbTooltipModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from 'angular-archwizard';
import { Ng5SliderModule } from 'ng5-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { UiModule } from '../../shared/ui/ui.module';


import { PersonnelRoutingModule } from './personnel-routing.module';

import { ListeComponent } from './liste/liste.component';
import { ListeDirective } from './liste/liste-directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ListemoyenComponent } from './listemoyen/listemoyen.component';
import { ListemoyenDirective } from './listemoyen/listemoyen-directive';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [ ListeComponent, ListeDirective, ListemoyenComponent, ListemoyenDirective],
  imports: [
    CommonModule,
    FormsModule,
    DropzoneModule,
    ReactiveFormsModule,
    PersonnelRoutingModule,
    UiModule,
    NgxSpinnerModule,
    ArchwizardModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbCollapseModule,
    NgSelectModule,
    Ng5SliderModule,
    NgbModalModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PersonnelModule { }
