import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: 'success',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },
        link: '/'
    },
    // {
    //     id: 3,
    //     label: 'Personnel',
    //     icon: 'ri-user-line',
    //     link: '/personnel',
    //     subItems: [
    //             {
    //                 id: 4,
    //                 label: 'Liste',
    //                 link: '/personnel/liste',
    //                 parentId: 3
    //             },
    //     ]
    // },
    {
        id: 8,
        label: 'Activités',
        icon: 'ri-calendar-2-line',
        link: '/activites',
        subItems: [
            {
                id: 9,
                label: 'Interventions',
                link: '/activites/interventions',
                parentId: 8
            },
            {
                id: 12,
                label: 'Main Courante',
                link: '/activites/maincourante',
                parentId: 8
            },
            {
                id: 13,
                label: 'Soins à la station',
                link: '/activites/soin',
                parentId: 8
            },
            {
                id: 14,
                label: 'Patrouille/Roulage',
                link: '/activites/roulage',
                parentId: 8
            },
    ]
    },
    {
        id: 17,
        label: 'Planning',
        icon: 'ri-calendar-check-line',
        link: '/planning',
        subItems: [
            {
                id: 18,
                label: 'Planning',
                link: '/planning/planning',
                parentId: 17
            },
            {
                id: 19,
                label: 'Agenda',
                link: '/planning/agenda',
                parentId: 17
            },
            {
                id: 20,
                label: 'Affectations',
                link: '/planning/affectation',
                parentId: 17
            }
        ]
    },
    {
        id: 21,
        label: 'Logistique',
        icon: 'ri-focus-2-line',
        link: '/logistique',
        subItems: [
            {
                id: 22,
                label: 'Ajouter Personnels',
                link: '/logistique/addperso',
                parentId: 21
            },
            {
                id: 23,
                label: 'Ajouter Moyens',
                link: '/logistique/addmoyen',
                parentId: 21
            },
            {
                id: 4,
                label: 'Liste Personnels',
                link: '/personnel/liste',
                parentId: 21
            },
            {
                id: 25,
                label: 'Liste Moyens',
                link: '/personnel/listemoyen',
                parentId: 21
            },
        ]
    },
    // {
    //     id: 22,
    //     label: 'Bibliothèque',
    //     icon: 'ri-book-mark-line',
    //     link: '/bibliotheque',
    //     subItems: [
    //         {
    //             id: 23,
    //             label: 'Archives',
    //             link: '/bibliotheque/archives',
    //             parentId: 22
    //         },
            // {
            //     id: 24,
            //     label: 'Imprimer',
            //     link: '/bibliotheque/imprimer',
            //     parentId: 22
            // },
            // {
            //     id: 25,
            //     label: 'Archive Planning',
            //     link: '/bibliotheque/saveplanning',
            //     parentId: 22
            // },
    //     ]
    // },
    // {
    //     id: 24,
    //     label: 'Réglages',
    //     icon: 'ri-settings-5-fill',
    //     link: '/reglages',
        // subItems: [
        //     {
        //         id: 25,
        //         label: 'Réglages',
        //         link: '/reglages',
        //         parentId: 24
        //     },
        // ]
    // },
    // {
    //     id: 25,
    //     label: 'Statistique',
    //     icon: 'ri-line-chart-line',
    //     link: '/statistique',
    //     subItems: [
    //         {
    //             id: 26,
    //             label: 'Graphiques',
    //             link: '/statistique/graphiques',
    //             parentId: 25
    //         },
    //         {
    //             id: 27,
    //             label: 'Reporting',
    //             link: '/statistique/reporting',
    //             parentId: 25
    //         },
    //     ]
    // },
    // {
    //     id: 28,
    //     label: 'Documents',
    //     icon: 'ri-file-copy-2-line',
    //     link: '/documents',
    //     subItems: [
    //         {
    //             id: 29,
    //             label: 'Bibliothéque',
    //             link: '/documents/bibliotheque',
    //             parentId: 28
    //         }
    //     ]
    // },
    // {
    //     id: 30,
    //     label: 'Communication',
    //     icon: 'ri-discuss-line',
    //     link: '/communication',
    //     subItems: [
    //         {
    //             id: 31,
    //             label: 'Chat',
    //             link: '/communication/chat',
    //             parentId: 30
    //         },
    //         {
    //             id: 32,
    //             label: 'Alerte',
    //             link: '/communication/alerte',
    //             parentId: 30
    //         },
    //         {
    //             id: 33,
    //             label: 'Message',
    //             link: '/communication/message',
    //             parentId: 30
    //         },
    //     ]
    // },
    // {
    //     id: 34,
    //     label: 'Organisation',
    //     icon: 'ri-building-4-fill',
    //     link: '/organisation',
    //     // badge: {
    //     //     variant: 'danger',
    //     //     text: '6'
    //     // },
    //     subItems: [
    //         {
    //             id: 35,
    //             label: 'Sections',
    //             link: '/organisation/sections',
    //             parentId: 34
    //         },
    //         {
    //             id: 36,
    //             label: 'Organigramme',
    //             link: '/organisation/organigramme',
    //             parentId: 34
    //         },
    //     ]
    // },
    // {
    //     id: 37,
    //     label: 'Configuration',
    //     icon: 'ri-settings-3-line',
    //     link: '/configuration',
    //     subItems: [
    //         {
    //             id: 38,
    //             label: 'Général',
    //             link: '/configuration/general',
    //             parentId: 37
    //         },
    //         {
    //             id: 39,
    //             label: 'Paramétrage',
    //             link: '/configuration/parametrage',
    //             parentId: 37
    //         },
    //         {
    //             id: 40,
    //             label: 'Habilitations',
    //             link: '/configuration/habilitations',
    //             parentId: 37
    //         },
    //         {
    //             id: 41,
    //             label: 'Monitoring',
    //             link: '/configuration/monitoring',
    //             parentId: 37
    //         }
    //     ]
    // }
];
