<div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Ajout d'un moyen</h4>
        <p class="card-title-desc">Veuillez renseigner des informations
          <code>valides</code> et les plus <code>précises</code> possible.</p>
        <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="addMoyenForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip01">Immatriculation</label>
                <input type="text" class="form-control" formControlName="immatriculation" id="validationTooltip01"
                  placeholder="Immatriculation" value="Mark"
                  [ngClass]="{'is-valid': formsubmit && addMoyenForm.controls['immatriculation'].errors}">
                <div *ngIf="formsubmit && addMoyenForm.controls['immatriculation'].errors" class="valid-tooltip">
                  <span *ngIf="addMoyenForm.controls['immatriculation'].errors.required">Looks good!</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip02">Nom du moyen</label>
                <input type="text" class="form-control" id="validationTooltip02" formControlName="name"
                  placeholder="Nom du Moyen" value="Otto"
                  [ngClass]="{'is-valid': formsubmit && addMoyenForm.controls['name'].errors}">
                <div *ngIf="formsubmit && addMoyenForm.controls['name'].errors" class="valid-tooltip">
                  <span *ngIf="addMoyenForm.controls['name'].errors.required">Looks good!</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="validationTooltip03">Sous-type</label>
                <div class="input-group">
                  <input type="text" class="form-control" id="validationTooltipUsername" formControlName="sousType"
                    placeholder="Sous-type" aria-describedby="validationTooltipUsernamePrepend"
                    [ngClass]="{'is-invalid': formsubmit && addMoyenForm.controls['sousType'].errors}">

                  <div *ngIf="formsubmit && addMoyenForm.controls['sousType'].errors" class="invalid-tooltip">
                    <span *ngIf="addMoyenForm.controls['sousType'].errors.required">Please choose a unique and valid username.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Type</label>
                <input type="text" class="form-control" id="validationTooltip03" placeholder="Type"
                  formControlName="type" [ngClass]="{'is-invalid': formsubmit && addMoyenForm.controls['type'].errors}">
                <div *ngIf="formsubmit && addMoyenForm.controls['type'].errors" class="invalid-tooltip">
                  <span *ngIf="addMoyenForm.controls['type'].errors.required">Type non valide.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative">
                <label for="validationTooltip4">Rattachement</label>
                <input type="text" class="form-control" id="validationTooltip05" placeholder="Rattachement"
                  formControlName="rattachement" [ngClass]="{'is-invalid': formsubmit && addMoyenForm.controls['rattachement'].errors}">
                <div *ngIf="formsubmit && addMoyenForm.controls['rattachement'].errors" class="invalid-tooltip">
                  <span *ngIf="addMoyenForm.controls['rattachement'].errors.required">Rattachement non valide</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group position-relative">
                  <label for="validationTooltip4">Icon</label>
                  <div class="dropdown" ngbDropdown container="body">
                    <button  type="button" class="btn dropdown-toggle" id="dropdownMenuButton"
                      ngbDropdownToggle>
                      <a *ngIf="!iconSelected"> Choisir </a>
                      <img *ngIf="iconSelected" class="png-icon" src="{{iconSelected}}" style="width: 24px; height: 24px;">
                      <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu >
                      <div *ngFor="let icon of icons" class="dropdown-item">
                        <div (click)="setIcon(icon)" >
                          <img class="png-icon" src="{{icon | async }}" style="width: 24px; height: 24px;">
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                
                <!-- <i-select
      id="backgroundPicker" 
      name="selectedBackground" 
      [size]="6" 
      [entries]="pickData"
      [searchEnabled]="true" 
      [applyPattern]="true"
      [applyLayoutType]="true"
      [applyAnimation]="true"
      [applySlideShow]="true"
      (ontoggle)="toggleSelection($event)"
      (onchange)="updateSelection($event)">
    </i-select>
  
    <select i-select
      id="overlayPicker" size="6" name="selectedOverlay"
      [searchEnabled]="false" 
      [applyOpacity]="true"
      [applyAnimation]="true"
      [applySlideShow]="true"
      (ontoggle)="toggleOverlay($event)"
      (change)="updateOverlay($event)" >
      <option *ngFor="let x of overlayData" 
        [attr.opacity]="x.opacity"
        [attr.repeat]="x.repeat"
        [attr.animation]="x.animation"
        [attr.favorite]="x.favorite"
        [attr.pattern]="x.pattern"
        [selected]="x.selected ? 'true' : null" 
        [value]="x.value" 
        [textContent]="x.name"></option>
    </select> -->
                
              </div>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">Ajouter Moyen</button>
        </form>
      </div>
    </div>
    <!-- end card -->
  </div>
