import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AffectationComponent } from './affectation/affectation.component';
import { AgendaComponent } from './agenda/agenda.component';
import { CalendrierComponent } from './calendrier/calendrier.component';
import { CalendrierINFComponent } from './calendrier_INF/calendrierINF.component';
import { CalendrierSNB1Component } from './calendrier_SNB1/calendrierSNB1.component';
import { CalendrierSNB2Component } from './calendrier_SNB2/calendrierSNB2.component';

const routes: Routes = [
    {
        path: 'agenda',
        component: AgendaComponent
    },
    {
        path: 'planning',
        component: CalendrierComponent
    },
    {
        path: 'planningSNB2',
        component: CalendrierSNB2Component
    },
    {
        path: 'planningSNB1',
        component: CalendrierSNB1Component
    },
    {
        path: 'planningINF',
        component: CalendrierINFComponent
    },
    {
        path: 'affectation',
        component: AffectationComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PlanningRoutingModule {}
