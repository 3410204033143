import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { NgbNavModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PersonnelModule } from './personnel/personnel.module';
import { PlanningModule } from './planning/planning.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { LogistiqueModule } from './logistique/logistique.module';
import { BibliothequeModule } from './bibliotheque/bibliotheque.module';
import { AgmCoreModule } from '@agm/core';
import { AlertComponent } from './alert/alert.component';
import { CdkTableExporterModule } from 'cdk-table-exporter';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReglagesComponent } from './reglages/reglages/reglages.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent , AlertComponent, ReglagesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD7Y4-W9XeNHB6F1pmzfXYN8LnMZKByH7U',
      libraries: ['places']
    }),
    NgbTooltipModule,
    NgApexchartsModule,
    PerfectScrollbarModule,
    DndModule,
    FullCalendarModule,
    PersonnelModule,
    PlanningModule,
    BibliothequeModule,
    ConfigurationModule,
    LogistiqueModule,
    LeafletModule,
    WidgetModule,
    NgxSpinnerModule,
    CdkTableExporterModule
  ],
  providers: [
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class PagesModule { }
